
/*===================================================================================*/
/*	OWL CAROUSEL
/*===================================================================================*/

.owl-carousel {
	text-align: center;
	cursor: default;
}
.owl-controls {
	display: inline-block;
    position: relative;
    margin-top: 40px;
}
.panel-group .panel .owl-controls {
    margin-top: 25px;
}
.panel-group.blank .panel .owl-controls {
    margin-top: 40px;
}
.owl-pagination {
	position: relative;
	line-height: 30px;
}
.owl-buttons {
	display: none;
}
.owl-prev,
.owl-next {
	display: inline-block;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 30px;
	height: 30px;
	font-size: 30px;
	color: #FFF;
	background-color: $clr-base-dark;
	border: none;
	-webkit-border-radius: 3px;
	   -moz-border-radius: 3px;
			border-radius: 3px;
	margin: auto;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
	opacity: 0;
}
.owl-prev {
	#{$right}: -35px;
}
.owl-next {
	#{$left}: -35px;
}
.owl-carousel:hover .owl-prev {
	#{$right}: -40px;
	opacity: .25;
}
.owl-carousel:hover .owl-next {
	#{$left}: -40px;
	opacity: .25;
}
.owl-carousel:hover .owl-prev:hover,
.owl-carousel:hover .owl-next:hover {
    color: #1ABB9C;
	opacity: 1;
}
.owl-outer-nav:after {
	content: "";
	position: absolute;
	top: 0;
	#{$right}: -15%;
	width: 130%;
	height: 100%;
	z-index: 0;
}
.owl-outer-nav .owl-wrapper-outer,
.owl-outer-nav .owl-pagination,
.owl-outer-nav .owl-prev,
.owl-outer-nav .owl-next {
	z-index: 1;
}
.owl-outer-nav .owl-controls {
	position: static;
}
.owl-outer-nav .owl-prev {
	#{$right}: -65px;
}
.owl-outer-nav .owl-next {
	#{$left}: -65px;
}
.owl-outer-nav:hover .owl-prev {
	#{$right}: -80px;
}
.owl-outer-nav:hover .owl-next {
	#{$left}: -80px;
}


.owl-ui-md .owl-pagination {
	line-height: 45px;
}
.owl-ui-md .owl-prev,
.owl-ui-md .owl-next {
	width: 45px;
	height: 45px;
	font-size: 45px;
}
.owl-ui-md .owl-prev {
	#{$right}: -55px;
}
.owl-ui-md .owl-next {
	#{$left}: -55px;
}
.owl-ui-md:hover .owl-prev {
	#{$right}: -60px;
}
.owl-ui-md:hover .owl-next {
	#{$left}: -60px;
}

.owl-outer-nav.owl-ui-md:after {
	#{$right}: -18%;
	width: 136%;
}
.owl-outer-nav.owl-ui-md .owl-prev {
	#{$right}: -85px;
}
.owl-outer-nav.owl-ui-md .owl-next {
	#{$left}: -85px;
}
.owl-outer-nav.owl-ui-md:hover .owl-prev {
	#{$right}: -100px;
}
.owl-outer-nav.owl-ui-md:hover .owl-next {
	#{$left}: -100px;
}

.owl-ui-lg .owl-pagination {
	line-height: 60px;
}
.owl-ui-lg .owl-prev,
.owl-ui-lg .owl-next {
	width: 60px;
	height: 60px;
	font-size: 60px;
}
.owl-ui-lg .owl-prev {
	#{$right}: -75px;
}
.owl-ui-lg .owl-next {
	#{$left}: -75px;
}
.owl-ui-lg:hover .owl-prev {
	#{$right}: -80px;
}
.owl-ui-lg:hover .owl-next {
	#{$left}: -80px;
}

.owl-outer-nav.owl-ui-lg:after {
	#{$right}: -22%;
	width: 144%;
}
.owl-outer-nav.owl-ui-lg .owl-prev {
	#{$right}: -105px;
}
.owl-outer-nav.owl-ui-lg .owl-next {
	#{$left}: -105px;
}
.owl-outer-nav.owl-ui-lg:hover .owl-prev {
	#{$right}: -120px;
}
.owl-outer-nav.owl-ui-lg:hover .owl-next {
	#{$left}: -120px;
}

.owl-inner-nav .owl-controls {
	position: static;
}
.owl-inner-nav .owl-prev {
	#{$right}: 45px;
}
.owl-inner-nav .owl-next {
	#{$left}: 45px;
}
.owl-inner-nav:hover .owl-prev {
	#{$right}: 30px;
}
.owl-inner-nav:hover .owl-next {
	#{$left}: 30px;
}

.owl-outer-nav .owl-prev,
.owl-outer-nav .owl-next,
.owl-inner-nav .owl-prev,
.owl-inner-nav .owl-next {
	bottom: 70px;
}

.owl-outer-nav.owl-ui-md .owl-prev,
.owl-outer-nav.owl-ui-md .owl-next,
.owl-inner-nav.owl-ui-md .owl-prev,
.owl-inner-nav.owl-ui-md .owl-next {
	bottom: 85px;
}

.owl-outer-nav.owl-ui-lg .owl-prev,
.owl-outer-nav.owl-ui-lg .owl-next,
.owl-inner-nav.owl-ui-lg .owl-prev,
.owl-inner-nav.owl-ui-lg .owl-next {
	bottom: 100px;
}

.owl-inner-pagination .owl-pagination,
.owl-inner-pagination .owl-prev,
.owl-inner-pagination .owl-next {
	margin-top: -40px;
	top: -60px;
}

.owl-inner-pagination.owl-ui-md .owl-pagination,
.owl-inner-pagination.owl-ui-md .owl-prev,
.owl-inner-pagination.owl-ui-md .owl-next {
	margin-top: -50px;
	top: -65px;
}

.owl-inner-pagination.owl-ui-lg .owl-pagination,
.owl-inner-pagination.owl-ui-lg .owl-prev,
.owl-inner-pagination.owl-ui-lg .owl-next {
	margin-top: -60px;
	top: -75px;
}

.owl-inner-pagination.owl-outer-nav .owl-prev,
.owl-inner-pagination.owl-outer-nav .owl-next,
.owl-inner-pagination.owl-inner-nav .owl-prev,
.owl-inner-pagination.owl-inner-nav .owl-next {
	margin: auto;
	top: 0;
	bottom: 43px;
}

.owl-inner-pagination .owl-pagination {
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
	opacity: 0;
}

.owl-inner-pagination:hover .owl-pagination {
	opacity: 1;
}

.owl-inner-pagination.owl-inner-nav .owl-pagination,
.owl-inner-pagination.owl-outer-nav .owl-pagination {
	top: -45px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-md .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-md .owl-pagination {
	top: -50px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-lg .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-lg .owl-pagination {
	top: -60px;
}

.owl-inner-pagination.owl-inner-nav:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav:hover .owl-pagination {
	top: -60px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-md:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-md:hover .owl-pagination {
	top: -65px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-lg:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-lg:hover .owl-pagination {
	top: -75px;
}

#owl-main .item {
	height: 44vh;
	background-color: #FFF;
	background-position: center 55%;
	-webkit-background-size: cover;
	   -moz-background-size: cover;
		 -o-background-size: cover;
			background-size: cover;
}
#owl-main.height-md .item {
	height: 61vh;
}
#owl-main.height-lg .item {
	height: 90vh;
}
#owl-main .container {
	display: table;
	height: inherit;
}
#owl-main .caption {
	display: table-cell;
}
#owl-main .caption.vertical-center {
	vertical-align: middle;
	padding-bottom: 3vh;
}
#owl-main .caption.vertical-top {
	vertical-align: top;
	padding-top: 8vh;
}
#owl-main .caption.vertical-bottom {
	vertical-align: bottom;
	padding-bottom: 14vh;
}
#owl-main .caption.text-center {
	padding-#{$right}: 8%;
	padding-#{$left}: 8%;
}
#owl-main .caption.text-left {
	padding-#{$left}: 20%;
}
#owl-main .caption.text-right {
	padding-#{$right}: 20%;
}
#owl-main .caption h1,
#owl-main .caption p,
#owl-main .caption div {
	position: relative;
}
#owl-main .caption h1 span {
	padding: .250em 0;
	line-height: 180%;
}
#owl-main .caption .light-bg,
#owl-main .caption .medium-bg,
#owl-main .caption .dark-bg,
#owl-main .caption .tint-bg {
	background: none;
	padding-#{$right}: .500em;
	padding-#{$left}: .500em;
}
#owl-main .caption h1.light-bg span,
#owl-main .caption p.light-bg span {
	background: #FFF;
	box-shadow: 0.5em 0 0 #FFF, -0.5em 0 0 #FFF;
	-webkit-box-decoration-break: clone;
	        box-decoration-break: clone;
}
#owl-main .caption h1.medium-bg span,
#owl-main .caption p.medium-bg span {
	background: #A3B1BF;
	box-shadow: 0.5em 0 0 #A3B1BF, -0.5em 0 0 #A3B1BF;
	-webkit-box-decoration-break: clone;
	        box-decoration-break: clone;
}
#owl-main .caption h1.dark-bg span,
#owl-main .caption p.dark-bg span {
	background: $clr-base-dark;
	box-shadow: 0.5em 0 0 $clr-base-dark, -0.5em 0 0 $clr-base-dark;
	-webkit-box-decoration-break: clone;
	        box-decoration-break: clone;
}
#owl-main .caption h1.tint-bg span,
#owl-main .caption p.tint-bg span {
	background: #1ABB9C;
	box-shadow: 0.5em 0 0 #1ABB9C, -0.5em 0 0 #1ABB9C;
	-webkit-box-decoration-break: clone;
	        box-decoration-break: clone;
}
#owl-main .owl-controls {
	display: block;
	position: static;
	margin-top: -47px;
}
#owl-main .owl-pagination {
	background: #FFF;
	line-height: inherit;
	position: relative;
	bottom: -40px;
	padding: 10px;
	display: inline-block;
	-webkit-border-radius: 3px;
	   -moz-border-radius: 3px;
			border-radius: 3px;
	margin: auto;
	opacity: 0;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}
#owl-main:hover .owl-pagination {
	bottom: -25px;
	opacity: 1;
}
#owl-main .owl-prev,
#owl-main .owl-next {
	bottom: 0;
}

.owl-controls .owl-page {
    display: inline-block;
}
.owl-pagination .owl-page span {
	display: block;
	width: 15px;
	height: 15px;
	background: $clr-base-dark;
	border: none;
	-webkit-border-radius: 50%;
			border-radius: 50%;
	margin: 0 5px;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}
.owl-pagination .owl-page.active span,
.owl-pagination .owl-page:hover span {
	background: #1ABB9C;
}
.owl-item-gap .item {
    margin: 0 15px;
}
.owl-item-gap-sm .item {
    margin: 0 10px;
}
/* preloading images */
.owl-item.loading {
    min-height: inherit;
    background: none;
}