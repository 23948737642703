
/*===================================================================================*/
/*	FEATURES / STYLES
/*===================================================================================*/

.font-icons {
  list-style: none;
  margin: 0;
  padding: 0;
}
.font-icons li {
  display: inline-block;
  font-size: 25px;
  margin-bottom: 10px;
  padding: 0 10px;
}
.alert .close {
  line-height: 28px;
}
.retina-icons-code i {
  font-size: 18px;
}
.retina-icons-code tr td:first-child,
.retina-icons-code tr th:first-child {
  text-align: center;
}
.retina-icons-code code {
  color: $clr-base-txt;
  background: none;
}
.table-bordered {
  border: 1px solid #E6E9ED;
  border-#{$right}: none;
  background: #FFF;
}
.table-bordered th,
.table-bordered td {
  border-#{$right}: 1px solid #E6E9ED;
}
.table th,
.table td {
  border-top: 1px solid #E6E9ED;
}
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
background-color: #F5F7FA;
}
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
border-color: #E6E9ED;
}