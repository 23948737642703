
/*===================================================================================*/
/*	HEADER
/*===================================================================================*/

.navbar .container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
    align-items: center;
    padding: 0;
}
.navbar {
	display: block;
	padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    position: relative;
    z-index: 8000;
    min-height: inherit;
}
.navbar ul {
	list-style: none;
}
.navbar-brand {
	line-height: 100%;
    padding: 0;
    margin-#{$left}: 0;
}
.navbar-brand img {
	display: inline-block;
	width: auto; /* Needed for SVG images */
	height: 75px; //40
	max-width: 100%;
}
.navbar-brand img[src*=".svg"] {
	max-width: 200%; /* Needed for SVG images in at least IE 10/11 */
}
.navbar-brand img.animate {
	-moz-transition: none;
}
.navbar-header .navbar-brand {
	display: none;
}
.navbar-header {
    float: none !important;
	position: relative;
	background: #F5F7FA;
	border-bottom: 1px solid #E6E9ED;
	z-index: 0;
}
.navbar-header li,
.navbar-header a {
	display: table-cell;
	vertical-align: middle;
	height: 40px;
}
.navbar-collapse {
    background-color: rgba(255, 255, 255, 1);
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #E6E9ED;
}
.navbar-collapse.collapse:not(.show) {
	display: block;
}
.navbar-collapse.collapse.affix {
	position: fixed;
	top: 0;
}
.navbar-collapse.collapse.animate {
	-moz-transition: none;
}
.navbar-collapse.collapse.animate-after {
    background-color: rgba(255, 255, 255, 0.97);
}
.navbar-nav {
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-positive: 1;
	flex-grow: 1;
    margin: 0;
}
.navbar-nav > li:first-of-type,
.navbar-nav > li.searchbox {
	margin-#{$right}: auto;
}
.navbar-nav > li > a {
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	text-transform: uppercase;
	color: $clr-base-dark;
	padding: 15px 20px;
	border: 1px solid rgba(255,255,255,0);
	border-radius: 3px;
	bottom: -1px;
	z-index: 2;
}
.navbar-nav > li.dropdown > a {
	border-bottom: none;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	transition: all 0.125s ease-out;
}
.animate-after .navbar-nav > li > a {
	border-radius: 0;
	margin-top: -1px;
}
.nav > li,
.nav > li > a {
	position: relative;
	display: block;
}
.nav > li.active > a {
    color: #1ABB9C;
}
.nav > li > a:focus {
    background-color: transparent;
    border-color: transparent;
}
.nav > li > a:hover {
    background-color: #F5F7FA;
    border-color: #E6E9ED;
    color: #1ABB9C;
}
.navbar .dropdown-menu {
    padding: 0;
    margin: 0;
    width: 210px;
    background: #F5F7FA;
	border: none;
	border: 1px solid #E6E9ED;
	border-bottom-width: 2px;
	border-bottom-color: #CED2D6;
    -webkit-border-radius: 3px !important;
       -moz-border-radius: 3px !important;
            border-radius: 3px !important;
	-webkit-border-top-right-radius: 0 !important;
	    -moz-border-radius-top#{$right}: 0 !important;
		    border-top-right-radius: 0 !important;
	-webkit-box-shadow: 0 10px 10px rgba(0,0,0,0.15);
	   -moz-box-shadow: 0 10px 10px rgba(0,0,0,0.15);
	        box-shadow: 0 10px 10px rgba(0,0,0,0.15);
	z-index: 0;
}
.navbar .dropdown.open .dropdown-menu {
	z-index: 1;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li {
    background: transparent;
    border: none;
    border-top: 1px solid #E6E9ED;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li:first-child {
    border: none;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a {
	line-height: 18px;
    padding: 13px 20px;
    color: $clr-base-txt;
    text-transform: uppercase;
    font-weight: 700;
	white-space: normal;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a.active {
    filter: none;
    background-color: #F5F7FA;
    color: #1ABB9C;
}
.navbar .nav .open > a,
.navbar .nav .open > a:hover,
.navbar .nav .open > a:focus {
    background-color: #F5F7FA;
    border-color: #E6E9ED;
}
.navbar .nav > li > .dropdown-menu:not(.yamm-dropdown-menu):after {
    display: none;
}
.navbar .nav > li > .dropdown-menu:not(.yamm-dropdown-menu):before {
    display: none;
}
.navbar .dropdown-submenu .dropdown-menu {
	top: -1px !important;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:focus,
.navbar .dropdown-submenu:hover > a,
.navbar .dropdown-submenu:focus > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:focus {
    background: #1ABB9C;
    color: #FFF !important;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.navbar-header .info {
	padding-#{$right}: 0;
  margin: 0;
}
.navbar-header .info li {
    font-size: 16px;
    padding-#{$left}: 25px;
    font-weight: bold;
}
.navbar-header .info li a {
    font-size: 16px;
    color: $clr-base-txt;
}
.navbar-header .info a:hover {
    color: #1ABB9C;
}
.navbar-header .social {
	margin-#{$right}: auto;
}
.navbar-form {
    padding: 0;
    margin: 0;
}
.btn.responsive-menu {
    display: none;
	width: 40px;
	height: 40px;
    font-size: 15px;
    padding: 0;
    margin: 0;
}


/*===================================================================================*/
/*	MEGA MENU
/*===================================================================================*/

.yamm .nav,
.yamm .collapse,
.yamm .dropdown.yamm-fullwidth {
    position: static;
}
.yamm .navbar-inner,
.yamm .container {
    position: relative;
}
.yamm .dropdown-menu {
	top: auto;
    #{$right}: auto;
	-webkit-border-top-#{$right}-radius: 3px !important;
	    -moz-border-radius-top-#{$right}: 3px !important;
		    border-top-#{$right}-radius: 3px !important;
}
.yamm .dropdown-menu > li {
    display: block;
}
.yamm .nav.float-right .dropdown-menu {
    #{$left}: 0;
}
.yamm .yamm-content {
	font-size: 14px;
	line-height: 22px;
    padding: 30px 40px;
	margin-#{$right}: -20px;
	margin-#{$left}: -20px;
}
.yamm .yamm-content .inner {
	padding: 0 20px;
}
.yamm .yamm-content li {
	line-height: inherit;
}
/*.yamm .yamm-content:before,
.yamm .yamm-content:after {
    display: table;
    content: "";
    line-height: 0;
}*/
/*.yamm .yamm-content:after {
    clear: both;
}*/
.yamm .nav > li > .dropdown-menu:after,
.yamm .nav > li > .dropdown-menu:before {
    display: none;
}
.yamm .yamm-content img {
    width: 100%;
}
.yamm .yamm-content a {
    color: inherit;
}
.yamm .yamm-content h4 {
	text-transform: uppercase;
	margin-bottom: 20px;
}
.yamm .yamm-content p {
	font-size: inherit;
	line-height: inherit;
    margin-bottom: 15px;
}
.yamm .yamm-content .btn {
	margin: 10px 0;
}
.yamm .yamm-content a:hover {
    color: #1ABB9C;
	opacity: 1;
}
.yamm .dropdown.yamm-fullwidth .dropdown-menu {
    width: 100%;
    #{$right}: 0;
    #{$left}: 0;
}