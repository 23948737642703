
/*===================================================================================*/
/*	RESPONSIVE
/*===================================================================================*/

@media (min-width: 1200px) { 
	.container {
	    padding-#{$left}: 0;
	    padding-#{$right}: 0;
	}
	.owl-buttons {
		display: block;
	}
	.items.col-4-custom {
	    margin-#{$right}: -10px;
	    margin-bottom: -10px;
	}
	.items.col-4-custom li {
	    width: 277px;
	    margin-#{$right}: 10px;
	    margin-bottom: 10px;
	}
	.items.col-3-custom {
	    margin-#{$right}: -9px;
	    margin-bottom: -9px;
	}
	.items.col-3-custom li {
	    width: 374px;
	    margin-#{$right}: 9px;
	    margin-bottom: 9px;
	}
	.items.col-3-custom.gap {
	    margin-#{$right}: -21px;
	    margin-bottom: -21px;
	}
	.items.col-3-custom.gap li {
	    width: 366px;
	    margin-#{$right}: 21px;
	    margin-bottom: 21px;
	}
	.items.col-4-custom.gap {
	    margin-#{$right}: -18px;
	    margin-bottom: -18px;
	}
	.items.col-4-custom.gap li {
	    width: 271px;
	    margin-#{$right}: 18px;
	    margin-bottom: 18px;
	}
	.grid-blog.col-2-custom {
	    margin-#{$right}: -40px;
	}
	.sidebar-left .grid-blog.col-2-custom {
		margin-#{$right}: -20px;
	}
	.sidebar-left .pagination {
		margin-#{$right}: 20px;
	}
	.grid-blog.col-2-custom .post {
	    width: 378px;
	    margin-#{$right}: 40px;
	    margin-bottom: 40px;
	}
	.grid-blog.col-2-custom.no-sidebar .post {
	    width: 550px;
	}
	.grid-blog.col-3-custom {
	    margin-#{$right}: -30px;
	}
	.grid-blog.col-3-custom .post {
	    margin-#{$right}: 30px;
	    margin-bottom: 30px;
	}
	.grid-blog.col-3-custom.no-sidebar .post {
		width: 360px;
	}
	.sidebar {
	    padding-#{$right}: 35px !important;
	}
	.modal-dialog {
		top: 50px;
		bottom: 50px;
		#{$right}: 50px;
		#{$left}: 50px;
		width: calc(100% - 100px);
	}
}

@media (min-width: 1025px) {
	.modal-open {
		margin-#{$right}: -17px;
		margin-#{$left}: -17px;
	}
	.modal-open .navbar-collapse.affix {
		#{$left}: 7px;
	}
	.navbar-collapse.collapse {
		padding-bottom: 15px;
	}
	.navbar .dropdown-submenu > a:after {
	    display: none;
	}
	.navbar .dropdown-submenu > .dropdown-menu:not(.yamm-dropdown-menu) {
	    margin-top: 0;
		margin-#{$right}: -10px;
	}
	.dropdown-submenu {
	    position: relative;
	}
	.dropdown-submenu>.dropdown-menu:not(.yamm-dropdown-menu) {
	    top: 0;
	    #{$right}: 100%;
	    margin-top: -6px;
	}
	.dropdown-submenu:hover>.dropdown-menu:not(.yamm-dropdown-menu) {
	    display: block;
	}
	.dropdown-submenu>a:after {
	    display: block;
	    content: " ";
	    float: $left;
	    width: 0;
	    height: 0;
	    margin-top: 5px;
	    margin-#{$left}: -10px;
	}
	.dropdown-submenu.float-left {
	    float: none;
	}
	.dropdown-submenu.float-left>.dropdown-menu:not(.yamm-dropdown-menu) {
	    #{$right}: -100%;
	    margin-#{$right}: 10px;
	}
	.navbar-nav .dropdown .dropdown-menu {
		visibility: hidden;
		display: block;
		transition: all 0.125s ease-out;
		opacity: 0;
		margin-top: -10px;
	}
	.navbar-nav .dropdown.open > .dropdown-menu,
	.navbar-nav .dropdown.open .dropdown-submenu:hover > .dropdown-menu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		margin-#{$right}: 0;
	}
	.navbar-nav .dropdown-menu {
		position: absolute;
		float: $right;
	}
	.navbar-nav .dropdown-menu-right .dropdown-menu:not(.yamm-dropdown-menu) {
		#{$right}: -100%;
	}
	.navbar-nav .dropdown-menu-right .dropdown-menu:not(.yamm-dropdown-menu) {
		margin-#{$right}: 10px;
	}
}

@media (min-width: 1025px) and (max-width: 1199px) {
	/*br {
		display: none;
	}*/
	#team .inner-left {
		padding-#{$right}: 40px;
	}
	#team .inner-right {
		padding-#{$left}: 40px;
	}	
	.classic-blog .post {
		width: 593px;
		padding-bottom: 50px;
	}
	.classic-blog .post-content > * {
		margin-#{$right}: 50px;
		margin-#{$left}: 50px;
	}
	.classic-blog .post-content > .post-media {
		margin-#{$right}: 0;
		margin-#{$left}: 0;
	}
	.classic-blog .post-content > *:first-child,
	.classic-blog .post-media + * {
		margin-top: 45px;
	}
	.classic-blog .post-content > .post-media:first-child,
	.classic-blog .owl-carousel.post-media + * {
		margin-top: 0;
	}
	.classic-blog .post-title {
		font-size: 33px;
	}
	.classic-blog .post-content .meta {
		padding-#{$right}: 50px;
		padding-#{$left}: 50px;
		margin-#{$left}: -5px;
		margin-#{$right}: -5px;
		margin-bottom: 25px;
	}
	.grid-blog.col-2-custom {
	    margin-#{$right}: -30px;
	}
	.grid-blog.col-2-custom .post {
	    width: 326px;
	    margin-#{$right}: 30px;
	    margin-bottom: 30px;
	}
	.grid-blog.col-2-custom .post-title {
		font-size: 28px;
	}
	.grid-blog.col-2-custom.no-sidebar {
	    margin-#{$right}: -40px;
	}
	.grid-blog.col-2-custom.no-sidebar .post {
	    width: 460px;
	    margin-#{$right}: 40px;
	    margin-bottom: 40px;
	}
	.grid-blog.col-3-custom.no-sidebar {
	    margin-#{$right}: -25px;
	}
	.grid-blog.col-3-custom.no-sidebar .post {
	    width: 302px;
	    margin-#{$right}: 25px;
	    margin-bottom: 25px;
	}
	.grid-blog.col-3-custom.no-sidebar .post-title {
		font-size: 24px;
	}
	.grid-blog.col-3-custom.no-sidebar p {
		font-size: 16px;
	}
	.tabs-circle-top .etabs:before {
		margin: 55px 0 -55px;
	}
	.tabs-circle-top .tab {
		height: 140px;
		margin: 0 10px;
	}
	.tabs-circle-top .tab a {
		width: 110px;
		height: 110px;
		max-width: 110px;
		font-size: 12px;
	}
	.tabs-circle-top .tab a div {
		font-size: 36px;
	}
	.tabs-circle-top .tab.active a {
		height: 140px;
		width: 140px;
		max-width: 140px;
		font-size: 14px;
	}
	.tabs-circle-top .tab.active a div {
		font-size: 46px;
	}
}

@media (max-width: 1024px) {
	html {
		overflow-x: hidden;
	}
	.body-scroll-disabled,
	.body-scroll-disabled body {
		overflow: hidden;
	}
	#hero .info,
	.items li .text-overlay .info.big,
	.thumbs .thumb .text-overlay .info.big,
	.owl-carousel .item .text-overlay .info.big {
		margin-top: -35px;
	}
	.retina-icons-code code {
		font-size: 11.5px;
	}
	.tabs-side.tab-container .panel-container {
		padding-#{$right}: 50px;
	}
	br {
		display: none;
	}
	#team .inner-left {
		padding-#{$right}: 30px;
	}
	#team .inner-right {
		padding-#{$left}: 30px;
	}
	.classic-blog .post {
		width: 623px;
		padding-bottom: 50px;
	}
	.classic-blog .post-content > * {
		margin-#{$right}: 50px;
		margin-#{$left}: 50px;
	}
	.classic-blog .post-content > .post-media {
		margin-#{$right}: 0;
		margin-#{$left}: 0;
	}
	.classic-blog .post-content > *:first-child,
	.classic-blog .post-media + * {
		margin-top: 45px;
	}
	.classic-blog .post-content > .post-media:first-child,
	.classic-blog .owl-carousel.post-media + * {
		margin-top: 0;
	}
	.classic-blog .post-title {
		font-size: 33px;
	}
	.classic-blog .post-content .meta {
		padding-#{$right}: 50px;
		padding-#{$left}: 50px;
		margin-#{$left}: -5px;
		margin-#{$right}: -5px;
		margin-bottom: 25px;
	}
	.classic-blog.no-sidebar .sidemeta, .classic-blog.no-sidebar .pagination {
		#{$right}: auto;
	}
	.sidebar-left .grid-blog.col-2-custom,
	.sidebar-left .grid-blog.col-2-custom + .pagination {
		padding-#{$right}: 20px;
		padding-#{$left}: 0;
	}
	.grid-blog.col-2-custom {
		padding-#{$left}: 20px;
	    margin-#{$right}: 0;
		margin-bottom: 0;
	}
	.grid-blog.col-2-custom .post {
	    width: 100%;
	    margin-#{$right}: 0;
	    margin-bottom: 30px;
	}
	.grid-blog.col-2-custom .post-title {
		font-size: 28px;
	}
	.grid-blog.col-2-custom.no-sidebar,
	.grid-blog.col-3-custom.no-sidebar {
		padding-#{$left}: 0;
	    margin-#{$right}: -25px;
	}
	.grid-blog.col-2-custom.no-sidebar .post,
	.grid-blog.col-3-custom.no-sidebar .post {
	    width: 332px;
		padding-bottom: 40px;
	    margin-#{$right}: 25px;
	    margin-bottom: 25px;
	}
	.grid-blog.col-2-custom.no-sidebar .post-content > *,
	.grid-blog.col-3-custom.no-sidebar .post-content > * {
		margin-#{$right}: 40px;
		margin-#{$left}: 40px;
	}
	.grid-blog.col-2-custom.no-sidebar .post-content > *:first-child,
	.grid-blog.col-2-custom.no-sidebar .post-media + *,
	.grid-blog.col-3-custom.no-sidebar .post-content > *:first-child,
	.grid-blog.col-3-custom.no-sidebar .post-media + * {
		margin-top: 35px;
	}
	.grid-blog.col-2-custom.no-sidebar .post-content .meta,
	.grid-blog.col-3-custom.no-sidebar .post-content .meta {
		padding-#{$right}: 40px;
		padding-#{$left}: 40px;
		margin-#{$left}: -5px;
		margin-#{$right}: -5px;
		margin-bottom: 25px;
	}
	.grid-blog.col-2-custom.no-sidebar .post-title ,
	.grid-blog.col-3-custom.no-sidebar .post-title {
		font-size: 24px;
	}
	.grid-blog.col-2-custom.no-sidebar p,
	.grid-blog.col-3-custom.no-sidebar p {
		font-size: 16px;
	}
	.format-filter li a {
		padding: 0 25px;
	}
	.blog.no-sidebar .post,
	.blog.no-sidebar #comments,
	.blog.no-sidebar .pagination,
	.blog.no-sidebar .comment-form-wrapper {
	    width: 100%;
	}
	.classic-blog .pagination,
	.classic-blog .comment-form-wrapper {
		margin-bottom: 120px;
	}
	.classic-blog.no-sidebar .pagination,
	.classic-blog.no-sidebar .comment-form-wrapper {
		margin-bottom: 0;
	}
	[class*="col-lg"][class*="inner-left"],
	[class*="col-lg"][class*="inner-right"] {
		padding-#{$right}: 15px;
		padding-#{$left}: 15px;
	}
	[class*="col-lg"][class*="border-left"] {
		border-#{$right}: none !important;
	}
	[class*="col-lg"][class*="border-right"] {
		border-#{$left}: none !important;
	}
	.tabs-circle-top .etabs:before {
		margin: 50px 0 -50px;
	}
	.tabs-circle-top .tab {
		height: 130px;
		margin: 0 5px;
	}
	.tabs-circle-top .tab a {
		width: 95px;
		height: 95px;
		max-width: 95px;
		font-size: 11px;
	}
	.tabs-circle-top .tab a div {
		font-size: 33px;
	}
	.tabs-circle-top .tab.active a {
		height: 130px;
		width: 130px;
		max-width: 130px;
		font-size: 13px;
	}
	.tabs-circle-top .tab.active a div {
		font-size: 44px;
	}
	#owl-main .caption.text-center,
	#owl-main .caption.text-left,
	#owl-main .caption.text-right {
		padding-#{$right}: 0;
		padding-#{$left}: 0;
	}
	.navbar-header .navbar-brand {
		display: inline-block;
	}
	.navbar-collapse .navbar-brand {
		display: none;
	}
	.navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-collapse.collapse.show {
		display: block !important;
	}
	.navbar.affix,
	.navbar.affix-top {
		position: fixed;
		top: 0;
		width: 100%;
	}
	.navbar .container {
		display: block;
	}
	.navbar .social,
	.navbar .info {
	    display: none;
	}
	.navbar-header {
		background: #FFF;
	    padding: 25px 0;
	}
	.btn.responsive-menu {
	    display: table;
	}
	.btn.responsive-menu i {
	    display: table-cell;
		vertical-align: middle;
	}
	.navbar-collapse {
	    position: static !important;
		background: #F5F7FA;
	    max-height: inherit;
		padding-top: 0;
		padding-bottom: 0;
	}
	.navbar-collapse:after {
	    content: '';
	    display: block;
	    height: 0;
	    clear: both;
	    visibility: hidden;
	}
	.yamm .nav,
	.yamm .dropup,
	.yamm .dropdown,
	.yamm .collapse {
		float: none;
	    position: relative;
	}
	.yamm .dropdown-menu {
	    position: relative;
	    #{$right}: inherit;
	    top: inherit;
	    float: none;
	    width: 100%;
	   /* display: block !important;*/
	}
	.yamm .yamm-content {
	    padding: 12px 0;
	    font-size: 13px;
	}
	.navbar-nav {
		float: none !important;
		display: block;
		padding: 15px 0;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	.navbar-nav > li {
		float: none;
		display: block;
	}
	.navbar-nav > li + li {
		border-top: 1px solid #E6E9ED;
	}
	.navbar-nav > li > a {
		padding-#{$right}: 0;
		padding-#{$left}: 0;
		border: none;
		border-radius: 0;
	}
	.navbar-nav > li > a:hover,
	.navbar-nav > li > a:focus {
		background: none;
	}
	.navbar-nav .dropdown-menu {
		border: none;
		box-shadow: none;
	}
	.navbar-nav .dropdown-submenu .dropdown-menu {
		top: 0 !important;
	    padding-#{$right}: 20px;
	}
	.navbar-nav .dropdown-menu > li:last-child {
		margin-bottom: 20px;
	}
	.navbar-nav .searchbox {
		float: right !important;
		display: block;
		width: 100%;
	}
	.navbar-header {
	    float: $right;
	    width: 100%;
	}
	.navbar-header:after {
	    content: '';
	    display: block;
	    height: 0;
	    clear: both;
	    visibility: hidden;
	}
}

@media (max-width: 991px) {
	.yamm .yamm-content .inner {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.yamm-content > [class*="col"] {
		margin-bottom: 0;
	}
	.classic-blog .post {
		width: 630px;
	}
}

@media (max-width: 979px) {
	.classic-blog .post {
		width: 601px;
	}
}

@media (max-width: 969px) { 
	.yamm .dropdown.yamm-fullwidth .dropdown-menu {
	    width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.navbar .container,
	.container {
	    padding-#{$left}: 15px;
	    padding-#{$right}: 15px;
	}
	.tabs-side .etabs {
	    width: 200px;
	}
	.tabs-side.tab-container .panel-container {
	    width: 480px;
	}
	.tabs-top.left .tab {
	    margin-#{$left}: 2px;
	}
	.tabs-top.left .tab a {
	    padding: 5px 10px;
	}
	.items.col-4-custom {
	    margin-#{$right}: -9px;
	    margin-bottom: -9px;
	}
	.items.col-4-custom li {
	    width: 224px;
	    margin-#{$right}: 9px;
	    margin-bottom: 9px;
	}
	.items.col-3-custom {
	    margin-#{$right}: -9px;
	    margin-bottom: -9px;
	}
	.items.col-3-custom li {
	    width: 224px;
	    margin-#{$right}: 9px;
	    margin-bottom: 9px;
	}
	.items.col-3-custom.gap {
	    margin-#{$right}: -18px;
	    margin-bottom: -18px;
	}
	.items.col-3-custom.gap li {
	    width: 336px;
	    margin-#{$right}: 18px;
	    margin-bottom: 18px;
	}
	.items.col-4-custom.gap {
	    margin-#{$right}: -18px;
	    margin-bottom: -18px;
	}
	.items.col-4-custom.gap li {
	    width: 336px;
	    margin-#{$right}: 18px;
	    margin-bottom: 18px;
	}
}

@media (max-width: 767px) {
	/*body {
		height: 100vh;
	}*/
	h1,
	h2,
	h3,
	h4 {
		letter-spacing: .0125em;
	}
	h1 {
		font-size: 26px;
		line-height: 120%;
		margin-bottom: 20px;
	}
	header p {
		font-size: 20px;
	}
	footer .inner {
		padding-top: 35px;
	    padding-bottom: 25px;
	}
	footer .row .inner {
		padding-#{$right}: 20px;
		padding-#{$left}: 20px;
	}
	footer .container > .row {
		margin-#{$right}: -20px;
		margin-#{$left}: -20px;
	}
	.row,
	#share {
		overflow: hidden;
	}
	#share .container {
		max-width: 100%;
		padding: 0;
	}
	/*.owl-controls {
		display: inline;
	}
	.owl-pagination {
		top: 0 !important;
	}*/
	.last-bottom {
		padding-bottom: 20px !important;
	}
	.last-bottom + .last-bottom {
		padding-bottom: 0 !important;
	}
	.thumbs.gap-lg {
		margin-#{$right}: -15px;
	}
	.thumbs.gap-lg .thumb {
		padding: 0 15px 15px 0;
	}
	.icon.float-right {
		margin-#{$right}: 10px;
	}
	.inner {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.inner-md {
		padding-top: 65px;
		padding-bottom: 65px;
	}
	.inner-sm {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.inner-xs {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.inner-top {
		padding-top: 80px;
	}
	.inner-top-md {
		padding-top: 65px;
	}
	.inner-top-sm {
		padding-top: 50px;
	}
	.inner-top-xs {
		padding-top: 40px;
	}
	.inner-bottom {
		padding-bottom: 80px;
	}
	.inner-bottom-md {
		padding-bottom: 65px;
	}
	.inner-bottom-sm {
		padding-bottom: 50px;
	}
	.inner-bottom-xs {
		padding-bottom: 40px;
	}
	.height-xs,
	.height-sm,
	.height-md,
	.height-lg,
	#owl-main .item,
	#owl-main.height-md .item,
	#owl-main.height-lg .item {
		height: 88vh;
	}
	#hero h1 {
		font-size: 38px;
		line-height: 115%;
		letter-spacing: 0.031em;
	}
	#hero p {
		font-size: 20px;
		line-height: 135%;
		margin-top: 15px;
	}
	#hero #owl-main .caption h1.light-bg,
	#hero #owl-main .caption h1.medium-bg,
	#hero #owl-main .caption h1.dark-bg,
	#hero #owl-main .caption h1.tint-bg {
		font-size: 33px;
	}
	#hero .btn {
		margin-top: 25px;
	}
	#hero .btn-large {
		font-size: 16px;
		padding: 12px 30px 15px;
	}
	.retina-icons-code tr td:first-child,
	.retina-icons-code tr th:first-child {
		max-width: 30px;
	}
	.table > thead > tr > th,
	.table > tbody > tr > th,
	.table > tfoot > tr > th,
	.table > thead > tr > td,
	.table > tbody > tr > td,
	.table > tfoot > tr > td {
		line-height: 100%;
		padding-#{$right}: 4px;
		padding-#{$left}: 4px;
	}
	.table > thead > tr > th {
		font-size: 11px;
	}
	.retina-icons-code i {
		font-size: 14px;
	}
	.retina-icons-code code {
		font-size: 10px;
	}
	.tabs-side .etabs {
		float: none;
		width: 100%;
	}
	.tabs-side .tab a {
		border-left-color: #E6E9ED;
	}
	.tabs-side .tab:first-child a {
		border-top-left-radius: 3px;
	}
	.tabs-side .tab:last-child a {
		border-bottom-left-radius: 3px;
	}
	.tabs-side .tab.active a {
		border-left-color: #CED7E0 !important;
	}
	.tabs-side.tab-container .panel-container {
		padding-#{$right}: 0;
		border-#{$right}: none;
		margin-top: 30px;
	}
	.tabs-top .etabs:after {
		display: none;
	}
	.tabs-top .tab {
		display: block;
		max-width: 100%;
		margin: 0;
	}
	.tabs-top .tab a {
		display: block;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	.tabs-top .tab:first-child a {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}
	.tabs-top .tab:last-child a {
		border-bottom: 1px solid #E6E9ED;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	.tabs-top .tab.active + .tab a {
		border-top-color: #CED7E0;
	}
	.tabs-top .tab.active + .tab a:hover {
		border-top-color: #1ABB9C;
	}
	.filter:before, .format-filter:before {
		display: none;
	}
	h1.single-block .btn {
		margin-#{$right}: 0;
	}
	.sidemeta .date-wrapper,
	.classic-blog.sidebar-left .sidemeta .date-wrapper {
		top: -80px;
		#{$right}: 50%;
		margin-#{$right}: -60px;
	}
	.sidemeta .format-wrapper,
	.classic-blog.sidebar-left .sidemeta .format-wrapper {
		top: -70px;
		#{$right}: 50%;
		margin-#{$right}: 10px;
	}
	.classic-blog .sidemeta,
	.classic-blog .pagination {
		padding-#{$right}: 0;
		border-#{$right}: none;
		margin-#{$right}: 0;
	}
	.classic-blog.sidebar-left .sidemeta,
	.classic-blog.sidebar-left .pagination {
		padding-#{$left}: 0;
		border-#{$left}: none;
		margin-#{$left}: 0;
	}
	.classic-blog.no-sidebar .sidemeta,
	.classic-blog.no-sidebar .pagination {
		#{$right}: 0;
	}
	.classic-blog .post {
		margin-top: 80px;
		width: 100%;
	}
	.grid-blog.col-2-custom {
		padding-#{$left}: 0;
	}
	.sidebar-left .grid-blog.col-2-custom,
	.sidebar-left .grid-blog.col-2-custom + .pagination {
		padding-#{$right}: 0;
	}
	.classic-blog .post,
	.grid-blog.col-2-custom .post,
	.grid-blog.col-3-custom .post,
	.grid-blog.col-2-custom.no-sidebar .post,
	.grid-blog.col-3-custom.no-sidebar .post {
		padding-bottom: 30px;
	}
	.classic-blog .post-content > *,
	.grid-blog.col-2-custom .post-content > *,
	.grid-blog.col-3-custom .post-content > *,
	.grid-blog.col-2-custom.no-sidebar .post-content > *,
	.grid-blog.col-3-custom.no-sidebar .post-content > * {
		margin-#{$right}: 30px;
		margin-#{$left}: 30px;
	}
	.classic-blog .post-content .meta,
	.grid-blog.col-2-custom .post-content .meta,
	.grid-blog.col-3-custom .post-content .meta,
	.grid-blog.col-2-custom.no-sidebar .post-content .meta,
	.grid-blog.col-3-custom.no-sidebar .post-content .meta {
		padding-#{$right}: 30px;
		padding-#{$left}: 30px;
		margin-bottom: 20px;
	}
	.classic-blog .post-content > *:first-child,
	.classic-blog .post-media + *,
	.grid-blog.col-2-custom .post-content > *:first-child,
	.grid-blog.col-2-custom .post-media + *,
	.grid-blog.col-3-custom .post-content > *:first-child,
	.grid-blog.col-3-custom .post-media + *,
	.grid-blog.col-2-custom.no-sidebar .post-content > *:first-child,
	.grid-blog.col-2-custom.no-sidebar .post-media + *,
	.grid-blog.col-3-custom.no-sidebar .post-content > *:first-child,
	.grid-blog.col-3-custom.no-sidebar .post-media + * {
		margin-top: 30px;
	}
	.classic-blog .post-title,
	.grid-blog.col-2-custom .post-title,
	.grid-blog.col-3-custom .post-title,
	.grid-blog.col-2-custom.no-sidebar .post-title,
	.grid-blog.col-3-custom.no-sidebar .post-title {
		font-size: 24px;
	}
	.classic-blog p,
	.grid-blog.col-2-custom p,
	.grid-blog.col-3-custom p,
	.grid-blog.col-2-custom.no-sidebar p,
	.grid-blog.col-3-custom.no-sidebar p {
		font-size: 16px;
		line-height: 150%;
		margin-bottom: 10px;
	}
	.classic-blog .pagination,
	.classic-blog .comment-form-wrapper,
	.pagination {
		margin-bottom: 100px;
	}
	.grid-blog.col-2-custom.no-sidebar + .pagination,
	.grid-blog.col-3-custom.no-sidebar + .pagination {
		margin-bottom: 0;
	}
	.classic-blog .pagination,
	.classic-blog.sidebar-left .pagination,
	.grid-blog.col-2-custom + .pagination,
	.grid-blog.col-3-custom + .pagination,
	.grid-blog.col-2-custom.no-sidebar + .pagination,
	.grid-blog.col-3-custom.no-sidebar + .pagination {
		margin-#{$right}: -10px;
	}
	.pagination > li:first-child {
		margin-#{$right}: 10px;
	}
	.pagination li {
		margin-bottom: 10px;
	}
	#blog-post .post h2 {
		font-size: 19px;
		margin-bottom: 15px;
	}
	#blog-post .post h3 {
		font-size: 16px;
	}
	#blog-post .post h4 {
		font-size: 14px;
	}
	#blog-post .post figure figcaption p {
		font-size: 14px;
		line-height: 150%;
	}
	[class*="col"] > figcaption:first-child {
		padding-top: 20px;
	}
	.post-author, #comments {
		padding: 30px;
	}
	.post-author .author-image {
		float: none;
		display: inline-block;
		margin-bottom: 20px;
	}
	.post-author .author-details {
		padding-#{$right}: 0;
	}
	#comments .avatar {
		float: none;
		display: inline-block;
		margin-bottom: 15px;
	}
	#comments .commentbody {
		padding-#{$right}: 0;
	}
	#comments .commentlist .children {
		padding-#{$right}: 0;
	}
	[class*="col"][class*="border-left"] {
		border-#{$right}: none !important;
	}
	[class*="col"][class*="border-right"] {
		border-#{$left}: none !important;
	}
	.filter li {
		display: block;
	}
	.filter li + li {
		margin-top: 15px;
	}
	.filter li:after {
		display: none;
	}
	#hero .info,
	#hero .info,
	#hero .info,
	.items li .text-overlay .info.big,
	.thumbs .thumb .text-overlay .info.big,
	.owl-carousel .item .text-overlay .info.big {
		margin-top: -25px;
	}
	.items li .text-overlay .info h2,
	.thumbs .thumb .text-overlay .info h2,
	.owl-carousel .item .text-overlay .info h2,
	.items li .text-overlay .info h3,
	.thumbs .thumb .text-overlay .info h3,
	.owl-carousel .item .text-overlay .info h3,
	.items li .text-overlay .info h4,
	.thumbs .thumb .text-overlay .info h4,
	.owl-carousel .item .text-overlay .info h4 {
		font-size: 16px;
		line-height: 150%;
	}
	.items li .text-overlay .info p,
	.thumbs .thumb .text-overlay .info p,
	.owl-carousel .item .text-overlay .info p,
	.items li .text-overlay .info.big p,
	.thumbs .thumb .text-overlay .info.big p,
	.owl-carousel .item .text-overlay .info.big p,
	#hero .items li .text-overlay .info.big p,
	#hero .thumbs .thumb .text-overlay .info.big p,
	#hero .owl-carousel .item .text-overlay .info.big p {
		font-size: 14px;
		line-height: 140%;
		padding-top: 0;
	}
	.tabs-2-big-top .tab {
		width: 100%;
		margin: 0 0 50px 0;
	}
	.tabs-2-big-top .tab a {
		padding: 25px;
	}
	.tabs-2-big-top.tab-container .panel-container {
		margin-top: 0;
	}
	[class*="col"][class*="inner-left"],
	[class*="col"][class*="inner-right"],
	[class*="col"][class*="reset-padding"] {
		padding-#{$right}: 15px;
		padding-#{$left}: 15px;
	}
	[class*="inner-top"].reset-xs {
		padding-top: 0 !important;
	}
	.tabs-circle-top .etabs:before {
		border-bottom: none;
		margin: 0;
	}
	.tabs-circle-top .tab {
		display: table;
		width: 100%;
		height: auto;
		margin: 0;
	}
	.tabs-circle-top .tab.active {
		top: 0;
	}
	.tabs-circle-top .tab a,
	.tabs-circle-top .tab.active a {
		width: 100%;
		max-width: 100%;
		height: auto;
		font-size: 18px;
		text-align: $right;
		padding-top: 10px;
		padding-bottom: 10px;
		border-top: none;
		border-radius: 0;
	}
	.tabs-circle-top .tab a:hover {
		border-width: 1px;
		border-color: #CED7E0 !important;
		border-top: none;
	}
	.tabs-circle-top .tab.active a:hover {
		border-color: transparent !important;
	}
	.tabs-circle-top .tab:first-child a {
		border-top: 1px solid #CED7E0;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}
	.tabs-circle-top .tab:last-child a {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	.tabs-circle-top .tab a div,
	.tabs-circle-top .tab.active a div {
		display: inline-block;
		vertical-align: middle;
		font-size: 30px;
		line-height: 120%;
		padding-bottom: 5px;
		margin: 0 0 0 10px;
	}
	.yamm-content {
		margin-top: 0;
	}
	.yamm-content > [class*="col"] {
		margin-bottom: 0;
	}
	.navbar .container,
	.container {
	    padding-#{$left}: 20px;
	    padding-#{$right}: 20px;
	}
	.navbar-collapse {
		padding-#{$right}: 0;
		padding-#{$left}: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
	.footer-bottom p.float-left,
	.footer-bottom ul.float-right {
	    float: none !important;
	    text-align: center;
	}
	.footer-bottom p {
	    margin-bottom: 20px;
	}
	.footer-menu li {
	    padding: 0 5px;
	}
	img {
	    max-width: 100%;
	    height: auto;
	}
	.filter li a:before {
	    display: none;
	}
	.items {
	    margin-#{$right}: 0 !important;
	    /*margin-bottom: -20px !important;*/
	}
	.items li {
	    width: 100% !important;
	    margin-#{$right}: 0 !important;
	    margin-bottom: 20px !important;
	}
	.items.fullscreen li {
	    margin: 0 !important;
	}
	.sidebar {
	    margin-top: 70px;
	    padding-#{$right}: 15px !important;
	}
	.blog.no-sidebar .post,
	.blog.no-sidebar #comments,
	.blog.no-sidebar .pagination,
	.blog.no-sidebar .comment-form-wrapper {
	    width: 100%
	}
	.blog.no-sidebar .pagination ul {
	    margin: 0
	}
	.grid-blog {
	    margin-#{$right}: 0 !important;
	    margin-bottom: 20px !important;
	}
	.grid-blog .post {
	    width: 100% !important;
	    margin-#{$right}: 0 !important;
	    margin-bottom: 20px !important;
	}
	#comments ul.children {
	    padding: 0 20px 0 0;
	}
	#comments .arrow-box {
	    margin-#{$right}: 0;
	}
	.panel-title {
	    line-height: 18px;
	}
	.yamm .yamm-content.row {
	    margin-bottom: -20px;
	}
	.modal .container {
		padding-#{$right}: 35px;
		padding-#{$left}: 35px;
	}
	.modal-dialog {
		top: 25px;
		bottom: 25px;
		#{$right}: 25px;
		#{$left}: 25px;
		width: calc(100% - 50px);
	}
	.modal-open {
		background-color: #F2F5F7;
	}
}

@media (min-width: 768px) {
	.navbar-nav > li {
		float: $right;
	}
}

@media (min-width: 768px) and (max-height: 1024px) {
	.height-xs,
	.height-sm,
	#owl-main .item {
		height: 480px;
	}
	#hero h1 {
		font-size: 60px;
		line-height: 115%;
	}
	#hero p {
		font-size: 22px;
		line-height: 135%;
		margin-top: 17px;
	}
	#hero #owl-main .caption h1.light-bg,
	#hero #owl-main .caption h1.medium-bg,
	#hero #owl-main .caption h1.dark-bg,
	#hero #owl-main .caption h1.tint-bg {
		font-size: 50px;
	}
	#hero .btn {
		margin-top: 35px;
	}
	#hero .btn-large {
		font-size: 16px;
		padding: 15px 35px 17px;
	}
}

@media (min-width: 768px) and (max-height: 768px) {
	.height-xs,
	.height-sm,
	.height-md,
	.height-lg,
	#owl-main .item,
	#owl-main.height-md .item,
	#owl-main.height-lg .item {
		height: 480px;
	}
}

@media (max-width: 480px) {
	.icon.pos-left i.icn {
		font-size: 60px;
	}
	.modal .container {
		padding-#{$right}: 25px;
		padding-#{$left}: 25px;
	}
	.modal-dialog {
		top: 15px;
		bottom: 15px;
		#{$right}: 15px;
		#{$left}: 15px;
		width: calc(100% - 30px);
	}
	.modal-header {
		padding: 14px 15px;
	}
	.modal-title {
		font-size: 16px;
		line-height: 120%;
	}
}

