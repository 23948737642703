// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Breakpoints map
$breakpoints: (
  'sbsiteSizeMobile': (max-width: 1024px),
  'mobile-m': (max-width: 375px),
  'mobile-l': (max-width: 425px),
  'extra-small': (max-width: 480px),
  'small': (max-width: 768px),
  'medium': (max-width: 1024px),
  'large': (max-width: 1200px),
  'larger': (max-width: 1300px),
  'huge': (max-width: 1400px),
) !default;

//dir set
@if $dir != ltr and $dir != rtl {
  $dir: ltr;
}

//left / right according to dir
$left: if-ltr(right, left);
$right: if-ltr(left, right);

$clr-base-dark: #0c1e23; 
$clr-base-orange: #F27A24; //d46b3c c2754c

$clr-base-txt: #1e4654; // 73879C 

// #506A85 основной

// #73879C - светло серый (текст + ссылки)  --------  1e4654

// #2F4052 дарк биги

// #283645 футер нижний

// №№№№№№№№№№№№№№№№№№№№№

// #F27A24 тинт биги

//Social color schemes
$clr-facebook: #3b5998;
$clr-youtube: #FF0000;
$clr-twitter: #1da1f2;
$clr-instagram: #c32aa3;
$clr-linkedin: #007bb5;
$clr-googleplus: #db4437;
$clr-whatsapp: #25d366;

/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;


/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;
