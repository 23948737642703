
/*===================================================================================*/
/*	PORTFOLIO
/*===================================================================================*/

.items {
  list-style: none;
  margin: 0;
  padding: 0;
}
.items:before,
.items:after {
  display: table;
  content: "";
  line-height: 0;
}
.items:after {
  clear: both
}
.items li {
  float: $right;
  min-height: 1px;
  position: relative;
}
.items.col-3-custom {
  margin-#{$right}: -8px;
  margin-bottom: -8px;
}
.items.col-3-custom li {
  width: 314px;
  margin-#{$right}: 8px;
  margin-bottom: 8px;
}
.items.col-3-custom.gap {
  margin-#{$right}: -20px;
  margin-bottom: -20px;
}
.items.col-3-custom.gap li {
  width: 306px;
  margin-#{$right}: 20px;
  margin-bottom: 20px;
}
.items.col-4-custom {
  margin-#{$right}: -10px;
  margin-bottom: -10px;
}
.items.col-4-custom li {
  width: 232px;
  margin-#{$right}: 10px;
  margin-bottom: 10px;
}
.items.col-4-custom .post-title {
  font-size: 16px;
  margin-bottom: 3px;
}
.items.fullscreen {
  width: 100%;
  clear: both;
margin: 0 0 -1px 0;
  padding: 0;
  list-style: none;
}
.items.fullscreen li {
  float: $right;
  position: relative;
  background: none;
  padding: 0;
width: 16.666666666%;
}
.items.fullscreen li img {
  display: block;
  /*position: relative;*/
  width: 100%;
  height: auto;
}
.items.fullscreen:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.items li img {
  display: block;
  width: 100%;
}
.items li,
.items li img,
.thumbs .thumb figure,
.owl-carousel .item figure {
display: block;
  position: relative;
}
.items li {
  overflow: hidden;
}
.items li .text-overlay,
.thumbs .thumb .text-overlay,
.owl-carousel .item .text-overlay {
position: absolute;
top: 0;
#{$left}: 0;
bottom: 0;
#{$right}: 0;
  z-index: 100;
padding: 10%;
font: 0px/0 a; /* remove the gap between inline(-block) elements */
  background-color: rgba(26,187,156, 0.9);
  -webkit-transition: all 200ms ease-out;
     -moz-transition: all 200ms ease-out;
       -o-transition: all 200ms ease-out;
          transition: all 200ms ease-out;
  opacity: 0;
}
.items li .text-overlay:before,
.thumbs .thumb .text-overlay:before,
.owl-carousel .item .text-overlay:before {
content: "";
display: inline-block;
vertical-align: middle;
height: 100%;
}

.items li:hover .text-overlay,
.thumbs .thumb:hover .text-overlay,
.owl-carousel .item:hover .text-overlay {
  opacity: 1;
}
.thumbs .thumb:hover .member .text-overlay {
opacity: 0;
}
.thumbs .thumb .member .member-image:hover .text-overlay {
opacity: 1;
}
.items li .text-overlay .info,
.thumbs .thumb .text-overlay .info,
.owl-carousel .item .text-overlay .info {
display: inline-block;
vertical-align: middle;
width: 100%;
height: auto;
font-family: 'Rubik', sans-serif;
font-size: 14px;
line-height: 22px;
/*color: #FFF;*/
text-align: center;
}
.items li .text-overlay .info h2,
.thumbs .thumb .text-overlay .info h2,
.owl-carousel .item .text-overlay .info h2,
.items li .text-overlay .info h3,
.thumbs .thumb .text-overlay .info h3,
.owl-carousel .item .text-overlay .info h3,
.items li .text-overlay .info h4,
.thumbs .thumb .text-overlay .info h4,
.owl-carousel .item .text-overlay .info h4 {
color: #FFF;
margin-bottom: 2px;
}
.items li .text-overlay .info p,
.thumbs .thumb .text-overlay .info p,
.owl-carousel .item .text-overlay .info p {
font-size: 14px;
line-height: 22px;
color: #FFF;
margin-bottom: 0;
}
.items li .text-overlay .info.big p,
.thumbs .thumb .text-overlay .info.big p,
.owl-carousel .item .text-overlay .info.big p,
#hero .items li .text-overlay .info.big p,
#hero .thumbs .thumb .text-overlay .info.big p,
#hero .owl-carousel .item .text-overlay .info.big p {
font-size: 18px;
line-height: 140%;
padding-top: 10px;
margin-top: 0;
}

#owl-clients .item .text-overlay,
#clients .thumb .text-overlay {
background: none;
}

#owl-clients .item img,
#clients .thumb img {
  -webkit-transition: all 200ms ease-out;
     -moz-transition: all 200ms ease-out;
       -o-transition: all 200ms ease-out;
          transition: all 200ms ease-out;
}

#owl-clients .item:hover img,
#clients .thumb:hover img {
opacity: .1;
}

#owl-clients .item .text-overlay .info h4,
#owl-clients .item .text-overlay .info p,
#clients .thumb .text-overlay .info h4,
#clients .thumb .text-overlay .info p {
color: inherit;
}
.filter,
.format-filter {
padding: 0;
  margin-bottom: 40px;
}
.filter:before,
.format-filter:before {
content: "";
display: block;
position: absolute;
top: 50%;
width: 100%;
border-bottom: 1px solid #CED7E0;
}
.filter li,
.format-filter li {
  display: inline;
position: relative;
background: #FFF;
font-size: 15px;
  font-weight: 700;
line-height: 150%;
letter-spacing: .031em;
  text-transform: uppercase;
margin: 0 -2px;
}
.light-bg .format-filter li {
background: #F2F5F7;
}
.filter li:after,
.format-filter li:after {
content: "\00b7";
}
.filter li:last-child:after,
.format-filter li:last-child:after {
content: "";
}
.filter li a,
.format-filter li a {
  padding: 0 15px;
}
.light-bg .filter:before {
border-color: #BCC7D1;
}
.light-bg .filter li {
background: #F2F5F7;
}
.dark-bg .filter:before {
border-color: $clr-base-txt;
}
.dark-bg .filter li {
background: #071215;
}
.isotope,
.isotope .isotope-item {
-webkit-transition-duration: 600ms;
   -moz-transition-duration: 600ms;
    -ms-transition-duration: 600ms;
     -o-transition-duration: 600ms;
        transition-duration: 600ms;
}
.isotope {
-webkit-transition-property: height, width;
   -moz-transition-property: height, width;
    -ms-transition-property: height, width;
     -o-transition-property: height, width;
        transition-property: height, width;
}
.isotope .isotope-item {
-webkit-transition-property: -webkit-transform, opacity;
   -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
     -o-transition-property: top, right, opacity;
        transition-property: transform, opacity;
}
.isotope figure,
.items figure {
margin: 0;
}
.post-content .isotope figure {
margin: auto;
}
