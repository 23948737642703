
/*===================================================================================*/
/*	ACCORDION / TOGGLE
/*===================================================================================*/

.panel-group {
	margin-bottom: 0;
}
.panel-group + .panel-group {
	margin-top: 20px;
}
.panel-group .panel {
    background: #F5F7FA;
    border: 1px solid #E6E9ED;
	border-bottom-width: 2px;
    border-radius: 3px;
    box-shadow: none;
}
.panel-group .panel + .panel {
	margin-top: 10px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
	border-top: 1px solid #E6E9ED;
}
.panel-body {
	padding: 25px;
}
.panel-heading {
	background-color: inherit !important;
	color: inherit !important;
    padding: 0;
	border-color: inherit !important;
}
.panel-title {
	font-family: 'Rubik', sans-serif;
    font-size: 15px;
	line-height: 1.42857;
    font-weight: 700;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0;
}
.panel-title > a {
    display: block;
    padding: 15px 25px 15px;
}
.panel-title > a.collapsed {
	background: #F5F7FA;
	color: $clr-base-dark;
}
.panel-title > a,
.panel-title > a:hover {
	background: #EDF0F2;
}
.panel-title > a {
	color: $clr-base-dark;
}
.panel-title > a:hover {
	color: #1ABB9C;
}
.panel-title > a span {
	display: block;
	position: relative;
	top: 0;
	color: $clr-base-dark;
	padding-#{$left}: 25px;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}
.text-right .panel-title > a span {
	padding-#{$right}: 25px;
	padding-#{$left}: 0;
}
.text-center .panel-title > a span {
	padding-#{$right}: 0;
	padding-#{$left}: 0;
}
.panel-title > a:hover span {
	color: inherit;
}
.text-center .panel-title > a:hover span {
	top: .5em;
	color: transparent;
}
.panel-title > a span:after {
	font-family: "fontello";
	content: "\e849";
	position: absolute;
	top: 0;
	#{$left}: 0;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}
.text-right .panel-title > a span:after {
	#{$right}: 0;
	#{$left}: auto;
}
.text-center .panel-title > a span:after {
	top: -.75em;
	#{$right}: 0;
	opacity: 0;
}
.panel-title > a.collapsed span:after {
	content: "\e84c";
}
.text-center .panel-title > a:hover span:after {
	color: #1ABB9C;
	top: -.5em;
	opacity: 1;
}
.panel-group .bordered {
	background: #FFF;
}

.light-bg .panel-group .panel {
    background: #FFF;
}
.light-bg .panel-title > a.collapsed,
.light-bg .blank .panel-title > a.collapsed {
	background: #F8FAFD;
}
.light-bg .panel-title > a,
.light-bg .blank .panel-title > a,
.light-bg .panel-title > a:hover,
.light-bg .blank .panel-title > a:hover {
	/*background: #E6E9ED;*/
	background: #FFF;
}

.dark-bg .panel-group .panel,
.dark-bg .panel-group .panel-heading + .panel-collapse .panel-body {
	border-color: #485867;
}
.dark-bg .panel-group .panel {
    background: #3A4A59;
}
.dark-bg .panel-title > a.collapsed {
	background: #566473;
}
.dark-bg .panel-title > a,
.dark-bg .panel-title > a:hover {
	/*background: #E6E9ED;*/
	background: #485867;
}
.dark-bg .panel-title > a,
.dark-bg .panel-title > a span {
	color: #FFF;
}
.dark-bg .blank .panel-title > a.collapsed {
    background: #071215;
}
.dark-bg .blank .panel-title > a.collapsed,
.dark-bg .blank .panel-title > a {
    border-color: #566473;
}
.dark-bg .blank .panel-title > a,
.dark-bg .blank .panel-title > a:hover {
	background: #485867;
}

.blank .panel-title > a.collapsed {
    background: #FFF;
}
.panel-group.blank .panel {
    background: none;
}
.panel-group.blank .panel {
	border: none;
}
.blank .panel-title > a.collapsed,
.blank .panel-title > a {
    border: 1px solid #E6E9ED;
}
.blank .panel-title > a,
.blank .panel-title > a:hover {
	background: #F5F7FA;
}
.blank .panel-heading,
.blank .panel-title > a {
    border-radius: 3px;
}
.panel-group.blank .panel-heading + .panel-collapse .panel-body {
	border: none;
}
.blank .panel-body {
	padding: 35px 0;
}
.blank.panel-group .bordered,
.light-bg .panel-group .bordered,
.dark-bg .panel-group .bordered {
	background: #F5F7FA;
}
.light-bg .blank.panel-group .bordered {
	background: #FFF;
}