
/*===================================================================================*/
/*	GO TO TOP / SCROLL UP
/*===================================================================================*/

#scrollUp {
	bottom: 20px;
	#{$right}: 20px;
	font-size: 36px;
	line-height: 100%;
	color: #FFF;
	background-color: $clr-base-dark;
	padding: 3px;
	-webkit-border-radius: 3px;
	   -moz-border-radius: 3px;
			border-radius: 3px;
	opacity: .5;
}
#scrollUp:hover {
	color: #1ABB9C;
	opacity: 1;
}