@charset 'UTF-8';

$dir: rtl !default;

.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}

// 1. Configuration and helpers
@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/mixins';

// 2. Vendors


// 3. Base stuff
 @import
   'base/base',
   'base/fonts';
//   'base/typography',
  //  'base/helpers';

// 4. Layout-related sections
//  @import
//    'layout/header',
//    'layout/footer';

// 6. Page-specific styles
  // @import
  //  'base/fonts',
    // 'pages/home',
    // 'pages/mainrtl';

// 5. Components
  @import
    'components/header',
    'components/buttons',
    'components/social-icons',
    'components/tabs',
    // 'components/testimonials',
    'components/accordion',
    'components/portfolio',
    'components/overlay',
    // 'components/portfolio-post',
    'components/owl-carusel',
    // 'components/pricing-tables',
    'components/forms',
    // 'components/blog',
    // 'components/author',
    // 'components/sidebar',
    'components/tooltips',
    // 'components/pagination',
    // 'components/comments',
    'components/scrollup',
    'components/features',
    'components/modals',
    'components/responsive';
  
// 7. Themes
  @import
    'themes/orange';

  
@import "E:/xampp/htdocs/isradelet.co.il#php/resources/views/components/popup-form/popup-form.scss";
  
  


// 6. Page-specific styles
  @import
  'custom';

/*===================================================================================*/
/*	MEDIA QUERIES | BREAKPOINTS
/*===================================================================================*/

body:before {
	content: 'xs';
	display: none;
}

/*@media (min-width: 768px) {
	body:before {
		content: 'sm';
	}
}*/

@media (min-width: 1025px) {
	body:before {
		content: 'md';
	}
}

/*@media (min-width: 1200px) {
	body:before {
		content: 'lg';
	}
}*/  
