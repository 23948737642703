
/*===================================================================================*/
/*	OVERLAY
/*===================================================================================*/

.icon-overlay {
  display: block;
  position: relative;
}
.icon-overlay img {
  display: block;
  max-width: 100%;
}
.icon-overlay a .icn-more {
  opacity: 0;
  height: 100%;
  position: absolute;
#{$right}: 0;
top: 0;
  text-decoration: none;
  width: 100%;
  z-index: 100;
  background-color: rgba(26,187,156, 0.9);
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  overflow: hidden;
}
.icon-overlay a:hover .icn-more {
  opacity: 1;
}
.icon-overlay a .icn-more:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  position: absolute;
  text-align: center;
  content: '\2b';
  width: 60px;
  height: 60px;
  font-size: 60px;
  line-height: 1;
  color: #FFF;
  top: 50%;
  #{$right}: 50%;
  z-index: 2;
  margin: -30px -30px 0 0;
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.icon-overlay a:hover .icn-more:before {
  font-size: 30px;
padding-top: 15px;
}
.icon-overlay.icn-link a .icn-more:before {
  content: '\2b';
}