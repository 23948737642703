
/*===================================================================================*/
/*	BUTTONS
/*===================================================================================*/

#buttons .btn {
	margin: 5px 2px;
}
#buttons .btn-wrapper {
	margin: 30px 0;
}
.btn {
    color: #FFF !important;
    background: #1ABB9C;
    padding: 11px 20px 13px;
	margin: 15px 0;
	font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
	letter-spacing: 0.02em;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    border: none;
    text-transform: uppercase;
    -webkit-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
    box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
}
.btn-small {
    padding: 5px 13px 6px;
	font-size: 13px;
}
.btn-large {
    padding: 14px 26px 16px;
	font-size: 15px;
}
.btn.btn-green {
    background: #1ABB9C;
}
.btn.btn-blue {
    background: #3F8DBF;
}
.btn.btn-red {
    background: #FA6C65;
}
.btn.btn-pink {
    background: #D487BE;
}
.btn.btn-purple {
    background: #9B59B6;
}
.btn.btn-orange {
    background: $clr-base-orange;
}
.btn.btn-navy {
    background: #34495E;
}
.btn.btn-gray {
    background: #95A5A6;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
    background: #17A78B;
    color: #FFF !important;
    -webkit-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
    box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
}
.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active {
    background: #17A78B !important;
}
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active {
    background: #387EAA !important;
}
.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active {
    background: #CF4C45 !important;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active {
    background: #BD78A9 !important;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active {
    background: #8A4FA2 !important;
}
.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active {
    background: #D96716 !important;
}
.btn-navy:hover,
.btn-navy:focus,
.btn-navy:active,
.btn-navy.active {
    background: #2C3E50 !important;
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active {
    background: #859394 !important;
}
.tint-bg .btn {
    background: #FFF !important;
	color: #1ABB9C !important;
}
.tint-bg .btn:hover {
    background: #E9E9E9 !important;
    color: $clr-base-dark !important;
}
.btn.share-facebook {
    background: #3d5b9b !important
}
.btn.share-facebook:hover {
    background: #334c8e !important
}
.btn.share-twitter {
    background: #5aa8cd !important
}
.btn.share-twitter:hover {
    background: #499ac8 !important
}
.btn.share-googleplus {
    background: #3b3b3b !important
}
.btn.share-googleplus:hover {
    background: #2e2e2e !important
}
.btn.share-pinterest {
    background: #c53942 !important
}
.btn.share-pinterest:hover {
    background: #bc2d32 !important
}
.btn-share-lg,
.btn-share-md,
.btn-share-sm,
.btn-share-xs {
	/*display: table;*/
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	z-index: 0;
	width: 100%;
	text-align: center;
}
.btn-share-lg i,
.btn-share-md i,
.btn-share-sm i,
.btn-share-xs i {
	/*display: table-cell;
	vertical-align: middle;*/
	-ms-flex-item-align: center;
	align-self: center;
	margin-#{$right}: auto;
	margin-#{$left}: auto;
}
.btn-share-lg {
	height: 250px;
}
.btn-share-lg i {
	font-size: 80px;
}
.btn-share-md {
	height: 180px;
}
.btn-share-md i {
	font-size: 60px;
}
.btn-share-sm {
	height: 120px;
}
.btn-share-sm i {
	font-size: 40px;
}
.btn-share-xs {
	height: 80px;
}
.btn-share-xs i {
	font-size: 28px;
}
.btn-share-lg i:after,
.btn-share-md i:after,
.btn-share-sm i:after,
.btn-share-xs i:after {
	content: "";
	position: absolute;
	top: 0;
	#{$right}: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
    -webkit-transition: all 200ms ease-out;
       -moz-transition: all 200ms ease-out;
         -o-transition: all 200ms ease-out;
            transition: all 200ms ease-out;
}
/*.btn-share-lg:hover i:after,
.btn-share-md:hover i:after,
.btn-share-sm:hover i:after,
.btn-share-xs:hover i:after {
	height: 100%;
}*/
.btn-share-lg:hover .icon-s-facebook:after,
.btn-share-md:hover .icon-s-facebook:after,
.btn-share-sm:hover .icon-s-facebook:after,
.btn-share-xs:hover .icon-s-facebook:after {
    background: #3B5998;
    background: #4668B3;
}
.btn-share-lg:hover .icon-s-gplus:after,
.btn-share-md:hover .icon-s-gplus:after,
.btn-share-sm:hover .icon-s-gplus:after,
.btn-share-xs:hover .icon-s-gplus:after {
    background: #DD4B39;
    background: #DE5745;
}
.btn-share-lg:hover .icon-s-twitter:after,
.btn-share-md:hover .icon-s-twitter:after,
.btn-share-sm:hover .icon-s-twitter:after,
.btn-share-xs:hover .icon-s-twitter:after {
    background: #00ACED;
    background: #2FBBED;
}
.btn-share-lg p,
.btn-share-md p,
.btn-share-sm p,
.btn-share-xs p {
	position: absolute;
	height: 1.1em;
	font-family: 'Rubik', sans-serif;
	line-height: 100%;
	color: #FFF;
	margin: auto;
	opacity: 0;
}
.btn-share-lg p,
.btn-share-md p {
	top: 20px;
}
.btn-share-sm p,
.btn-share-xs p {
	top: 0;
	bottom: 0;
}
.btn-share-sm p {
	font-size: 16px;
}
.btn-share-xs p {
	font-size: 15px;
}
.btn-share-lg:hover p,
.btn-share-md:hover p,
.btn-share-sm:hover p,
.btn-share-xs:hover p {
	opacity: 1;
}
.btn-share-lg .name,
.btn-share-md .name,
.btn-share-sm .name,
.btn-share-xs .name {
	#{$right}: 25px;
}
.btn-share-lg .counter,
.btn-share-md .counter,
.btn-share-sm .counter,
.btn-share-xs .counter {
	#{$left}: 25px;
}
.btn-share-lg:hover,
.btn-share-md:hover,
.btn-share-sm:hover,
.btn-share-xs:hover {
	color: #FFF;
}
h1 .btn,
h2 .btn,
h3 .btn,
h4 .btn,
p .btn {
	margin-#{$right}: 15px;
}
.txt-btn {
	font-size: 105%;
	font-weight: 700;
}
.txt-btn:after {
	font-family: 'fontello';
	font-size: 75%;
	content: '\e846';
	margin-#{$right}: 7px;
    -webkit-transition: all 200ms ease-out;
       -moz-transition: all 200ms ease-out;
         -o-transition: all 200ms ease-out;
            transition: all 200ms ease-out;
}
.txt-btn:hover:after {
	margin-#{$right}: 12px;
}