a:hover,
a:focus,
.bordered .info h3 a:hover,
.bordered .info h4 a:hover,
.icon i.icn,
.meta a:hover,
.meta li.categories:hover:before,
.more:hover,
.navbar.basic .nav > li > a:hover,
.navbar.basic .nav > li > a:focus,
.navbar.basic .nav .open > a,
.navbar.basic .nav .open > a:hover,
.navbar.basic .nav .open > a:focus,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a.active,
.navbar-header .info a:hover,
.nav > li > a:hover,
.owl-carousel:hover .owl-next:hover,
.owl-carousel:hover .owl-prev:hover,
.panel-title > a:hover,
#scrollUp:hover,
.sidemeta .format-wrapper a,
.tabs-side .tab.active a,
.tabs-top .tab.active a,
.text-center .panel-title > a:hover span:after,
ul.arrowed li:before,
ul.circled li:before,
.yamm .yamm-content a:hover {
	color: $clr-base-orange;
}

.tint-bg .btn,
.tint-color {
	color: $clr-base-orange !important;
}

.btn,
.classic-blog .post .date,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:focus,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:focus,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:hover,
.navbar .dropdown-submenu:focus > a,
.navbar .dropdown-submenu:hover > a,
.owl-pagination .owl-page.active span,
.owl-pagination .owl-page:hover span,
#owl-main .caption h1 span.tint-bg,
#owl-main .caption p span.tint-bg,
.pagination > li > a,
.pagination > li > span,
.post.format-quote,
.sidemeta .format-wrapper a:hover,
.sidenav li a:hover,
.tabs-circle-top .tab.active a,
.tabs-side .tab a:hover,
.tabs-top .tab a:hover,
.tint-bg,
.tint-bg.img-bg-soft:before,
.tint-bg.img-bg-softer:before,
.tooltip-inner {
	background-color: $clr-base-orange;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > li > a:hover,
.pagination > li > a:focus {
	background-color: #D96716;
}

.icon-overlay a .icn-more,
.items li .text-overlay,
.owl-carousel .item .text-overlay,
.thumbs .thumb .text-overlay {
	background-color: rgba(242, 122, 36, 0.9);
}

.classic-blog .post .date,
.icon.circle,
.sidemeta .format-wrapper a,
.tabs-circle-top .tab.active a,
.tabs-circle-top .tab a:hover,
.tabs-side .tab a:hover,
.tabs-top .tab a:hover {
	border-color: $clr-base-orange;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: $clr-base-orange;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
	border-top-color: $clr-base-orange;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-left-color: $clr-base-orange;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
	border-right-color: $clr-base-orange;
}

#owl-main .caption h1 span.tint-bg,
#owl-main .caption p span.tint-bg  {
	box-shadow: -.500em 0 0 $clr-base-orange, .500em 0 0 $clr-base-orange;
}

::selection {
	background: #F7CAA6; /* Safari */
}

::-moz-selection {
	background: #F7CAA6; /* Firefox */
}