
/*===================================================================================*/
/*	FORMS
/*===================================================================================*/

.forms {
  position: relative;
  padding: 0;
  width: 100%;
}
.form-container .response {
  display: none;
}
.forms ol {
  margin: 0;
  padding: 0;
}
.forms ol li {
  line-height: auto;
  list-style: none;
}
input,
textarea,
.uneditable-input,
.form-inline .form-control,
.navbar-form .form-control {
  width: 100%;
}
select,
textarea,
textarea.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 25px;
  color: $clr-base-txt;
  resize: none;
  vertical-align: middle;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #f5f5f5;
  border: 1px solid #E6E9ED;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
}
textarea:focus,
textarea.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border: 1px solid #BCC7D1;
  box-shadow: none;
}
textarea {
  height: 100%;
  padding: 10px;
  margin-bottom: 15px;
  min-height: 150px;
  resize: vertical;
}
textarea.form-control {
padding: 10px;
}
.btn-submit {
  width: auto;
}
.forms .btn-submit {
margin-bottom: 0;
}
.forms .error,
.forms .error:focus {
  border: 1px solid #d99898;
}
.forms label {
  display: block;
  float: $right;
  width: 95px;
  padding-top: 7px;
  font-size: 13px;
  clear: both;
}
.forms input,
.forms textarea {
background: #F8FAFD;
border-radius: 3px;
}
.light-bg .forms input,
.light-bg .forms textarea {
background: #FFF;
}
.form-container:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.newsletter {
  position: relative;
}
.newsletter input[type="email"] {
  background: rgba(255,255,255,0.15);
padding-#{$left}: 100px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  color: #FFF !important;
}
.newsletter input[type="email"]:focus {
  background: rgba(255,255,255,0.20);
}
.navbar .searchbox .dropdown-menu {
width: 270px;
}
.search {
position: relative;
margin: 15px;
margin-bottom: 0;
}
.search input[type="search"] {
  background: #FFF;
padding-#{$left}: 40px;
}
.newsletter .btn-submit,
.search .btn-submit {
  position: absolute;
  #{$left}: 0;
  top: 0;
padding: 0;
margin-top: 5px;
margin-#{$left}: 5px;
  color: #FFF !important;
line-height: 1;
}
.navbar-form.search .btn-submit {
width: 30px;
height: 30px;
}
footer .newsletter .btn-submit {
width: 88px;
height: 30px;
font-size: 13px;
}
#response ul.errors {
margin-bottom: 25px;
}
#response .btn i {
font-size: 12px;
}
.dark-bg input,
.dark-bg button,
.dark-bg select,
.dark-bg textarea {
  color: #A3B1BF;
}
.form-control::-webkit-input-placeholder {
  color: #C2C7CC;
}
.form-control:-moz-placeholder {
  color: #C2C7CC;
}
.form-control::-moz-placeholder {
  color: #C2C7CC;
}
.form-control:-ms-input-placeholder {
  color: #C2C7CC;
}
.dark-bg .form-control::-webkit-input-placeholder {
  color: #828E99;
}
.dark-bg .form-control:-moz-placeholder {
  color: #828E99;
}
.dark-bg .form-control::-moz-placeholder {
  color: #828E99;
}
.dark-bg .form-control:-ms-input-placeholder {
  color: #828E99;
}
.search .form-control::-webkit-input-placeholder {
  color: #C2C7CC;
}
.search .form-control:-moz-placeholder {
  color: #C2C7CC;
}
.search .form-control::-moz-placeholder {
  color: #C2C7CC;
}
.search .form-control:-ms-input-placeholder {
  color: #C2C7CC;
}
input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus:-ms-input-placeholder {
  color: transparent !important;
}