
/*===================================================================================*/
/*	TABS ON SIDE
/*===================================================================================*/

.tabs-side .etabs {
	float: $right;
	width: 20%;
    padding: 0;
	margin: 0 0 0 -1px;
}
.tabs-side .tab {
	display: block;
	position: relative;
	z-index: 0;
}
.tabs-side .tab a {
    display: block;
	background: #F5F7FA;
    color: $clr-base-dark;
	font-size: 14px;
	line-height: 130%;
	font-weight: 700;
    text-transform: uppercase;
	padding: 15px;
    border: 1px solid #E6E9ED;
	border-left-color: #CED7E0;
    border-bottom: none;
	overflow: hidden;
}
.tabs-side .tab:first-child a {
	border-top-right-radius: 3px;
}
.tabs-side .tab:last-child a {
    border-bottom: 1px solid #E6E9ED;
	border-bottom-right-radius: 3px;
}
.tabs-side .tab a:hover {
	background: #1ABB9C;
	color: #FFF;
	border-color: #1ABB9C;
}
.tabs-side .tab:hover,
.tabs-side .tab.active {
	z-index: 2;
}
.tabs-side .tab.active a {
    background: #FFF !important;
	color: #1ABB9C;
    border-color: #CED7E0 !important;
    border-left-color: #FFF !important;
}
.tabs-side .tab.active + .tab a {
	border-top-color: #CED7E0;
}
.tabs-side .tab.active + .tab a:hover {
	border-top-color: #1ABB9C;
}
.tabs-side .tab a div {
	font-family: 'Rubik', sans-serif;
	font-size: 40px;
	line-height: 100%;
	font-weight: 200;
	margin: -10px 0 5px;
}
.tabs-side.tab-container .panel-container {
	position: relative;
	z-index: 1;
	overflow: hidden;
	padding-#{$right}: 70px;
    border-#{$right}: 1px solid #CED7E0;
}
.tabs-side.tab-container .panel-container p {
    margin: 0;
    padding-bottom: 20px;
}


/*===================================================================================*/
/*	TABS ON TOP
/*===================================================================================*/

.tabs-top .etabs {
    padding: 0;
    margin: 0;
}
.tabs-top .etabs:after {
	content: '';
	display: block;
	position: relative;
	z-index: 1;
    border-top: 1px solid #CED7E0;
	margin-top: -1px;
}
.tabs-top .tab {
    display: inline-block;
	position: relative;
	z-index: 0;
	max-width: 40%;
	margin: 0 3px;
}
.tabs-top .tab a {
    display: inline-block;
	vertical-align: bottom;
	background: #F5F7FA;
    color: $clr-base-dark;
	font-size: 14px;
	line-height: 130%;
	font-weight: 700;
    text-transform: uppercase;
	padding: 15px;
    border: 1px solid #E6E9ED;
    border-bottom: none;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	overflow: hidden;
}
.tabs-top .tab a div {
	font-family: 'Rubik', sans-serif;
	font-size: 40px;
	line-height: 100%;
	font-weight: 200;
	margin: -10px 0 5px;
}
.tabs-top .tab a:hover {
	background: #1ABB9C;
	color: #FFF;
	border-color: #1ABB9C;
}
.tabs-top .tab.active {
	z-index: 2;
}
.tabs-top .tab.active a {
    background: #FFF !important;
	color: #1ABB9C;
	border-color: #CED7E0 !important;
}
.tabs-top.tab-container .panel-container {
	margin-top: 70px;
}
.tabs-top.tab-container .panel-container p {
    margin: 0;
    padding-bottom: 20px;
}


/*===================================================================================*/
/*	TABS CIRCLED ON TOP
/*===================================================================================*/

.tabs-circle-top .etabs {
	position: relative;
    padding: 0;
    margin: 0;
	z-index: 2;
}
.tabs-circle-top .etabs:before {
	content: '';
	display: block;
    border-bottom: 1px solid #CED7E0;
	margin: 65px 0 -65px;
}
.tabs-circle-top .tab {
    display: inline-block;
	height: 160px;
	position: relative;
	top: 0;
	margin: 0 15px;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}
.tabs-circle-top .tab a {
    display: table-cell;
	vertical-align: middle;
	width: 130px;
	height: 130px;
	max-width: 130px;
	background: #FFF;
    color: $clr-base-dark;
	font-size: 14px;
	line-height: 130%;
	font-weight: 700;
    text-transform: uppercase;
	padding: 13px;
    border: 1px solid #CED7E0;
	border-radius: 50%;
	overflow: hidden;
}
.tabs-circle-top .tab a div {
	font-family: 'Rubik', sans-serif;
	font-size: 40px;
	line-height: 100%;
	font-weight: 200;
	margin: -10px 0 5px;
}
.tabs-circle-top .tab a:hover {
	/*border: 4px solid #1ABB9C;*/
	background-color: #F5F7FA;
	border-color: #CED7E0 !important;
}
.tabs-circle-top .tab.active {
	top: -15px;
}
.tabs-circle-top .tab.active a {
	height: 160px;
	width: 160px;
	max-width: 160px;
    background: #1ABB9C;
	font-size: 15px;
	line-height: 140%;
	color: #FFF;
	border: 1px solid #1ABB9C;
}
.tabs-circle-top .tab.active a:hover {
	border-color: transparent !important;
}
.tabs-circle-top .tab.active a div {
	font-size: 50px;
	margin: -13px 0 8px;
}
.tabs-circle-top.tab-container .panel-container {
	position: relative;
	margin-top: 30px;
	z-index: 1;
}
.tabs-circle-top.tab-container .panel-container p {
    margin: 0;
    padding-bottom: 20px;
}


/*===================================================================================*/
/*	TABS 2-BIG ON TOP
/*===================================================================================*/

.tabs-2-big-top .etabs {
	position: relative;
    padding: 0;
    margin: 0;
	z-index: 2;
}
.tabs-2-big-top .tab {
	position: relative;
    display: inline-block;
	width: 40%;
    border-bottom: 2px solid transparent;
	border-radius: 3px;
	margin: 0 15px;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}
.tabs-2-big-top .tab:before, .tabs-2-big-top .tab:after {
	content: '';
	position: absolute;
	top: 100%;
	#{$right}: 50%;
	height: 0;
	width: 0;
	border: solid transparent;
	pointer-events: none;
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}
.tabs-2-big-top .tab:before {
	border-width: 23px;
	margin-#{$right}: -23px;
}
.tabs-2-big-top .tab:after {
	border-width: 20px;
	margin-#{$right}: -20px;
}
.tabs-2-big-top .tab a {
    display: block;
	padding: 35px;
    color: $clr-base-dark;
	opacity: .5;
}
.tabs-2-big-top .tab:hover {
	background: #F7F9FA;
	border-color: #E6EBF0;
}
.tabs-2-big-top .tab:hover:before {
	border-top-color: #E6EBF0;
}
.tabs-2-big-top .tab:hover:after {
	border-top-color: #F7F9FA;
}
.tabs-2-big-top .tab.active {
    background: #FFF;
	border-color: #E1E7ED;
}
.tabs-2-big-top .tab.active:before {
	border-top-color: #E1E7ED;
}
.tabs-2-big-top .tab.active:after {
	border-top-color: #FFF;
}
.tabs-2-big-top .tab:hover a,
.tabs-2-big-top .tab.active a {
	opacity: 1;
}
.tabs-2-big-top .tab a p {
	margin-bottom: 0;
}
.tabs-2-big-top.tab-container .panel-container {
	position: relative;
	margin-top: 80px;
	z-index: 1;
}
.tabs-2-big-top.tab-container .panel-container p {
    margin: 0;
    padding-bottom: 20px;
}