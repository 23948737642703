/*
Template: 		REEN – Made for Designers
Description: 	Creative Portfolio Bootstrap 4 One/Multi Page Template
Version: 		2.0
Author: 		fuviz
Demo URI: 		http://demo.fuviz.com/reen
*/

/*===================================================================================*/
/*	GENERAL
/*===================================================================================*/
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
:focus {
    outline: 0 !important;
}
figure {
    margin: 0;
    padding: 0;
}
figure img {
    width: 100%;
    height: auto;
}
figure :not(.icon-overlay) a:hover,
figure:not(.icon-overlay) > a:hover {
	opacity: .7;
}
img[src*=".svg"] {
	height: 100%; 
}
iframe {
    border: none;
}
* > p:last-child {
	padding-bottom: 0 !important;
	margin-bottom: 0 !important;
}
::selection {
    background: #AAF2E4; /* Safari */
    color: $clr-base-dark;
}
::-moz-selection {
    background: #AAF2E4; /* Firefox */
    color: $clr-base-dark;
}
.animate {
	transition: all 0.1s ease-out; /* Optionally cubic-bezier(0.215, 0.610, 0.355, 1.000) which is easeOutCubic */
	-webkit-transform: translateZ(0); /* Enables hardware accelerated transitions for transform, opacity and filter properties on browsers with webkit engines — optionally will-change */
	-webkit-backface-visibility: hidden; /* Fixes transition flickering in Chrome and Safari */
}
body {
    font-family: 'Rubik', sans-serif;
    background-color: #080d0e;
    color: $clr-base-txt;
    font-size: 18px;
	line-height: 150%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
	/*-moz-osx-font-smoothing: grayscale;*/
	text-rendering: optimizeLegibility;
	overflow-x: hidden !important;
}
.navbar,
main {
	background-color: #FFF;
}
main:before {
	content: "";
	position: fixed;
	top: 0;
	#{$right}: 0;
	bottom: 0;
	#{$left}: 0;
	z-index: 1;
	background: #ffffff;
	transition: 0.4s ease-out 0.2s;
	transition-property: opacity, visibility;
}
main.js-reveal:before {
	opacity: 0;
	visibility: hidden;
}
.container {
    padding-#{$left}: 0;
    padding-#{$right}: 0;
}
p {
	margin-bottom: 20px;
}
p a {
	border-bottom: 1px solid $clr-base-txt;
}
p a:hover {
	border-color: transparent;
}
a {
	color: inherit;
	transition: all 0.2s ease-out;
	-webkit-transform: translateZ(0); /* Enables hardware accelerated transitions for transform, opacity and filter properties on browsers with webkit engines — optionally will-change */
	-webkit-backface-visibility: hidden; /* Fixes transition flickering in Chrome and Safari */
}
a:hover,
a:focus {
    color: #1ABB9C;
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
	letter-spacing: 0.025em;
    color: $clr-base-dark;
    margin-top: 0;
	/*word-wrap: break-word;*/
}
h1 {
    font-size: 44px;
    line-height: 130%;
    margin-bottom: 25px;
}
h2 {
    font-size: 26px;
    line-height: 130%;
    margin-bottom: 20px;
}
h3 {
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 15px;
}
h4 {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 15px;
}
h5 {
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 13px;
}
h6 {
    font-size: 12px;
    line-height: 160%;
    margin-bottom: 10px;
}
/*a .logo {
	transition: all 0.2s ease-out;
}*/
a:hover .logo {
	opacity: .6;
}
a.green:hover {
    background: #1ABB9C !important;
}
a.blue:hover {
    background: #3F8DBF !important;
}
a.red:hover {
    background: #FA6C65 !important;
}
a.orange:hover {
    background: $clr-base-orange !important;
}
a.purple:hover {
    background: #9B59B6 !important;
}
a.pink:hover {
    background: #D487BE !important;
}
a.navy:hover {
    background: #34495E !important;
}
a.gray:hover {
    background: #95A5A6 !important;
}
ol {
	position: relative;
	padding-#{$right}: 22px;
}
ul {
    list-style: none;
	position: relative;
	padding-#{$right}: 22px;
}
ul.arrowed li,
ul.circled li,
ol li {
	margin-bottom: 5px;
}
ul.arrowed li:before {
    font-family: 'fontello';
	font-size: 75%;
    font-weight: normal;
    color: #1ABB9C;
	vertical-align: baseline;
    content: '\e846';
	margin-top: 1px;
	margin-#{$right}: -20px;
	position: absolute;
}
ul.circled li:before {
    font-family: 'fontello-circle';
    content: '\e800';
    font-size: 8px;
    font-weight: normal;
    color: #1ABB9C;
    padding-top: 1px;
	margin-#{$right}: -20px;
    vertical-align: top;
	position: absolute;
}
ul.contacts {
	padding-#{$right}: 0;
}
ul.contacts li {
	margin-bottom: 5px;
}
footer ul.contacts li {
	margin-bottom: 2px;
}
.social-network {
	position: relative;
	z-index: 0;
	padding-top: 25px;
}
.social-network .social li {
	display: table-cell;
}
.social-network .social li a i {
	width: 55px;
	height: 80px;
	font-size: 20px;
}
#hero h1 {
	font-size: 78px;
	line-height: 110%;
	font-weight: 900;
	letter-spacing: .05em;
	text-transform: uppercase;
	margin-bottom: 0;
	-webkit-transition: font 200ms ease-out;
	   -moz-transition: font 200ms ease-out;
		 -o-transition: font 200ms ease-out;
			transition: font 200ms ease-out;
}
#hero #owl-main .caption h1.light-bg,
#hero #owl-main .caption h1.medium-bg,
#hero #owl-main .caption h1.dark-bg,
#hero #owl-main .caption h1.tint-bg {
	font-size: 73px;
}
#hero h4 {
	font-size: 33px;
	line-height: 120%;
}
#hero p {
	font-size: 24px;
	line-height: 150%;
	font-weight: 400;
	letter-spacing: .015em;
	text-transform: none;
	margin-top: 20px;
	margin-bottom: 0;
	-webkit-transition: font 200ms ease-out, margin 200ms ease-out;
	   -moz-transition: font 200ms ease-out, margin 200ms ease-out;
		 -o-transition: font 200ms ease-out, margin 200ms ease-out;
			transition: font 200ms ease-out, margin 200ms ease-out;
}
#hero .btn {
	margin-top: 60px;
	margin-bottom: 0;
	-webkit-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
	   -moz-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
		 -o-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
			transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
}
#hero .btn-large {
	font-size: 18px;
	padding: 18px 70px 20px;
}
header p {
	font-size: 22px;
	line-height: 150%;
}
p + h2,
p + h3 {
	padding-top: 15px;
}
.text-small {
	font-size: 16px;
	line-height: 160%;
}
.text-normal {
	font-size: inherit;
	line-height: inherit;
}
h2 + .text-small {
	margin-top: -5px;
}
header h2 + .text-small {
	margin-top: 0;
}
.post-title {
    margin: 0 0 15px 0;
}
h1.post-title {
    font-size: 22px;
    line-height: 24px;
}
.bordered .post-title {
    margin-bottom: 5px;
}
main header + * {
	margin-top: 35px !important;
}
.inner {
	padding-top: 120px;
	padding-bottom: 120px;
}
.inner-md {
	padding-top: 100px;
	padding-bottom: 100px;
}
.inner-sm {
	padding-top: 80px;
	padding-bottom: 80px;
}
.inner-xs {
	padding-top: 40px;
	padding-bottom: 40px;
}
.inner-top {
	padding-top: 120px;
}
.inner-top-md {
	padding-top: 100px;
}
.inner-top-sm {
	padding-top: 80px;
}
.inner-top-xs {
	padding-top: 40px;
}
.inner-bottom {
	padding-bottom: 120px;
}
.inner-bottom-md {
	padding-bottom: 100px;
}
.inner-bottom-sm {
	padding-bottom: 80px;
}
.inner-bottom-xs {
	padding-bottom: 40px;
}
.inner-left {
	padding-#{$right}: 75px;
}
.inner-left-md {
	padding-#{$right}: 60px;
}
.inner-left-sm {
	padding-#{$right}: 45px;
}
.inner-left-xs {
	padding-#{$right}: 30px;
}
.inner-right {
	padding-#{$left}: 75px;
}
.inner-right-md {
	padding-#{$left}: 60px;
}
.inner-right-sm {
	padding-#{$left}: 45px;
}
.inner-right-xs {
	padding-#{$left}: 30px;
}
.outer {
	margin-top: 120px;
	margin-bottom: 120px;
}
.outer-md {
	margin-top: 100px;
	margin-bottom: 100px;
}
.outer-sm {
	margin-top: 80px;
	margin-bottom: 80px;
}
.outer-xs {
	margin-top: 40px;
	margin-bottom: 40px;
}
.outer-top {
	margin-top: 120px;
}
.outer-top-md {
	margin-top: 100px;
}
.outer-top-sm {
	margin-top: 80px;
}
.outer-top-xs {
	margin-top: 40px;
}
.outer-bottom {
	margin-bottom: 120px;
}
.outer-bottom-md {
	margin-bottom: 100px;
}
.outer-bottom-sm {
	margin-bottom: 80px;
}
.outer-bottom-xs {
	margin-bottom: 40px;
}
.height-xs {
	height: 30vh;
}
.height-sm {
	height: 44vh;
}
.height-md {
	height: 61vh;
}
.height-lg {
	height: 90vh;
}
.dropcap {
	float: $right;
	display: inline-block;
	width: 44px;
	height: 44px;
	background: #F2F5F7;
	font-size: 28px;
	font-weight: 700;
	line-height: 42px;
	text-align: center;
	color: $clr-base-dark;
	margin: 6px 0 0 15px;
}
.dropcap-lg {
	float: $right;
	display: inline-block;
	width: 70px;
	height: 70px;
	background: #F2F5F7;
	font-size: 48px;
	font-weight: 700;
	line-height: 65px;
	text-align: center;
	color: $clr-base-dark;
	margin: 6px 0 0 15px;
}
blockquote {
    padding: 0;
    margin: 0;
    border: none;
}
blockquote p {
    font-size: 22px;
    line-height: 160%;
	font-style: italic;
}
blockquote footer,
blockquote small,
blockquote .small {
	color: $clr-base-dark;
	font-size: 14px;
	line-height: 150%;
	font-weight: 700;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before,
blockquote footer:after,
blockquote small:after,
blockquote .small:after {
	content: '';
	display: inline-block;
	width: 20px;
	height: 1px;
	background-color: $clr-base-dark;
	margin-bottom: .28em;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
	margin-#{$left}: 10px;
}
blockquote footer:after,
blockquote small:after,
blockquote .small:after {
	margin-#{$right}: 10px;
}
hr {
    border: 0;
    border-bottom: 1px solid #CED7E0;
    margin: 60px 0;
}
.map iframe {
    display: block;
    max-width: 100%;
}
h1 + figure,
p + figure {
	padding-top: 15px;
}
h2 + figure,
h3 + figure,
h2 + .thumbs,
h3 + .thumbs,
h2 + .thumb,
h3 + .thumb {
	padding-top: 10px;
}
h4 + figure {
	padding-top: 5px;
}
h2 + .owl-carousel,
p + .owl-carousel,
h2 + .panel-group,
p + .panel-group {
	padding-top: 20px;
}
figure figcaption {
	font-size: 14px;
	line-height: 150%;
}
figure figcaption h4 {
	margin-bottom: 10px;
}
figure * + figcaption {
	padding-top: 20px;
}
figure + *,
.thumb + figure {
	padding-top: 25px;
}
.classic-blog figure + * {
	padding-top: 35px;
}
figure.post-media + * {
	padding-top: 0;
}
.member .icon-overlay {
	display: inline-block;
}
.member .icon-overlay span {
	border-radius: 50%;
}
.member .rounded-circle {
	max-width: 200px;
	max-height: 200px;
}
.member .member-image {
	position: relative;
}
.member figcaption {
	position: relative;
	padding-top: 35px;
	z-index: 0;
}
.member figcaption.bordered {
	padding: 20px 15px 15px;
}
.member figcaption h2 span,
.member figcaption h3 span {
	display: block;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	letter-spacing: 0.015em;
}
.member figcaption h3 {
	font-size: 16px;
	line-height: 130%;
	margin-bottom: 10px;
}
.member figcaption h2 span {
	font-size: 18px;
	line-height: 140%;
	margin-top: 5px;
}
.member figcaption h3 span {
	font-size: 14px;
	line-height: 120%;
	margin-top: 4px;
}
.member figcaption blockquote {
	font-size: 16px;
	font-style: italic;
	line-height: 160%;
	margin-bottom: 20px;
}
.member figcaption blockquote:before {
	display: inline;
	position: relative;
	content: "\201C";
	font-family: inherit;
	font-size: inherit;
	margin-#{$left}: 2px;
}
.member figcaption blockquote:after {
	content: "\201D";
	margin-#{$right}: 2px;
}
.member .social {
	display: inline-block;
}
.member .social li {
	display: table-cell;
}
.member .social li a i {
	width: 33px;
	height: 45px;
	font-size: 15px;
}
.member .text-overlay .info .social li a i {
	color: #FFF;
}
.member .text-overlay .info .social li a:hover .icon-s-facebook {
    color: #3B5998;
    color: #4668B3;
}
.member .text-overlay .info .social li a:hover .icon-s-gplus {
    color: #DD4B39;
    color: #DE5745;
}
.member .text-overlay .info .social li a:hover .icon-s-twitter {
    color: #00ACED;
    color: #2FBBED;
}
.member .text-overlay .info .social li a i:after {
	background: #FFF;
}
.img-bg,
.img-bg-center {
	background-position: center !important;
	background-size: cover;
}
.img-bg-top {
	background-position: top !important;
	background-size: cover;
}
.img-bg-bottom {
	background-position: bottom !important;
	background-size: cover;
}
.img-bg-soft,
.img-bg-softer {
	background-position: center;
	position: relative;
}
.img-bg-soft:before,
.img-bg-softer:before {
	content: "";
	position: absolute;
	top: 0;
	#{$right}: 0;
	width: 100%;
	height: 100%;
}
.light-bg.img-bg-soft:before {
	background-color: #F2F5F7;
	opacity: .66;
}
.dark-bg.img-bg-soft:before {
	background-color: #071215;
	opacity: .57;
}
.tint-bg.img-bg-soft:before {
	background-color: #1ABB9C;
	opacity: .84;
}
.light-bg.img-bg-softer:before {
	background-color: #F2F5F7;
	opacity: .95;
}
.dark-bg.img-bg-softer:before {
	background-color: #071215;
	opacity: .97;
}
.tint-bg.img-bg-softer:before {
	background-color: #1ABB9C;
	opacity: .96;
}
.screen-container {
	position: relative;
	width: 100%;
	height: auto !important;
	background: url("../images/art/screen-container.png") no-repeat top / 100%;
	overflow: hidden;
}
.screen-container:before{
	content: "";
	display: block;
	padding-top: 47%;
}
.screen-container .tab-content {
	position: absolute;
	top: 0;
	#{$left}: 0;
	bottom: 0;
	#{$right}: 0;
	padding: 5% 4.5%;
	padding-bottom: 0;
}
.sidelines {
	position: relative;
	line-height: 100%;
	border-bottom: 1px solid #CED7E0;
	margin-top: -.35em;
	margin-bottom: 1.5em;
}
.sidelines span {
	position: relative;
	top: .5em;
	background: #FFF;
	padding: 0 0 0 .5em;
}
.sidelines.text-right span,
.text-right .sidelines span {
	padding: 0 .5em 0 0;
}
.sidelines.text-center span,
.text-center .sidelines span {
	padding: 0 .5em;
}
.team-headline.sidelines {
	margin-top: -20px;
	margin-bottom: 40px;
}
.light-bg .sidelines {
	border-color: #BCC7D1;
}
.light-bg .sidelines span {
	background: #F2F5F7;
}
.dark-bg .sidelines {
	border-color: $clr-base-txt;
}
.dark-bg .sidelines span {
	background: #071215;
}
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	#{$right}: 0;
	width: 100%;
	height: 100%;
}
iframe + iframe {
    margin-top: 10px;
}
.isotope, .owl-carousel .owl-wrapper-outer {
	z-index: 0;
}
.green-bg {
    background-color: #1ABB9C !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.blue-bg {
    background-color: #3F8DBF !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.red-bg {
    background-color: #FA6C65 !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.orange-bg {
    background-color: $clr-base-orange !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.purple-bg {
    background-color: #9B59B6 !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.pink-bg {
    background-color: #D487BE !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.navy-bg {
    background-color: #34495E !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.gray-bg {
    background-color: #95A5A6 !important;
	color: #FFF !important;
	padding: 2px 8px 4px;
	margin-top: -2px;
}
.light-bg {
    background-color: #F2F5F7;
}
.dark-bg {
    background-color: #071215;
}
.dark-bg,
.dark-bg p,
.dark-bg .item-details li:before {
    color: #A3B1BF;
}
.dark-bg h1,
.dark-bg h2,
.dark-bg h3,
.dark-bg h4 {
    color: #FFF;
}
.dark-bg .widget h1,
.dark-bg .widget h2,
.dark-bg .widget h3,
.dark-bg .widget h4 {
    color: #FFF;
}
.tint-bg {
    background-color: #1ABB9C;
	color: #FFF;
	padding: 2px 8px;
	margin-top: -2px;
}
.tint-bg,
.tint-bg h1,
.tint-bg h2,
.tint-bg h3,
.tint-bg h4,
.tint-bg p,
.tint-bg .item-details li:before,
.tint-bg .icon i.icn {
    color: #FFF;
}
.tint-bg a:hover {
    color: $clr-base-dark;
}
.light-color,
.light-color .dark-bg {
	color: #FFF !important;
}
.medium-color {
	color: #A3B1BF !important;
}
.dark-color {
	color: $clr-base-dark !important;
}
.tint-color {
	color: #1ABB9C !important;
}
.green {
    color: #1ABB9C !important;
}
.blue {
    color: #3F8DBF !important;
}
.red {
    color: #FA6C65 !important;
}
.pink {
    color: #D487BE !important;
}
.purple {
    color: #9B59B6 !important;
}
.orange {
    color: $clr-base-orange !important;
}
.navy {
    color: #34495E !important;
}
.gray {
    color: #95A5A6 !important;
}
.icon {
    display: inline-block;
	margin-bottom: 20px;
    -webkit-transition: all 200ms ease-out;
       -moz-transition: all 200ms ease-out;
         -o-transition: all 200ms ease-out;
            transition: all 200ms ease-out;
}
.icon.circle {
	padding: 30px;
	border: 1px solid #1ABB9C;
	border-radius: 50%;
}
.icon.circle.xs {
	padding: 15px;
}
.icon.circle.sm {
	padding: 20px;
}
.icon.circle.lg {
	padding: 35px;
}
.icon i.icn {
    display: block;
    color: #1ABB9C;
    font-size: 65px;
    line-height: 100%;
    -webkit-transition: all 200ms ease-out;
       -moz-transition: all 200ms ease-out;
         -o-transition: all 200ms ease-out;
            transition: all 200ms ease-out;
}
.icon i.icn.xs {
	font-size: 20px;
}
.icon i.icn.sm {
	font-size: 35px;
}
.icon i.icn.lg {
	font-size: 85px;
}
i.contact {
    margin-#{$left}: 5px;
    width: auto;
    height: auto;
    line-height: 18px;
}
.tagcloud {
    line-height: 25px;
}
.tagcloud > a {
    display: inline-block;
    margin-#{$left}: 2px;
}
footer {
	font-size: 14px;
	line-height: 22px;
}
footer.dark-bg {
    color: #A3B1BF;
}
footer .inner {
	padding-top: 45px;
	padding-bottom: 35px;
}
footer .container > .row {
	margin-#{$right}: -25px;
	margin-#{$left}: -25px;
}
footer .row .inner {
	padding: 25px;
}
footer .logo {
	height: auto;
	max-height: 100%;
}
footer h4 {
	text-transform: uppercase;
	margin-bottom: 20px;
}
footer p {
	margin-bottom: 15px;
}
.footer-menu {
    padding: 0;
    margin: 0;
    list-style: none;
}
.footer-menu li {
    display: inline;
    padding-#{$right}: 10px;
}
.footer-menu li:before {
    display: inline-block;
    content: "·";
    padding-#{$left}: 12px;
}
.footer-menu li:first-child:before {
    display: none;
}
.footer-bottom {
    background: #080d0e;
	color: #707E8C;
}
.footer-bottom .container.inner {
    padding-top: 30px;
	padding-bottom: 30px;
}
.footer-bottom p,
.footer-bottom a {
	color: #707E8C;
}
.footer-bottom p {
    padding: 0;
    margin: 0;
}
.meta,
.more {
    font-size: 14px;
    font-weight: 400;
	line-height: 160%;
	cursor: default;
}
.meta span:before {
    display: inline-block;
    content: "|";
    padding: 0 7px 0 10px;
}
.meta span:first-child:before {
    display: none;
    padding: 0;
}
.meta a {
    display: inline !important;
}
.meta a:hover,
.more:hover {
    color: #1ABB9C;
}
.meta.tags a:before {
    content: "#";
    display: inline-block;
}
.bordered .info {
    text-align: center;
}
.bordered .info h3,
.bordered .info h4 {
	margin-bottom: 5px;
}
.bordered .info h3 a,
.bordered .info h4 a {
    color: $clr-base-dark;
}
.bordered .info h3 a:hover,
.bordered .info h4 a:hover {
	color: #1ABB9C;
}
.bordered .info p {
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 0;
}
.bordered {
    border: 1px solid #E6E9ED;
	border-bottom-width: 2px;
	border-radius: 3px;
    padding: 25px;
    background: #F5F7FA;
}
.light-bg .bordered {
	background: #FFF;
}
.dark-bg .bordered .info h3,
.dark-bg .bordered .info h4,
.tint-bg .bordered .info h3,
.tint-bg .bordered .info h4 {
	color: $clr-base-dark;
}
.dark-bg .bordered .info p,
.tint-bg .bordered .info p {
	color: $clr-base-txt;
}
.no-top-border {
    border-top: none;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.border,
.border-top,
.border-right,
.border-bottom,
.border-left {
	border-color: #CED7E0 !important;
}
.border {
	border: 1px solid #CED7E0;
}
.border-top-lg,
.border-top-md,
.border-top-sm,
.border-top-xs,
.border-top {
	border-top: 1px solid #CED7E0;
}
.border-right-lg,
.border-right-md,
.border-right-sm,
.border-right-xs,
.border-right {
	border-#{$left}: 1px solid #CED7E0;
}
.border-bottom-lg,
.border-bottom-md,
.border-bottom-sm,
.border-bottom-xs,
.border-bottom {
	border-bottom: 1px solid #CED7E0;
}
.border-left-lg,
.border-left-md,
.border-left-sm,
.border-left-xs,
.border-left {
	border-#{$right}: 1px solid #CED7E0;
}
.border-lg,
.border-top-lg,
.border-right-lg,
.border-bottom-lg,
.border-left-lg {
	border-width: 20px;
}
.border-md,
.border-top-md,
.border-right-md,
.border-bottom-md,
.border-left-md {
	border-width: 10px;
}
.border-sm,
.border-top-sm,
.border-right-sm,
.border-bottom-sm,
.border-left-sm {
	border-width: 5px;
}
.border-xs,
.border-top-xs,
.border-right-xs,
.border-bottom-xs,
.border-left-xs {
	border-width: 3px;
}
.light-bg .border,
.light-bg .border-lg,
.light-bg .border-md,
.light-bg .border-sm,
.light-bg .border-xs,
.light-bg .border-top-lg,
.light-bg .border-top-md,
.light-bg .border-top-sm,
.light-bg .border-top-xs,
.light-bg .border-top,
.light-bg .border-right-lg,
.light-bg .border-right-md,
.light-bg .border-right-sm,
.light-bg .border-right-xs,
.light-bg .border-right,
.light-bg .border-bottom-lg,
.light-bg .border-bottom-md,
.light-bg .border-bottom-sm,
.light-bg .border-bottom-xs,
.light-bg .border-bottom,
.light-bg .border-left-lg,
.light-bg .border-left-md,
.light-bg .border-left-sm,
.light-bg .border-left-xs,
.light-bg .border-left {
	border-color: #BCC7D1 !important;
}
.dark-bg .border,
.dark-bg .border-lg,
.dark-bg .border-md,
.dark-bg .border-sm,
.dark-bg .border-xs,
.dark-bg .border-top-lg,
.dark-bg .border-top-md,
.dark-bg .border-top-sm,
.dark-bg .border-top-xs,
.dark-bg .border-top,
.dark-bg .border-right-lg,
.dark-bg .border-right-md,
.dark-bg .border-right-sm,
.dark-bg .border-right-xs,
.dark-bg .border-right,
.dark-bg .border-bottom-lg,
.dark-bg .border-bottom-md,
.dark-bg .border-bottom-sm,
.dark-bg .border-bottom-xs,
.dark-bg .border-bottom,
.dark-bg .border-left-lg,
.dark-bg .border-left-md,
.dark-bg .border-left-sm,
.dark-bg .border-left-xs,
.dark-bg .border-left {
	border-color: $clr-base-txt !important;
}
.tint-bg .border,
.tint-bg .border-lg,
.tint-bg .border-md,
.tint-bg .border-sm,
.tint-bg .border-xs,
.tint-bg .border-top-lg,
.tint-bg .border-top-md,
.tint-bg .border-top-sm,
.tint-bg .border-top-xs,
.tint-bg .border-top,
.tint-bg .border-right-lg,
.tint-bg .border-right-md,
.tint-bg .border-right-sm,
.tint-bg .border-right-xs,
.tint-bg .border-right,
.tint-bg .border-bottom-lg,
.tint-bg .border-bottom-md,
.tint-bg .border-bottom-sm,
.tint-bg .border-bottom-xs,
.tint-bg .border-bottom,
.tint-bg .border-left-lg,
.tint-bg .border-left-md,
.tint-bg .border-left-sm,
.tint-bg .border-left-xs,
.tint-bg .border-left {
	border-color: #FFF !important;
}
.last-bottom {
	padding-bottom: 0 !important;
	margin-bottom: 0 !important;
}
.last-right {
	padding-#{$left}: 0 !important;
	margin-#{$left}: 0 !important;
}
.reset-margin {
	margin: 0;
}
.reset-margin-left {
	margin-#{$right}: 0;
}
.reset-margin-right {
	margin-#{$left}: 0;
}
.reset-padding {
	padding: 0;
}
.reset-padding-left {
	padding-#{$right}: 0;
}
.reset-padding-right {
	padding-#{$left}: 0;
}
.single-block {
	padding: 0;
	border: 0;
	margin: 0;
}
h1 + .single-line,
h2 + .single-line,
h3 + .single-line,
h4 + .single-line {
	margin-top: -.5em;
}
.img-intext {
    margin-bottom: 20px;
}
aside {
	font-size: 16px;
	line-height: 160%;
}
aside p {
	margin-bottom: 15px;
}
aside .txt-btn {
	font-size: 100%;
}
aside .thumb figure {
	margin: 0;
}
.thumbs.no-gap {
	margin-#{$right}: 0;
	margin-#{$left}: 0;
}
.thumbs.no-gap .thumb {
	padding: 0;
}
.thumbs.gap-xs {
	margin-#{$right}: -5px;
	margin-#{$left}: 0;
}
.thumbs.gap-xs .thumb {
	padding: 0 5px 5px 0;
}
.thumbs.gap-sm {
	margin-#{$right}: -10px;
	margin-#{$left}: 0;
}
.thumbs.gap-sm .thumb {
	padding: 0 10px 10px 0;
}
.thumbs.gap-md {
	margin-#{$right}: -20px;
	margin-#{$left}: 0;
}
.thumbs.gap-md .thumb {
	padding: 0 20px 20px 0;
}
.thumbs.gap-lg {
	margin-#{$right}: -30px;
	margin-#{$left}: 0;
}
.thumbs.gap-lg .thumb {
	padding: 0 30px 30px 0;
}
.dropdown-toggle::after {
	display: none;
}
.open > .dropdown-menu {
	display: block;
}
.dropdown-menu {
	font-size: 0.875rem;
	color: inherit;
}
.float-right > .dropdown-menu {
	#{$left}: 0; 
	#{$right}: auto;
}
.dropdown-menu > li > a {
	display: block;
	clear: both;
}