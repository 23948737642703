@charset "UTF-8";
.float-right {
  float: right !important; }

.float-left {
  float: left !important; }

/*
Template: 		REEN – Made for Designers
Description: 	Creative Portfolio Bootstrap 4 One/Multi Page Template
Version: 		2.0
Author: 		fuviz
Demo URI: 		http://demo.fuviz.com/reen
*/
/*===================================================================================*/
/*	GENERAL
/*===================================================================================*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

:focus {
  outline: 0 !important; }

figure {
  margin: 0;
  padding: 0; }

figure img {
  width: 100%;
  height: auto; }

figure :not(.icon-overlay) a:hover,
figure:not(.icon-overlay) > a:hover {
  opacity: .7; }

img[src*=".svg"] {
  height: 100%; }

iframe {
  border: none; }

* > p:last-child {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important; }

::selection {
  background: #AAF2E4;
  /* Safari */
  color: #0c1e23; }

::-moz-selection {
  background: #AAF2E4;
  /* Firefox */
  color: #0c1e23; }

.animate {
  transition: all 0.1s ease-out;
  /* Optionally cubic-bezier(0.215, 0.610, 0.355, 1.000) which is easeOutCubic */
  -webkit-transform: translateZ(0);
  /* Enables hardware accelerated transitions for transform, opacity and filter properties on browsers with webkit engines — optionally will-change */
  -webkit-backface-visibility: hidden;
  /* Fixes transition flickering in Chrome and Safari */ }

body {
  font-family: 'Rubik', sans-serif;
  background-color: #080d0e;
  color: #1e4654;
  font-size: 18px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  /*-moz-osx-font-smoothing: grayscale;*/
  text-rendering: optimizeLegibility;
  overflow-x: hidden !important; }

.navbar,
main {
  background-color: #FFF; }

main:before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #ffffff;
  transition: 0.4s ease-out 0.2s;
  transition-property: opacity, visibility; }

main.js-reveal:before {
  opacity: 0;
  visibility: hidden; }

.container {
  padding-left: 0;
  padding-right: 0; }

p {
  margin-bottom: 20px; }

p a {
  border-bottom: 1px solid #1e4654; }

p a:hover {
  border-color: transparent; }

a {
  color: inherit;
  transition: all 0.2s ease-out;
  -webkit-transform: translateZ(0);
  /* Enables hardware accelerated transitions for transform, opacity and filter properties on browsers with webkit engines — optionally will-change */
  -webkit-backface-visibility: hidden;
  /* Fixes transition flickering in Chrome and Safari */ }

a:hover,
a:focus {
  color: #1ABB9C;
  text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  letter-spacing: 0.025em;
  color: #0c1e23;
  margin-top: 0;
  /*word-wrap: break-word;*/ }

h1 {
  font-size: 44px;
  line-height: 130%;
  margin-bottom: 25px; }

h2 {
  font-size: 26px;
  line-height: 130%;
  margin-bottom: 20px; }

h3 {
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 15px; }

h4 {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 15px; }

h5 {
  font-size: 14px;
  line-height: 160%;
  margin-bottom: 13px; }

h6 {
  font-size: 12px;
  line-height: 160%;
  margin-bottom: 10px; }

/*a .logo {
	transition: all 0.2s ease-out;
}*/
a:hover .logo {
  opacity: .6; }

a.green:hover {
  background: #1ABB9C !important; }

a.blue:hover {
  background: #3F8DBF !important; }

a.red:hover {
  background: #FA6C65 !important; }

a.orange:hover {
  background: #F27A24 !important; }

a.purple:hover {
  background: #9B59B6 !important; }

a.pink:hover {
  background: #D487BE !important; }

a.navy:hover {
  background: #34495E !important; }

a.gray:hover {
  background: #95A5A6 !important; }

ol {
  position: relative;
  padding-right: 22px; }

ul {
  list-style: none;
  position: relative;
  padding-right: 22px; }

ul.arrowed li,
ul.circled li,
ol li {
  margin-bottom: 5px; }

ul.arrowed li:before {
  font-family: 'fontello';
  font-size: 75%;
  font-weight: normal;
  color: #1ABB9C;
  vertical-align: baseline;
  content: '\e846';
  margin-top: 1px;
  margin-right: -20px;
  position: absolute; }

ul.circled li:before {
  font-family: 'fontello-circle';
  content: '\e800';
  font-size: 8px;
  font-weight: normal;
  color: #1ABB9C;
  padding-top: 1px;
  margin-right: -20px;
  vertical-align: top;
  position: absolute; }

ul.contacts {
  padding-right: 0; }

ul.contacts li {
  margin-bottom: 5px; }

footer ul.contacts li {
  margin-bottom: 2px; }

.social-network {
  position: relative;
  z-index: 0;
  padding-top: 25px; }

.social-network .social li {
  display: table-cell; }

.social-network .social li a i {
  width: 55px;
  height: 80px;
  font-size: 20px; }

#hero h1 {
  font-size: 78px;
  line-height: 110%;
  font-weight: 900;
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-transition: font 200ms ease-out;
  -moz-transition: font 200ms ease-out;
  -o-transition: font 200ms ease-out;
  transition: font 200ms ease-out; }

#hero #owl-main .caption h1.light-bg,
#hero #owl-main .caption h1.medium-bg,
#hero #owl-main .caption h1.dark-bg,
#hero #owl-main .caption h1.tint-bg {
  font-size: 73px; }

#hero h4 {
  font-size: 33px;
  line-height: 120%; }

#hero p {
  font-size: 24px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: .015em;
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 0;
  -webkit-transition: font 200ms ease-out, margin 200ms ease-out;
  -moz-transition: font 200ms ease-out, margin 200ms ease-out;
  -o-transition: font 200ms ease-out, margin 200ms ease-out;
  transition: font 200ms ease-out, margin 200ms ease-out; }

#hero .btn {
  margin-top: 60px;
  margin-bottom: 0;
  -webkit-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
  -moz-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
  -o-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
  transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out; }

#hero .btn-large {
  font-size: 18px;
  padding: 18px 70px 20px; }

header p {
  font-size: 22px;
  line-height: 150%; }

p + h2,
p + h3 {
  padding-top: 15px; }

.text-small {
  font-size: 16px;
  line-height: 160%; }

.text-normal {
  font-size: inherit;
  line-height: inherit; }

h2 + .text-small {
  margin-top: -5px; }

header h2 + .text-small {
  margin-top: 0; }

.post-title {
  margin: 0 0 15px 0; }

h1.post-title {
  font-size: 22px;
  line-height: 24px; }

.bordered .post-title {
  margin-bottom: 5px; }

main header + * {
  margin-top: 35px !important; }

.inner {
  padding-top: 120px;
  padding-bottom: 120px; }

.inner-md {
  padding-top: 100px;
  padding-bottom: 100px; }

.inner-sm {
  padding-top: 80px;
  padding-bottom: 80px; }

.inner-xs {
  padding-top: 40px;
  padding-bottom: 40px; }

.inner-top {
  padding-top: 120px; }

.inner-top-md {
  padding-top: 100px; }

.inner-top-sm {
  padding-top: 80px; }

.inner-top-xs {
  padding-top: 40px; }

.inner-bottom {
  padding-bottom: 120px; }

.inner-bottom-md {
  padding-bottom: 100px; }

.inner-bottom-sm {
  padding-bottom: 80px; }

.inner-bottom-xs {
  padding-bottom: 40px; }

.inner-left {
  padding-right: 75px; }

.inner-left-md {
  padding-right: 60px; }

.inner-left-sm {
  padding-right: 45px; }

.inner-left-xs {
  padding-right: 30px; }

.inner-right {
  padding-left: 75px; }

.inner-right-md {
  padding-left: 60px; }

.inner-right-sm {
  padding-left: 45px; }

.inner-right-xs {
  padding-left: 30px; }

.outer {
  margin-top: 120px;
  margin-bottom: 120px; }

.outer-md {
  margin-top: 100px;
  margin-bottom: 100px; }

.outer-sm {
  margin-top: 80px;
  margin-bottom: 80px; }

.outer-xs {
  margin-top: 40px;
  margin-bottom: 40px; }

.outer-top {
  margin-top: 120px; }

.outer-top-md {
  margin-top: 100px; }

.outer-top-sm {
  margin-top: 80px; }

.outer-top-xs {
  margin-top: 40px; }

.outer-bottom {
  margin-bottom: 120px; }

.outer-bottom-md {
  margin-bottom: 100px; }

.outer-bottom-sm {
  margin-bottom: 80px; }

.outer-bottom-xs {
  margin-bottom: 40px; }

.height-xs {
  height: 30vh; }

.height-sm {
  height: 44vh; }

.height-md {
  height: 61vh; }

.height-lg {
  height: 90vh; }

.dropcap {
  float: right;
  display: inline-block;
  width: 44px;
  height: 44px;
  background: #F2F5F7;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  color: #0c1e23;
  margin: 6px 0 0 15px; }

.dropcap-lg {
  float: right;
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #F2F5F7;
  font-size: 48px;
  font-weight: 700;
  line-height: 65px;
  text-align: center;
  color: #0c1e23;
  margin: 6px 0 0 15px; }

blockquote {
  padding: 0;
  margin: 0;
  border: none; }

blockquote p {
  font-size: 22px;
  line-height: 160%;
  font-style: italic; }

blockquote footer,
blockquote small,
blockquote .small {
  color: #0c1e23;
  font-size: 14px;
  line-height: 150%;
  font-weight: 700; }

blockquote footer:before,
blockquote small:before,
blockquote .small:before,
blockquote footer:after,
blockquote small:after,
blockquote .small:after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 1px;
  background-color: #0c1e23;
  margin-bottom: .28em; }

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  margin-left: 10px; }

blockquote footer:after,
blockquote small:after,
blockquote .small:after {
  margin-right: 10px; }

hr {
  border: 0;
  border-bottom: 1px solid #CED7E0;
  margin: 60px 0; }

.map iframe {
  display: block;
  max-width: 100%; }

h1 + figure,
p + figure {
  padding-top: 15px; }

h2 + figure,
h3 + figure,
h2 + .thumbs,
h3 + .thumbs,
h2 + .thumb,
h3 + .thumb {
  padding-top: 10px; }

h4 + figure {
  padding-top: 5px; }

h2 + .owl-carousel,
p + .owl-carousel,
h2 + .panel-group,
p + .panel-group {
  padding-top: 20px; }

figure figcaption {
  font-size: 14px;
  line-height: 150%; }

figure figcaption h4 {
  margin-bottom: 10px; }

figure * + figcaption {
  padding-top: 20px; }

figure + *,
.thumb + figure {
  padding-top: 25px; }

.classic-blog figure + * {
  padding-top: 35px; }

figure.post-media + * {
  padding-top: 0; }

.member .icon-overlay {
  display: inline-block; }

.member .icon-overlay span {
  border-radius: 50%; }

.member .rounded-circle {
  max-width: 200px;
  max-height: 200px; }

.member .member-image {
  position: relative; }

.member figcaption {
  position: relative;
  padding-top: 35px;
  z-index: 0; }

.member figcaption.bordered {
  padding: 20px 15px 15px; }

.member figcaption h2 span,
.member figcaption h3 span {
  display: block;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  letter-spacing: 0.015em; }

.member figcaption h3 {
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 10px; }

.member figcaption h2 span {
  font-size: 18px;
  line-height: 140%;
  margin-top: 5px; }

.member figcaption h3 span {
  font-size: 14px;
  line-height: 120%;
  margin-top: 4px; }

.member figcaption blockquote {
  font-size: 16px;
  font-style: italic;
  line-height: 160%;
  margin-bottom: 20px; }

.member figcaption blockquote:before {
  display: inline;
  position: relative;
  content: "\201C";
  font-family: inherit;
  font-size: inherit;
  margin-left: 2px; }

.member figcaption blockquote:after {
  content: "\201D";
  margin-right: 2px; }

.member .social {
  display: inline-block; }

.member .social li {
  display: table-cell; }

.member .social li a i {
  width: 33px;
  height: 45px;
  font-size: 15px; }

.member .text-overlay .info .social li a i {
  color: #FFF; }

.member .text-overlay .info .social li a:hover .icon-s-facebook {
  color: #3B5998;
  color: #4668B3; }

.member .text-overlay .info .social li a:hover .icon-s-gplus {
  color: #DD4B39;
  color: #DE5745; }

.member .text-overlay .info .social li a:hover .icon-s-twitter {
  color: #00ACED;
  color: #2FBBED; }

.member .text-overlay .info .social li a i:after {
  background: #FFF; }

.img-bg,
.img-bg-center {
  background-position: center !important;
  background-size: cover; }

.img-bg-top {
  background-position: top !important;
  background-size: cover; }

.img-bg-bottom {
  background-position: bottom !important;
  background-size: cover; }

.img-bg-soft,
.img-bg-softer {
  background-position: center;
  position: relative; }

.img-bg-soft:before,
.img-bg-softer:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.light-bg.img-bg-soft:before {
  background-color: #F2F5F7;
  opacity: .66; }

.dark-bg.img-bg-soft:before {
  background-color: #071215;
  opacity: .57; }

.tint-bg.img-bg-soft:before {
  background-color: #1ABB9C;
  opacity: .84; }

.light-bg.img-bg-softer:before {
  background-color: #F2F5F7;
  opacity: .95; }

.dark-bg.img-bg-softer:before {
  background-color: #071215;
  opacity: .97; }

.tint-bg.img-bg-softer:before {
  background-color: #1ABB9C;
  opacity: .96; }

.screen-container {
  position: relative;
  width: 100%;
  height: auto !important;
  background: url("../images/art/screen-container.png") no-repeat top/100%;
  overflow: hidden; }

.screen-container:before {
  content: "";
  display: block;
  padding-top: 47%; }

.screen-container .tab-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 5% 4.5%;
  padding-bottom: 0; }

.sidelines {
  position: relative;
  line-height: 100%;
  border-bottom: 1px solid #CED7E0;
  margin-top: -.35em;
  margin-bottom: 1.5em; }

.sidelines span {
  position: relative;
  top: .5em;
  background: #FFF;
  padding: 0 0 0 .5em; }

.sidelines.text-right span,
.text-right .sidelines span {
  padding: 0 .5em 0 0; }

.sidelines.text-center span,
.text-center .sidelines span {
  padding: 0 .5em; }

.team-headline.sidelines {
  margin-top: -20px;
  margin-bottom: 40px; }

.light-bg .sidelines {
  border-color: #BCC7D1; }

.light-bg .sidelines span {
  background: #F2F5F7; }

.dark-bg .sidelines {
  border-color: #1e4654; }

.dark-bg .sidelines span {
  background: #071215; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%; }

iframe + iframe {
  margin-top: 10px; }

.isotope, .owl-carousel .owl-wrapper-outer {
  z-index: 0; }

.green-bg {
  background-color: #1ABB9C !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.blue-bg {
  background-color: #3F8DBF !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.red-bg {
  background-color: #FA6C65 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.orange-bg {
  background-color: #F27A24 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.purple-bg {
  background-color: #9B59B6 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.pink-bg {
  background-color: #D487BE !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.navy-bg {
  background-color: #34495E !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.gray-bg {
  background-color: #95A5A6 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px; }

.light-bg {
  background-color: #F2F5F7; }

.dark-bg {
  background-color: #071215; }

.dark-bg,
.dark-bg p,
.dark-bg .item-details li:before {
  color: #A3B1BF; }

.dark-bg h1,
.dark-bg h2,
.dark-bg h3,
.dark-bg h4 {
  color: #FFF; }

.dark-bg .widget h1,
.dark-bg .widget h2,
.dark-bg .widget h3,
.dark-bg .widget h4 {
  color: #FFF; }

.tint-bg {
  background-color: #1ABB9C;
  color: #FFF;
  padding: 2px 8px;
  margin-top: -2px; }

.tint-bg,
.tint-bg h1,
.tint-bg h2,
.tint-bg h3,
.tint-bg h4,
.tint-bg p,
.tint-bg .item-details li:before,
.tint-bg .icon i.icn {
  color: #FFF; }

.tint-bg a:hover {
  color: #0c1e23; }

.light-color,
.light-color .dark-bg {
  color: #FFF !important; }

.medium-color {
  color: #A3B1BF !important; }

.dark-color {
  color: #0c1e23 !important; }

.tint-color {
  color: #1ABB9C !important; }

.green {
  color: #1ABB9C !important; }

.blue {
  color: #3F8DBF !important; }

.red {
  color: #FA6C65 !important; }

.pink {
  color: #D487BE !important; }

.purple {
  color: #9B59B6 !important; }

.orange {
  color: #F27A24 !important; }

.navy {
  color: #34495E !important; }

.gray {
  color: #95A5A6 !important; }

.icon {
  display: inline-block;
  margin-bottom: 20px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.icon.circle {
  padding: 30px;
  border: 1px solid #1ABB9C;
  border-radius: 50%; }

.icon.circle.xs {
  padding: 15px; }

.icon.circle.sm {
  padding: 20px; }

.icon.circle.lg {
  padding: 35px; }

.icon i.icn {
  display: block;
  color: #1ABB9C;
  font-size: 65px;
  line-height: 100%;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.icon i.icn.xs {
  font-size: 20px; }

.icon i.icn.sm {
  font-size: 35px; }

.icon i.icn.lg {
  font-size: 85px; }

i.contact {
  margin-left: 5px;
  width: auto;
  height: auto;
  line-height: 18px; }

.tagcloud {
  line-height: 25px; }

.tagcloud > a {
  display: inline-block;
  margin-left: 2px; }

footer {
  font-size: 14px;
  line-height: 22px; }

footer.dark-bg {
  color: #A3B1BF; }

footer .inner {
  padding-top: 45px;
  padding-bottom: 35px; }

footer .container > .row {
  margin-right: -25px;
  margin-left: -25px; }

footer .row .inner {
  padding: 25px; }

footer .logo {
  height: auto;
  max-height: 100%; }

footer h4 {
  text-transform: uppercase;
  margin-bottom: 20px; }

footer p {
  margin-bottom: 15px; }

.footer-menu {
  padding: 0;
  margin: 0;
  list-style: none; }

.footer-menu li {
  display: inline;
  padding-right: 10px; }

.footer-menu li:before {
  display: inline-block;
  content: "·";
  padding-left: 12px; }

.footer-menu li:first-child:before {
  display: none; }

.footer-bottom {
  background: #080d0e;
  color: #707E8C; }

.footer-bottom .container.inner {
  padding-top: 30px;
  padding-bottom: 30px; }

.footer-bottom p,
.footer-bottom a {
  color: #707E8C; }

.footer-bottom p {
  padding: 0;
  margin: 0; }

.meta,
.more {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  cursor: default; }

.meta span:before {
  display: inline-block;
  content: "|";
  padding: 0 7px 0 10px; }

.meta span:first-child:before {
  display: none;
  padding: 0; }

.meta a {
  display: inline !important; }

.meta a:hover,
.more:hover {
  color: #1ABB9C; }

.meta.tags a:before {
  content: "#";
  display: inline-block; }

.bordered .info {
  text-align: center; }

.bordered .info h3,
.bordered .info h4 {
  margin-bottom: 5px; }

.bordered .info h3 a,
.bordered .info h4 a {
  color: #0c1e23; }

.bordered .info h3 a:hover,
.bordered .info h4 a:hover {
  color: #1ABB9C; }

.bordered .info p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0; }

.bordered {
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-radius: 3px;
  padding: 25px;
  background: #F5F7FA; }

.light-bg .bordered {
  background: #FFF; }

.dark-bg .bordered .info h3,
.dark-bg .bordered .info h4,
.tint-bg .bordered .info h3,
.tint-bg .bordered .info h4 {
  color: #0c1e23; }

.dark-bg .bordered .info p,
.tint-bg .bordered .info p {
  color: #1e4654; }

.no-top-border {
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.border,
.border-top,
.border-right,
.border-bottom,
.border-left {
  border-color: #CED7E0 !important; }

.border {
  border: 1px solid #CED7E0; }

.border-top-lg,
.border-top-md,
.border-top-sm,
.border-top-xs,
.border-top {
  border-top: 1px solid #CED7E0; }

.border-right-lg,
.border-right-md,
.border-right-sm,
.border-right-xs,
.border-right {
  border-left: 1px solid #CED7E0; }

.border-bottom-lg,
.border-bottom-md,
.border-bottom-sm,
.border-bottom-xs,
.border-bottom {
  border-bottom: 1px solid #CED7E0; }

.border-left-lg,
.border-left-md,
.border-left-sm,
.border-left-xs,
.border-left {
  border-right: 1px solid #CED7E0; }

.border-lg,
.border-top-lg,
.border-right-lg,
.border-bottom-lg,
.border-left-lg {
  border-width: 20px; }

.border-md,
.border-top-md,
.border-right-md,
.border-bottom-md,
.border-left-md {
  border-width: 10px; }

.border-sm,
.border-top-sm,
.border-right-sm,
.border-bottom-sm,
.border-left-sm {
  border-width: 5px; }

.border-xs,
.border-top-xs,
.border-right-xs,
.border-bottom-xs,
.border-left-xs {
  border-width: 3px; }

.light-bg .border,
.light-bg .border-lg,
.light-bg .border-md,
.light-bg .border-sm,
.light-bg .border-xs,
.light-bg .border-top-lg,
.light-bg .border-top-md,
.light-bg .border-top-sm,
.light-bg .border-top-xs,
.light-bg .border-top,
.light-bg .border-right-lg,
.light-bg .border-right-md,
.light-bg .border-right-sm,
.light-bg .border-right-xs,
.light-bg .border-right,
.light-bg .border-bottom-lg,
.light-bg .border-bottom-md,
.light-bg .border-bottom-sm,
.light-bg .border-bottom-xs,
.light-bg .border-bottom,
.light-bg .border-left-lg,
.light-bg .border-left-md,
.light-bg .border-left-sm,
.light-bg .border-left-xs,
.light-bg .border-left {
  border-color: #BCC7D1 !important; }

.dark-bg .border,
.dark-bg .border-lg,
.dark-bg .border-md,
.dark-bg .border-sm,
.dark-bg .border-xs,
.dark-bg .border-top-lg,
.dark-bg .border-top-md,
.dark-bg .border-top-sm,
.dark-bg .border-top-xs,
.dark-bg .border-top,
.dark-bg .border-right-lg,
.dark-bg .border-right-md,
.dark-bg .border-right-sm,
.dark-bg .border-right-xs,
.dark-bg .border-right,
.dark-bg .border-bottom-lg,
.dark-bg .border-bottom-md,
.dark-bg .border-bottom-sm,
.dark-bg .border-bottom-xs,
.dark-bg .border-bottom,
.dark-bg .border-left-lg,
.dark-bg .border-left-md,
.dark-bg .border-left-sm,
.dark-bg .border-left-xs,
.dark-bg .border-left {
  border-color: #1e4654 !important; }

.tint-bg .border,
.tint-bg .border-lg,
.tint-bg .border-md,
.tint-bg .border-sm,
.tint-bg .border-xs,
.tint-bg .border-top-lg,
.tint-bg .border-top-md,
.tint-bg .border-top-sm,
.tint-bg .border-top-xs,
.tint-bg .border-top,
.tint-bg .border-right-lg,
.tint-bg .border-right-md,
.tint-bg .border-right-sm,
.tint-bg .border-right-xs,
.tint-bg .border-right,
.tint-bg .border-bottom-lg,
.tint-bg .border-bottom-md,
.tint-bg .border-bottom-sm,
.tint-bg .border-bottom-xs,
.tint-bg .border-bottom,
.tint-bg .border-left-lg,
.tint-bg .border-left-md,
.tint-bg .border-left-sm,
.tint-bg .border-left-xs,
.tint-bg .border-left {
  border-color: #FFF !important; }

.last-bottom {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important; }

.last-right {
  padding-left: 0 !important;
  margin-left: 0 !important; }

.reset-margin {
  margin: 0; }

.reset-margin-left {
  margin-right: 0; }

.reset-margin-right {
  margin-left: 0; }

.reset-padding {
  padding: 0; }

.reset-padding-left {
  padding-right: 0; }

.reset-padding-right {
  padding-left: 0; }

.single-block {
  padding: 0;
  border: 0;
  margin: 0; }

h1 + .single-line,
h2 + .single-line,
h3 + .single-line,
h4 + .single-line {
  margin-top: -.5em; }

.img-intext {
  margin-bottom: 20px; }

aside {
  font-size: 16px;
  line-height: 160%; }

aside p {
  margin-bottom: 15px; }

aside .txt-btn {
  font-size: 100%; }

aside .thumb figure {
  margin: 0; }

.thumbs.no-gap {
  margin-right: 0;
  margin-left: 0; }

.thumbs.no-gap .thumb {
  padding: 0; }

.thumbs.gap-xs {
  margin-right: -5px;
  margin-left: 0; }

.thumbs.gap-xs .thumb {
  padding: 0 5px 5px 0; }

.thumbs.gap-sm {
  margin-right: -10px;
  margin-left: 0; }

.thumbs.gap-sm .thumb {
  padding: 0 10px 10px 0; }

.thumbs.gap-md {
  margin-right: -20px;
  margin-left: 0; }

.thumbs.gap-md .thumb {
  padding: 0 20px 20px 0; }

.thumbs.gap-lg {
  margin-right: -30px;
  margin-left: 0; }

.thumbs.gap-lg .thumb {
  padding: 0 30px 30px 0; }

.dropdown-toggle::after {
  display: none; }

.open > .dropdown-menu {
  display: block; }

.dropdown-menu {
  font-size: 0.875rem;
  color: inherit; }

.float-right > .dropdown-menu {
  left: 0;
  right: auto; }

.dropdown-menu > li > a {
  display: block;
  clear: both; }

@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello/fontello.eot");
  src: url("../fonts/fontello/fontello-.eot#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello.woff") format("woff"), url("../fonts/fontello/fontello.ttf") format("truetype"), url("../fonts/fontello/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'fontello-social';
  src: url("../fonts/fontello/fontello-social.eot");
  src: url("../fonts/fontello/fontello-social-.eot#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello-social.woff") format("woff"), url("../fonts/fontello/fontello-social.ttf") format("truetype"), url("../fonts/fontello/fontello-social.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'fontello-circle';
  src: url("../fonts/fontello/fontello-circle.eot");
  src: url("../fonts/fontello/fontello-circle.eot#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello-circle.woff") format("woff"), url("../fonts/fontello/fontello-circle.ttf") format("truetype"), url("../fonts/fontello/fontello-circle.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0;
  text-align: center;
  line-height: 1em; }

[class^="icon-s-"]:before,
[class*=" icon-s-"]:before {
  font-family: 'fontello-social';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0;
  text-align: center;
  line-height: 1em; }

.icon-plus:before {
  content: '\2b'; }

/* '+' */
.icon-plus-1:before {
  content: '\e817'; }

/* '' */
.icon-minus:before {
  content: '\2d'; }

/* '-' */
.icon-minus-1:before {
  content: '\e816'; }

/* '' */
.icon-info:before {
  content: '\2139'; }

/* 'ℹ' */
.icon-left-thin:before {
  content: '\2190'; }

/* '←' */
.icon-left-1:before {
  content: '\e847'; }

/* '' */
.icon-up-thin:before {
  content: '\2191'; }

/* '↑' */
.icon-up-1:before {
  content: '\e845'; }

/* '' */
.icon-right-thin:before {
  content: '\2192'; }

/* '→' */
.icon-right-1:before {
  content: '\e846'; }

/* '' */
.icon-down-thin:before {
  content: '\2193'; }

/* '↓' */
.icon-down-1:before {
  content: '\e848'; }

/* '' */
.icon-level-up:before {
  content: '\21b0'; }

/* '↰' */
.icon-level-down:before {
  content: '\21b3'; }

/* '↳' */
.icon-switch:before {
  content: '\21c6'; }

/* '⇆' */
.icon-infinity:before {
  content: '\221e'; }

/* '∞' */
.icon-plus-squared:before {
  content: '\229e'; }

/* '⊞' */
.icon-minus-squared:before {
  content: '\229f'; }

/* '⊟' */
.icon-home:before {
  content: '\2302'; }

/* '⌂' */
.icon-home-1:before {
  content: '\e811'; }

/* '' */
.icon-keyboard:before {
  content: '\2328'; }

/* '⌨' */
.icon-erase:before {
  content: '\232b'; }

/* '⌫' */
.icon-pause:before {
  content: '\2389'; }

/* '⎉' */
.icon-pause-1:before {
  content: '\e851'; }

/* '' */
.icon-fast-forward:before {
  content: '\23e9'; }

/* '⏩' */
.icon-fast-fw:before {
  content: '\e856'; }

/* '' */
.icon-fast-backward:before {
  content: '\23ea'; }

/* '⏪' */
.icon-fast-bw:before {
  content: '\e857'; }

/* '' */
.icon-to-end:before {
  content: '\23ed'; }

/* '⏭' */
.icon-to-end-1:before {
  content: '\e852'; }

/* '' */
.icon-to-start:before {
  content: '\23ee'; }

/* '⏮' */
.icon-to-start-1:before {
  content: '\e854'; }

/* '' */
.icon-hourglass:before {
  content: '\23f3'; }

/* '⏳' */
.icon-stop:before {
  content: '\25a0'; }

/* '■' */
.icon-stop-1:before {
  content: '\25aa'; }

/* '▪' */
.icon-up-dir:before {
  content: '\25b4'; }

/* '▴' */
.icon-up-dir-1:before {
  content: '\e841'; }

/* '' */
.icon-play:before {
  content: '\25b6'; }

/* '▶' */
.icon-play-1:before {
  content: '\e84f'; }

/* '' */
.icon-right-dir:before {
  content: '\25b8'; }

/* '▸' */
.icon-right-dir-1:before {
  content: '\e84d'; }

/* '' */
.icon-down-dir:before {
  content: '\25be'; }

/* '▾' */
.icon-down-dir-1:before {
  content: '\e840'; }

/* '' */
.icon-left-dir:before {
  content: '\25c2'; }

/* '◂' */
.icon-left-dir-1:before {
  content: '\e842'; }

/* '' */
.icon-adjust:before {
  content: '\25d1'; }

/* '◑' */
.icon-cloud:before {
  content: '\2601'; }

/* '☁' */
.icon-cloud-1:before {
  content: '\e85c'; }

/* '' */
.icon-umbrella:before {
  content: '\2602'; }

/* '☂' */
.icon-star:before {
  content: '\2605'; }

/* '★' */
.icon-star-1:before {
  content: '\e805'; }

/* '' */
.icon-star-empty:before {
  content: '\2606'; }

/* '☆' */
.icon-star-empty-1:before {
  content: '\e806'; }

/* '' */
.icon-check-1:before {
  content: '\2611'; }

/* '☑' */
.icon-cup:before {
  content: '\2615'; }

/* '☕' */
.icon-left-hand:before {
  content: '\261c'; }

/* '☜' */
.icon-up-hand:before {
  content: '\261d'; }

/* '☝' */
.icon-right-hand:before {
  content: '\261e'; }

/* '☞' */
.icon-down-hand:before {
  content: '\261f'; }

/* '☟' */
.icon-menu:before {
  content: '\2630'; }

/* '☰' */
.icon-th-list:before {
  content: '\e81b'; }

/* '' */
.icon-moon:before {
  content: '\263d'; }

/* '☽' */
.icon-heart-empty:before {
  content: '\2661'; }

/* '♡' */
.icon-heart-empty-1:before {
  content: '\e804'; }

/* '' */
.icon-heart:before {
  content: '\2665'; }

/* '♥' */
.icon-heart-1:before {
  content: '\e803'; }

/* '' */
.icon-note:before {
  content: '\266a'; }

/* '♪' */
.icon-note-beamed:before {
  content: '\266b'; }

/* '♫' */
.icon-music-1:before {
  content: '\e800'; }

/* '' */
.icon-th:before {
  content: '\268f'; }

/* '⚏' */
.icon-layout:before {
  content: '\e80c'; }

/* '' */
.icon-flag:before {
  content: '\2691'; }

/* '⚑' */
.icon-flag-1:before {
  content: '\e81f'; }

/* '' */
.icon-tools:before {
  content: '\2692'; }

/* '⚒' */
.icon-cog:before {
  content: '\2699'; }

/* '⚙' */
.icon-cog-1:before {
  content: '\e82e'; }

/* '' */
.icon-attention:before {
  content: '\26a0'; }

/* '⚠' */
.icon-attention-1:before {
  content: '\e834'; }

/* '' */
.icon-flash:before {
  content: '\26a1'; }

/* '⚡' */
.icon-flash-1:before {
  content: '\e85d'; }

/* '' */
.icon-record:before {
  content: '\26ab'; }

/* '⚫' */
.icon-cloud-thunder:before {
  content: '\26c8'; }

/* '⛈' */
.icon-cog-alt:before {
  content: '\26ef'; }

/* '⛯' */
.icon-scissors:before {
  content: '\2702'; }

/* '✂' */
.icon-tape:before {
  content: '\2707'; }

/* '✇' */
.icon-flight:before {
  content: '\2708'; }

/* '✈' */
.icon-flight-1:before {
  content: '\e86f'; }

/* '' */
.icon-mail:before {
  content: '\2709'; }

/* '✉' */
.icon-mail-1:before {
  content: '\e802'; }

/* '' */
.icon-edit:before {
  content: '\270d'; }

/* '✍' */
.icon-pencil:before {
  content: '\270e'; }

/* '✎' */
.icon-pencil-1:before {
  content: '\e826'; }

/* '' */
.icon-feather:before {
  content: '\2712'; }

/* '✒' */
.icon-check:before {
  content: '\2713'; }

/* '✓' */
.icon-ok:before {
  content: '\e81a'; }

/* '' */
.icon-ok-circle:before {
  content: '\2714'; }

/* '✔' */
.icon-cancel:before {
  content: '\2715'; }

/* '✕' */
.icon-cancel-1:before {
  content: '\e819'; }

/* '' */
.icon-cancel-circled:before {
  content: '\2716'; }

/* '✖' */
.icon-cancel-circle:before {
  content: '\e818'; }

/* '' */
.icon-asterisk:before {
  content: '\2731'; }

/* '✱' */
.icon-cancel-squared:before {
  content: '\274e'; }

/* '❎' */
.icon-help:before {
  content: '\2753'; }

/* '❓' */
.icon-attention-circle:before {
  content: '\2757'; }

/* '❗' */
.icon-quote:before {
  content: '\275e'; }

/* '❞' */
.icon-plus-circled:before {
  content: '\2795'; }

/* '➕' */
.icon-plus-circle:before {
  content: '\e815'; }

/* '' */
.icon-minus-circled:before {
  content: '\2796'; }

/* '➖' */
.icon-minus-circle:before {
  content: '\e814'; }

/* '' */
.icon-right:before {
  content: '\27a1'; }

/* '➡' */
.icon-direction:before {
  content: '\27a2'; }

/* '➢' */
.icon-forward:before {
  content: '\27a6'; }

/* '➦' */
.icon-forward-1:before {
  content: '\e824'; }

/* '' */
.icon-ccw:before {
  content: '\27f2'; }

/* '⟲' */
.icon-cw:before {
  content: '\27f3'; }

/* '⟳' */
.icon-cw-1:before {
  content: '\e844'; }

/* '' */
.icon-left:before {
  content: '\2b05'; }

/* '⬅' */
.icon-up:before {
  content: '\2b06'; }

/* '⬆' */
.icon-down:before {
  content: '\2b07'; }

/* '⬇' */
.icon-resize-vertical:before {
  content: '\2b0c'; }

/* '⬌' */
.icon-resize-horizontal:before {
  content: '\2b0d'; }

/* '⬍' */
.icon-eject:before {
  content: '\2ecf'; }

/* '⻏' */
.icon-list-add:before {
  content: '\e003'; }

/* '' */
.icon-list:before {
  content: '\e005'; }

/* '' */
.icon-left-bold:before {
  content: '\e4ad'; }

/* '' */
.icon-right-bold:before {
  content: '\e4ae'; }

/* '' */
.icon-up-bold:before {
  content: '\e4af'; }

/* '' */
.icon-down-bold:before {
  content: '\e4b0'; }

/* '' */
.icon-user-add:before {
  content: '\e700'; }

/* '' */
.icon-star-half:before {
  content: '\e701'; }

/* '' */
.icon-ok-circle2:before {
  content: '\e702'; }

/* '' */
.icon-cancel-circle2:before {
  content: '\e703'; }

/* '' */
.icon-help-circled:before {
  content: '\e704'; }

/* '' */
.icon-help-circle:before {
  content: '\e813'; }

/* '' */
.icon-info-circled:before {
  content: '\e705'; }

/* '' */
.icon-info-circle:before {
  content: '\e812'; }

/* '' */
.icon-th-large:before {
  content: '\e708'; }

/* '' */
.icon-eye:before {
  content: '\e70a'; }

/* '' */
.icon-eye-1:before {
  content: '\e81c'; }

/* '' */
.icon-eye-off:before {
  content: '\e70b'; }

/* '' */
.icon-tag:before {
  content: '\e70c'; }

/* '' */
.icon-tag-1:before {
  content: '\e81d'; }

/* '' */
.icon-tags:before {
  content: '\e70d'; }

/* '' */
.icon-camera-alt:before {
  content: '\e70f'; }

/* '' */
.icon-upload-cloud:before {
  content: '\e711'; }

/* '' */
.icon-reply:before {
  content: '\e712'; }

/* '' */
.icon-reply-all:before {
  content: '\e713'; }

/* '' */
.icon-code:before {
  content: '\e714'; }

/* '' */
.icon-export:before {
  content: '\e715'; }

/* '' */
.icon-export-1:before {
  content: '\e825'; }

/* '' */
.icon-print:before {
  content: '\e716'; }

/* '' */
.icon-print-1:before {
  content: '\e827'; }

/* '' */
.icon-retweet:before {
  content: '\e717'; }

/* '' */
.icon-retweet-1:before {
  content: '\e828'; }

/* '' */
.icon-comment:before {
  content: '\e718'; }

/* '' */
.icon-comment-1:before {
  content: '\e829'; }

/* '' */
.icon-chat:before {
  content: '\e720'; }

/* '' */
.icon-chat-1:before {
  content: '\e82a'; }

/* '' */
.icon-vcard:before {
  content: '\e722'; }

/* '' */
.icon-address:before {
  content: '\e723'; }

/* '' */
.icon-location:before {
  content: '\e724'; }

/* '' */
.icon-location-1:before {
  content: '\e833'; }

/* '' */
.icon-map:before {
  content: '\e727'; }

/* '' */
.icon-compass:before {
  content: '\e728'; }

/* '' */
.icon-trash:before {
  content: '\e729'; }

/* '' */
.icon-trash-1:before {
  content: '\e832'; }

/* '' */
.icon-doc:before {
  content: '\e730'; }

/* '' */
.icon-doc-text-inv:before {
  content: '\e731'; }

/* '' */
.icon-docs:before {
  content: '\e736'; }

/* '' */
.icon-doc-landscape:before {
  content: '\e737'; }

/* '' */
.icon-archive:before {
  content: '\e738'; }

/* '' */
.icon-rss:before {
  content: '\e73a'; }

/* '' */
.icon-share:before {
  content: '\e73c'; }

/* '' */
.icon-basket:before {
  content: '\e73d'; }

/* '' */
.icon-basket-1:before {
  content: '\e82d'; }

/* '' */
.icon-shareable:before {
  content: '\e73e'; }

/* '' */
.icon-login:before {
  content: '\e740'; }

/* '' */
.icon-login-1:before {
  content: '\e82b'; }

/* '' */
.icon-logout:before {
  content: '\e741'; }

/* '' */
.icon-logout-1:before {
  content: '\e836'; }

/* '' */
.icon-volume:before {
  content: '\e742'; }

/* '' */
.icon-resize-full:before {
  content: '\e744'; }

/* '' */
.icon-resize-full-1:before {
  content: '\e83b'; }

/* '' */
.icon-resize-small:before {
  content: '\e746'; }

/* '' */
.icon-resize-small-1:before {
  content: '\e83c'; }

/* '' */
.icon-popup:before {
  content: '\e74c'; }

/* '' */
.icon-publish:before {
  content: '\e74d'; }

/* '' */
.icon-window:before {
  content: '\e74e'; }

/* '' */
.icon-arrow-combo:before {
  content: '\e74f'; }

/* '' */
.icon-zoom-in:before {
  content: '\e750'; }

/* '' */
.icon-chart-pie:before {
  content: '\e751'; }

/* '' */
.icon-zoom-out:before {
  content: '\e83d'; }

/* '' */
.icon-language:before {
  content: '\e752'; }

/* '' */
.icon-air:before {
  content: '\e753'; }

/* '' */
.icon-database:before {
  content: '\e754'; }

/* '' */
.icon-drive:before {
  content: '\e755'; }

/* '' */
.icon-bucket:before {
  content: '\e756'; }

/* '' */
.icon-thermometer:before {
  content: '\e757'; }

/* '' */
.icon-down-circled:before {
  content: '\e758'; }

/* '' */
.icon-down-circle2:before {
  content: '\e83e'; }

/* '' */
.icon-left-circled:before {
  content: '\e759'; }

/* '' */
.icon-right-circled:before {
  content: '\e75a'; }

/* '' */
.icon-up-circled:before {
  content: '\e75b'; }

/* '' */
.icon-up-circle2:before {
  content: '\e83f'; }

/* '' */
.icon-down-open:before {
  content: '\e75c'; }

/* '' */
.icon-down-open-1:before {
  content: '\e84c'; }

/* '' */
.icon-left-open:before {
  content: '\e75d'; }

/* '' */
.icon-left-open-1:before {
  content: '\e84b'; }

/* '' */
.icon-right-open:before {
  content: '\e75e'; }

/* '' */
.icon-right-open-1:before {
  content: '\e84a'; }

/* '' */
.icon-up-open:before {
  content: '\e75f'; }

/* '' */
.icon-up-open-1:before {
  content: '\e849'; }

/* '' */
.icon-down-open-mini:before {
  content: '\e760'; }

/* '' */
.icon-arrows-cw:before {
  content: '\e843'; }

/* '' */
.icon-left-open-mini:before {
  content: '\e761'; }

/* '' */
.icon-play-circle2:before {
  content: '\e850'; }

/* '' */
.icon-right-open-mini:before {
  content: '\e762'; }

/* '' */
.icon-to-end-alt:before {
  content: '\e853'; }

/* '' */
.icon-up-open-mini:before {
  content: '\e763'; }

/* '' */
.icon-to-start-alt:before {
  content: '\e855'; }

/* '' */
.icon-down-open-big:before {
  content: '\e764'; }

/* '' */
.icon-left-open-big:before {
  content: '\e765'; }

/* '' */
.icon-right-open-big:before {
  content: '\e766'; }

/* '' */
.icon-up-open-big:before {
  content: '\e767'; }

/* '' */
.icon-progress-0:before {
  content: '\e768'; }

/* '' */
.icon-progress-1:before {
  content: '\e769'; }

/* '' */
.icon-progress-2:before {
  content: '\e76a'; }

/* '' */
.icon-progress-3:before {
  content: '\e76b'; }

/* '' */
.icon-back-in-time:before {
  content: '\e771'; }

/* '' */
.icon-network:before {
  content: '\e776'; }

/* '' */
.icon-inbox:before {
  content: '\e777'; }

/* '' */
.icon-inbox-1:before {
  content: '\e85a'; }

/* '' */
.icon-install:before {
  content: '\e778'; }

/* '' */
.icon-font:before {
  content: '\e779'; }

/* '' */
.icon-bold:before {
  content: '\e77a'; }

/* '' */
.icon-italic:before {
  content: '\e77b'; }

/* '' */
.icon-text-height:before {
  content: '\e77c'; }

/* '' */
.icon-text-width:before {
  content: '\e77d'; }

/* '' */
.icon-align-left:before {
  content: '\e77e'; }

/* '' */
.icon-align-center:before {
  content: '\e77f'; }

/* '' */
.icon-align-right:before {
  content: '\e780'; }

/* '' */
.icon-align-justify:before {
  content: '\e781'; }

/* '' */
.icon-list-1:before {
  content: '\e782'; }

/* '' */
.icon-indent-left:before {
  content: '\e783'; }

/* '' */
.icon-indent-right:before {
  content: '\e784'; }

/* '' */
.icon-lifebuoy:before {
  content: '\e788'; }

/* '' */
.icon-mouse:before {
  content: '\e789'; }

/* '' */
.icon-dot:before {
  content: '\e78b'; }

/* '' */
.icon-dot-2:before {
  content: '\e78c'; }

/* '' */
.icon-dot-3:before {
  content: '\e78d'; }

/* '' */
.icon-suitcase:before {
  content: '\e78e'; }

/* '' */
.icon-off:before {
  content: '\e86a'; }

/* '' */
.icon-road:before {
  content: '\e78f'; }

/* '' */
.icon-flow-cascade:before {
  content: '\e790'; }

/* '' */
.icon-list-alt:before {
  content: '\e869'; }

/* '' */
.icon-flow-branch:before {
  content: '\e791'; }

/* '' */
.icon-qrcode:before {
  content: '\e868'; }

/* '' */
.icon-flow-tree:before {
  content: '\e792'; }

/* '' */
.icon-barcode:before {
  content: '\e867'; }

/* '' */
.icon-flow-line:before {
  content: '\e793'; }

/* '' */
.icon-ajust:before {
  content: '\e865'; }

/* '' */
.icon-flow-parallel:before {
  content: '\e794'; }

/* '' */
.icon-tint:before {
  content: '\e864'; }

/* '' */
.icon-brush:before {
  content: '\e79a'; }

/* '' */
.icon-paper-plane:before {
  content: '\e79b'; }

/* '' */
.icon-magnet:before {
  content: '\e7a1'; }

/* '' */
.icon-magnet-1:before {
  content: '\e863'; }

/* '' */
.icon-gauge:before {
  content: '\e7a2'; }

/* '' */
.icon-traffic-cone:before {
  content: '\e7a3'; }

/* '' */
.icon-cc:before {
  content: '\e7a5'; }

/* '' */
.icon-cc-by:before {
  content: '\e7a6'; }

/* '' */
.icon-cc-nc:before {
  content: '\e7a7'; }

/* '' */
.icon-cc-nc-eu:before {
  content: '\e7a8'; }

/* '' */
.icon-cc-nc-jp:before {
  content: '\e7a9'; }

/* '' */
.icon-cc-sa:before {
  content: '\e7aa'; }

/* '' */
.icon-cc-nd:before {
  content: '\e7ab'; }

/* '' */
.icon-cc-pd:before {
  content: '\e7ac'; }

/* '' */
.icon-cc-zero:before {
  content: '\e7ad'; }

/* '' */
.icon-cc-share:before {
  content: '\e7ae'; }

/* '' */
.icon-cc-remix:before {
  content: '\e7af'; }

/* '' */
.icon-move:before {
  content: '\f047'; }

/* '' */
.icon-link-ext:before {
  content: '\f08e'; }

/* '' */
.icon-check-empty:before {
  content: '\f096'; }

/* '' */
.icon-bookmark-empty:before {
  content: '\f097'; }

/* '' */
.icon-phone-squared:before {
  content: '\f098'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-github:before {
  content: '\f09b'; }

/* '' */
.icon-rss-1:before {
  content: '\f09e'; }

/* '' */
.icon-hdd:before {
  content: '\f0a0'; }

/* '' */
.icon-certificate:before {
  content: '\f0a3'; }

/* '' */
.icon-left-circled-1:before {
  content: '\f0a8'; }

/* '' */
.icon-right-circled-1:before {
  content: '\f0a9'; }

/* '' */
.icon-up-circled-1:before {
  content: '\f0aa'; }

/* '' */
.icon-down-circled-1:before {
  content: '\f0ab'; }

/* '' */
.icon-tasks:before {
  content: '\f0ae'; }

/* '' */
.icon-filter:before {
  content: '\f0b0'; }

/* '' */
.icon-resize-full-alt:before {
  content: '\f0b2'; }

/* '' */
.icon-beaker:before {
  content: '\f0c3'; }

/* '' */
.icon-docs-1:before {
  content: '\f0c5'; }

/* '' */
.icon-blank:before {
  content: '\f0c8'; }

/* '' */
.icon-menu-1:before {
  content: '\f0c9'; }

/* '' */
.icon-list-bullet:before {
  content: '\f0ca'; }

/* '' */
.icon-list-numbered:before {
  content: '\f0cb'; }

/* '' */
.icon-strike:before {
  content: '\f0cc'; }

/* '' */
.icon-underline:before {
  content: '\f0cd'; }

/* '' */
.icon-table:before {
  content: '\f0ce'; }

/* '' */
.icon-magic:before {
  content: '\f0d0'; }

/* '' */
.icon-pinterest-circled-1:before {
  content: '\f0d2'; }

/* '' */
.icon-pinterest-squared:before {
  content: '\f0d3'; }

/* '' */
.icon-gplus-squared:before {
  content: '\f0d4'; }

/* '' */
.icon-gplus:before {
  content: '\f0d5'; }

/* '' */
.icon-money:before {
  content: '\f0d6'; }

/* '' */
.icon-columns:before {
  content: '\f0db'; }

/* '' */
.icon-sort:before {
  content: '\f0dc'; }

/* '' */
.icon-sort-down:before {
  content: '\f0dd'; }

/* '' */
.icon-sort-up:before {
  content: '\f0de'; }

/* '' */
.icon-mail-alt:before {
  content: '\f0e0'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-gauge-1:before {
  content: '\f0e4'; }

/* '' */
.icon-comment-empty:before {
  content: '\f0e5'; }

/* '' */
.icon-chat-empty:before {
  content: '\f0e6'; }

/* '' */
.icon-sitemap:before {
  content: '\f0e8'; }

/* '' */
.icon-paste:before {
  content: '\f0ea'; }

/* '' */
.icon-user-md:before {
  content: '\f200'; }

/* '' */
.icon-s-github:before {
  content: '\f300'; }

/* '' */
.icon-github-squared:before {
  content: '\e862'; }

/* '' */
.icon-github-circled:before {
  content: '\f301'; }

/* '' */
.icon-s-flickr:before {
  content: '\f303'; }

/* '' */
.icon-twitter-squared:before {
  content: '\e85f'; }

/* '' */
.icon-s-vimeo:before {
  content: '\f306'; }

/* '' */
.icon-vimeo-circled:before {
  content: '\f307'; }

/* '' */
.icon-facebook-squared-1:before {
  content: '\f308'; }

/* '' */
.icon-s-twitter:before {
  content: '\f309'; }

/* '' */
.icon-twitter-circled:before {
  content: '\f30a'; }

/* '' */
.icon-s-facebook:before {
  content: '\f30c'; }

/* '' */
.icon-linkedin-squared:before {
  content: '\e870'; }

/* '' */
.icon-facebook-circled:before {
  content: '\f30d'; }

/* '' */
.icon-s-gplus:before {
  content: '\f30f'; }

/* '' */
.icon-gplus-circled:before {
  content: '\f310'; }

/* '' */
.icon-s-pinterest:before {
  content: '\f312'; }

/* '' */
.icon-pinterest-circled:before {
  content: '\f313'; }

/* '' */
.icon-s-tumblr:before {
  content: '\f315'; }

/* '' */
.icon-tumblr-circled:before {
  content: '\f316'; }

/* '' */
.icon-s-linkedin:before {
  content: '\f318'; }

/* '' */
.icon-linkedin-circled:before {
  content: '\f319'; }

/* '' */
.icon-s-dribbble:before {
  content: '\f31b'; }

/* '' */
.icon-dribbble-circled:before {
  content: '\f31c'; }

/* '' */
.icon-s-stumbleupon:before {
  content: '\f31e'; }

/* '' */
.icon-stumbleupon-circled:before {
  content: '\f31f'; }

/* '' */
.icon-s-lastfm:before {
  content: '\f321'; }

/* '' */
.icon-lastfm-circled:before {
  content: '\f322'; }

/* '' */
.icon-rdio:before {
  content: '\f324'; }

/* '' */
.icon-rdio-circled:before {
  content: '\f325'; }

/* '' */
.icon-spotify:before {
  content: '\f327'; }

/* '' */
.icon-s-spotify-circled:before {
  content: '\f328'; }

/* '' */
.icon-qq:before {
  content: '\f32a'; }

/* '' */
.icon-s-instagrem:before {
  content: '\f32d'; }

/* '' */
.icon-dropbox:before {
  content: '\f330'; }

/* '' */
.icon-s-evernote:before {
  content: '\f333'; }

/* '' */
.icon-flattr:before {
  content: '\f336'; }

/* '' */
.icon-s-skype:before {
  content: '\f339'; }

/* '' */
.icon-skype-circled:before {
  content: '\f33a'; }

/* '' */
.icon-renren:before {
  content: '\f33c'; }

/* '' */
.icon-sina-weibo:before {
  content: '\f33f'; }

/* '' */
.icon-s-paypal:before {
  content: '\f342'; }

/* '' */
.icon-s-picasa:before {
  content: '\f345'; }

/* '' */
.icon-s-soundcloud:before {
  content: '\f348'; }

/* '' */
.icon-s-behance:before {
  content: '\f34e'; }

/* '' */
.icon-google-circles:before {
  content: '\f351'; }

/* '' */
.icon-vkontakte:before {
  content: '\f354'; }

/* '' */
.icon-smashing:before {
  content: '\f357'; }

/* '' */
.icon-db-shape:before {
  content: '\f600'; }

/* '' */
.icon-sweden:before {
  content: '\f601'; }

/* '' */
.icon-logo-db:before {
  content: '\f603'; }

/* '' */
.icon-picture:before {
  content: '\f304'; }

/* '' */
.icon-picture-1:before {
  content: '\e80a'; }

/* '' */
.icon-globe:before {
  content: '\f30e'; }

/* '' */
.icon-globe-1:before {
  content: '\e85b'; }

/* '' */
.icon-leaf-1:before {
  content: '\e86d'; }

/* '' */
.icon-lemon:before {
  content: '\f34b'; }

/* '' */
.icon-glass:before {
  content: '\f378'; }

/* '' */
.icon-gift:before {
  content: '\f381'; }

/* '' */
.icon-graduation-cap:before {
  content: '\f393'; }

/* '' */
.icon-mic:before {
  content: '\f3a4'; }

/* '' */
.icon-videocam:before {
  content: '\f3a5'; }

/* '' */
.icon-headphones:before {
  content: '\f3a7'; }

/* '' */
.icon-palette:before {
  content: '\f3a8'; }

/* '' */
.icon-ticket:before {
  content: '\f3ab'; }

/* '' */
.icon-video:before {
  content: '\f3ac'; }

/* '' */
.icon-video-1:before {
  content: '\e809'; }

/* '' */
.icon-target:before {
  content: '\f3af'; }

/* '' */
.icon-target-1:before {
  content: '\e858'; }

/* '' */
.icon-music:before {
  content: '\f3b5'; }

/* '' */
.icon-trophy:before {
  content: '\f3c6'; }

/* '' */
.icon-award:before {
  content: '\f3c9'; }

/* '' */
.icon-thumbs-up:before {
  content: '\f44d'; }

/* '' */
.icon-thumbs-up-1:before {
  content: '\e820'; }

/* '' */
.icon-thumbs-down:before {
  content: '\f44e'; }

/* '' */
.icon-thumbs-down-1:before {
  content: '\e821'; }

/* '' */
.icon-bag:before {
  content: '\f45c'; }

/* '' */
.icon-user:before {
  content: '\f464'; }

/* '' */
.icon-user-1:before {
  content: '\e807'; }

/* '' */
.icon-users:before {
  content: '\f465'; }

/* '' */
.icon-users-1:before {
  content: '\e808'; }

/* '' */
.icon-lamp:before {
  content: '\f4a1'; }

/* '' */
.icon-alert:before {
  content: '\f4a5'; }

/* '' */
.icon-water:before {
  content: '\f4a6'; }

/* '' */
.icon-droplet:before {
  content: '\f4a7'; }

/* '' */
.icon-credit-card:before {
  content: '\f4b3'; }

/* '' */
.icon-credit-card-1:before {
  content: '\e860'; }

/* '' */
.icon-monitor:before {
  content: '\f4bb'; }

/* '' */
.icon-briefcase:before {
  content: '\f4bc'; }

/* '' */
.icon-briefcase-1:before {
  content: '\e86c'; }

/* '' */
.icon-floppy:before {
  content: '\f4be'; }

/* '' */
.icon-floppy-1:before {
  content: '\e85e'; }

/* '' */
.icon-cd:before {
  content: '\f4bf'; }

/* '' */
.icon-folder:before {
  content: '\f4c1'; }

/* '' */
.icon-folder-1:before {
  content: '\e830'; }

/* '' */
.icon-folder-open:before {
  content: '\f4c2'; }

/* '' */
.icon-doc-text:before {
  content: '\f4c4'; }

/* '' */
.icon-doc-1:before {
  content: '\e831'; }

/* '' */
.icon-calendar:before {
  content: '\f4c5'; }

/* '' */
.icon-calendar-1:before {
  content: '\e82c'; }

/* '' */
.icon-chart-line:before {
  content: '\f4c8'; }

/* '' */
.icon-chart-bar:before {
  content: '\f4ca'; }

/* '' */
.icon-chart-bar-1:before {
  content: '\e861'; }

/* '' */
.icon-clipboard:before {
  content: '\f4cb'; }

/* '' */
.icon-pin:before {
  content: '\f4cc'; }

/* '' */
.icon-attach:before {
  content: '\f4ce'; }

/* '' */
.icon-attach-1:before {
  content: '\e80f'; }

/* '' */
.icon-bookmarks:before {
  content: '\f4d1'; }

/* '' */
.icon-book:before {
  content: '\f4d5'; }

/* '' */
.icon-book-1:before {
  content: '\e866'; }

/* '' */
.icon-book-open:before {
  content: '\f4d6'; }

/* '' */
.icon-phone:before {
  content: '\f4de'; }

/* '' */
.icon-phone-1:before {
  content: '\e82f'; }

/* '' */
.icon-megaphone:before {
  content: '\f4e3'; }

/* '' */
.icon-megaphone-1:before {
  content: '\e86e'; }

/* '' */
.icon-upload:before {
  content: '\f4e4'; }

/* '' */
.icon-upload-1:before {
  content: '\e823'; }

/* '' */
.icon-download:before {
  content: '\f4e5'; }

/* '' */
.icon-download-1:before {
  content: '\e822'; }

/* '' */
.icon-box:before {
  content: '\f4e6'; }

/* '' */
.icon-newspaper:before {
  content: '\f4f0'; }

/* '' */
.icon-mobile:before {
  content: '\f4f1'; }

/* '' */
.icon-signal:before {
  content: '\f4f6'; }

/* '' */
.icon-signal-1:before {
  content: '\e859'; }

/* '' */
.icon-camera:before {
  content: '\f4f7'; }

/* '' */
.icon-camera-1:before {
  content: '\e80b'; }

/* '' */
.icon-shuffle:before {
  content: '\f500'; }

/* '' */
.icon-shuffle-1:before {
  content: '\e84e'; }

/* '' */
.icon-loop:before {
  content: '\f501'; }

/* '' */
.icon-arrows-ccw:before {
  content: '\f504'; }

/* '' */
.icon-light-down:before {
  content: '\f505'; }

/* '' */
.icon-light-up:before {
  content: '\f506'; }

/* '' */
.icon-mute:before {
  content: '\f507'; }

/* '' */
.icon-volume-off:before {
  content: '\e837'; }

/* '' */
.icon-volume-down:before {
  content: '\f509'; }

/* '' */
.icon-sound:before {
  content: '\f50a'; }

/* '' */
.icon-volume-up:before {
  content: '\e838'; }

/* '' */
.icon-battery:before {
  content: '\f50b'; }

/* '' */
.icon-search:before {
  content: '\f50d'; }

/* '' */
.icon-search-1:before {
  content: '\e801'; }

/* '' */
.icon-key:before {
  content: '\f511'; }

/* '' */
.icon-key-1:before {
  content: '\e86b'; }

/* '' */
.icon-lock:before {
  content: '\f512'; }

/* '' */
.icon-lock-1:before {
  content: '\e80e'; }

/* '' */
.icon-lock-open:before {
  content: '\f513'; }

/* '' */
.icon-lock-open-1:before {
  content: '\e80d'; }

/* '' */
.icon-bell:before {
  content: '\f514'; }

/* '' */
.icon-bell-1:before {
  content: '\e835'; }

/* '' */
.icon-bookmark:before {
  content: '\f516'; }

/* '' */
.icon-bookmark-1:before {
  content: '\e81e'; }

/* '' */
.icon-link:before {
  content: '\f517'; }

/* '' */
.icon-link-1:before {
  content: '\e810'; }

/* '' */
.icon-back:before {
  content: '\f519'; }

/* '' */
.icon-fire:before {
  content: '\f525'; }

/* '' */
.icon-flashlight:before {
  content: '\f526'; }

/* '' */
.icon-wrench:before {
  content: '\f527'; }

/* '' */
.icon-hammer:before {
  content: '\f528'; }

/* '' */
.icon-chart-area:before {
  content: '\f53e'; }

/* '' */
.icon-clock:before {
  content: '\f554'; }

/* '' */
.icon-clock-1:before {
  content: '\e839'; }

/* '' */
.icon-rocket:before {
  content: '\f680'; }

/* '' */
.icon-truck:before {
  content: '\f69a'; }

/* '' */
.icon-block:before {
  content: '\f6ab'; }

/* '' */
.icon-block-1:before {
  content: '\e83a'; }

/* '' */
.icon-s-rss:before {
  font-family: 'fontello';
  content: '\e73a'; }

/* '' */
.icon-s-delicious:before {
  content: '\23'; }

/* '#' */
.icon-s-500px:before {
  content: '\30'; }

/* '0' */
.icon-s-grooveshark:before {
  content: '\38'; }

/* '8' */
.icon-s-forrst:before {
  content: '\3a'; }

/* ':' */
.icon-s-digg:before {
  content: '\3b'; }

/* ';' */
.icon-s-blogger:before {
  content: '\42'; }

/* 'B' */
.icon-s-klout:before {
  content: '\4b'; }

/* 'K' */
.icon-s-dropbox:before {
  content: '\64'; }

/* 'd' */
.icon-s-ebay:before {
  content: '\65'; }

/* 'e' */
.icon-s-github-1:before {
  content: '\67'; }

/* 'g' */
.icon-s-songkick:before {
  content: '\6b'; }

/* 'k' */
.icon-s-posterous:before {
  content: '\7e'; }

/* '~' */
.icon-s-appnet:before {
  content: '\e1'; }

/* 'á' */
.icon-s-github:before {
  content: '\f300'; }

/* '' */
.icon-s-flickr:before {
  content: '\f303'; }

/* '' */
.icon-s-vimeo:before {
  content: '\f306'; }

/* '' */
.icon-s-twitter:before {
  content: '\f309'; }

/* '' */
.icon-s-facebook:before {
  content: '\f30c'; }

/* '' */
.icon-s-gplus:before {
  content: '\f30f'; }

/* '' */
.icon-s-pinterest:before {
  content: '\f312'; }

/* '' */
.icon-s-youtube:before {
  content: '\f313'; }

/* '' */
.icon-s-tumblr:before {
  content: '\f315'; }

/* '' */
.icon-s-linkedin:before {
  content: '\f318'; }

/* '' */
.icon-s-dribbble:before {
  content: '\f31b'; }

/* '' */
.icon-s-stumbleupon:before {
  content: '\f31e'; }

/* '' */
.icon-s-lastfm:before {
  content: '\f321'; }

/* '' */
.icon-s-spotify:before {
  content: '\f328'; }

/* '' */
.icon-s-instagram:before {
  content: '\f32d'; }

/* '' */
.icon-s-evernote:before {
  content: '\f333'; }

/* '' */
.icon-s-skype:before {
  content: '\f339'; }

/* '' */
.icon-s-paypal:before {
  content: '\f342'; }

/* '' */
.icon-s-picasa:before {
  content: '\f345'; }

/* '' */
.icon-s-soundcloud:before {
  content: '\f348'; }

/* '' */
.icon-s-behance:before {
  content: '\f34e'; }

/* '' */
.icon-circle-empty:before {
  content: '\e800'; }

/* '' */
/*===================================================================================*/
/*	HEADER
/*===================================================================================*/
.navbar .container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 0; }

.navbar {
  display: block;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  position: relative;
  z-index: 8000;
  min-height: inherit; }

.navbar ul {
  list-style: none; }

.navbar-brand {
  line-height: 100%;
  padding: 0;
  margin-left: 0; }

.navbar-brand img {
  display: inline-block;
  width: auto;
  /* Needed for SVG images */
  height: 75px;
  max-width: 100%; }

.navbar-brand img[src*=".svg"] {
  max-width: 200%;
  /* Needed for SVG images in at least IE 10/11 */ }

.navbar-brand img.animate {
  -moz-transition: none; }

.navbar-header .navbar-brand {
  display: none; }

.navbar-header {
  float: none !important;
  position: relative;
  background: #F5F7FA;
  border-bottom: 1px solid #E6E9ED;
  z-index: 0; }

.navbar-header li,
.navbar-header a {
  display: table-cell;
  vertical-align: middle;
  height: 40px; }

.navbar-collapse {
  background-color: white;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E6E9ED; }

.navbar-collapse.collapse:not(.show) {
  display: block; }

.navbar-collapse.collapse.affix {
  position: fixed;
  top: 0; }

.navbar-collapse.collapse.animate {
  -moz-transition: none; }

.navbar-collapse.collapse.animate-after {
  background-color: rgba(255, 255, 255, 0.97); }

.navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0; }

.navbar-nav > li:first-of-type,
.navbar-nav > li.searchbox {
  margin-right: auto; }

.navbar-nav > li > a {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #0c1e23;
  padding: 15px 20px;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 3px;
  bottom: -1px;
  z-index: 2; }

.navbar-nav > li.dropdown > a {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.125s ease-out; }

.animate-after .navbar-nav > li > a {
  border-radius: 0;
  margin-top: -1px; }

.nav > li,
.nav > li > a {
  position: relative;
  display: block; }

.nav > li.active > a {
  color: #1ABB9C; }

.nav > li > a:focus {
  background-color: transparent;
  border-color: transparent; }

.nav > li > a:hover {
  background-color: #F5F7FA;
  border-color: #E6E9ED;
  color: #1ABB9C; }

.navbar .dropdown-menu {
  padding: 0;
  margin: 0;
  width: 210px;
  background: #F5F7FA;
  border: none;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-bottom-color: #CED2D6;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  z-index: 0; }

.navbar .dropdown.open .dropdown-menu {
  z-index: 1; }

.navbar .dropdown-menu:not(.yamm-dropdown-menu) li {
  background: transparent;
  border: none;
  border-top: 1px solid #E6E9ED; }

.navbar .dropdown-menu:not(.yamm-dropdown-menu) li:first-child {
  border: none; }

.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a {
  line-height: 18px;
  padding: 13px 20px;
  color: #1e4654;
  text-transform: uppercase;
  font-weight: 700;
  white-space: normal; }

.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a.active {
  filter: none;
  background-color: #F5F7FA;
  color: #1ABB9C; }

.navbar .nav .open > a,
.navbar .nav .open > a:hover,
.navbar .nav .open > a:focus {
  background-color: #F5F7FA;
  border-color: #E6E9ED; }

.navbar .nav > li > .dropdown-menu:not(.yamm-dropdown-menu):after {
  display: none; }

.navbar .nav > li > .dropdown-menu:not(.yamm-dropdown-menu):before {
  display: none; }

.navbar .dropdown-submenu .dropdown-menu {
  top: -1px !important; }

.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:focus,
.navbar .dropdown-submenu:hover > a,
.navbar .dropdown-submenu:focus > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:focus {
  background: #1ABB9C;
  color: #FFF !important;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); }

.navbar-header .info {
  padding-right: 0;
  margin: 0; }

.navbar-header .info li {
  font-size: 16px;
  padding-left: 25px;
  font-weight: bold; }

.navbar-header .info li a {
  font-size: 16px;
  color: #1e4654; }

.navbar-header .info a:hover {
  color: #1ABB9C; }

.navbar-header .social {
  margin-right: auto; }

.navbar-form {
  padding: 0;
  margin: 0; }

.btn.responsive-menu {
  display: none;
  width: 40px;
  height: 40px;
  font-size: 15px;
  padding: 0;
  margin: 0; }

/*===================================================================================*/
/*	MEGA MENU
/*===================================================================================*/
.yamm .nav,
.yamm .collapse,
.yamm .dropdown.yamm-fullwidth {
  position: static; }

.yamm .navbar-inner,
.yamm .container {
  position: relative; }

.yamm .dropdown-menu {
  top: auto;
  right: auto;
  -webkit-border-top-right-radius: 3px !important;
  -moz-border-radius-top-right: 3px !important;
  border-top-right-radius: 3px !important; }

.yamm .dropdown-menu > li {
  display: block; }

.yamm .nav.float-right .dropdown-menu {
  left: 0; }

.yamm .yamm-content {
  font-size: 14px;
  line-height: 22px;
  padding: 30px 40px;
  margin-right: -20px;
  margin-left: -20px; }

.yamm .yamm-content .inner {
  padding: 0 20px; }

.yamm .yamm-content li {
  line-height: inherit; }

/*.yamm .yamm-content:before,
.yamm .yamm-content:after {
    display: table;
    content: "";
    line-height: 0;
}*/
/*.yamm .yamm-content:after {
    clear: both;
}*/
.yamm .nav > li > .dropdown-menu:after,
.yamm .nav > li > .dropdown-menu:before {
  display: none; }

.yamm .yamm-content img {
  width: 100%; }

.yamm .yamm-content a {
  color: inherit; }

.yamm .yamm-content h4 {
  text-transform: uppercase;
  margin-bottom: 20px; }

.yamm .yamm-content p {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 15px; }

.yamm .yamm-content .btn {
  margin: 10px 0; }

.yamm .yamm-content a:hover {
  color: #1ABB9C;
  opacity: 1; }

.yamm .dropdown.yamm-fullwidth .dropdown-menu {
  width: 100%;
  right: 0;
  left: 0; }

/*===================================================================================*/
/*	BUTTONS
/*===================================================================================*/
#buttons .btn {
  margin: 5px 2px; }

#buttons .btn-wrapper {
  margin: 30px 0; }

.btn {
  color: #FFF !important;
  background: #1ABB9C;
  padding: 11px 20px 13px;
  margin: 15px 0;
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  border: none;
  text-transform: uppercase;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15); }

.btn-small {
  padding: 5px 13px 6px;
  font-size: 13px; }

.btn-large {
  padding: 14px 26px 16px;
  font-size: 15px; }

.btn.btn-green {
  background: #1ABB9C; }

.btn.btn-blue {
  background: #3F8DBF; }

.btn.btn-red {
  background: #FA6C65; }

.btn.btn-pink {
  background: #D487BE; }

.btn.btn-purple {
  background: #9B59B6; }

.btn.btn-orange {
  background: #F27A24; }

.btn.btn-navy {
  background: #34495E; }

.btn.btn-gray {
  background: #95A5A6; }

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  background: #17A78B;
  color: #FFF !important;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15); }

.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active {
  background: #17A78B !important; }

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active {
  background: #387EAA !important; }

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active {
  background: #CF4C45 !important; }

.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active {
  background: #BD78A9 !important; }

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active {
  background: #8A4FA2 !important; }

.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active {
  background: #D96716 !important; }

.btn-navy:hover,
.btn-navy:focus,
.btn-navy:active,
.btn-navy.active {
  background: #2C3E50 !important; }

.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active {
  background: #859394 !important; }

.tint-bg .btn {
  background: #FFF !important;
  color: #1ABB9C !important; }

.tint-bg .btn:hover {
  background: #E9E9E9 !important;
  color: #0c1e23 !important; }

.btn.share-facebook {
  background: #3d5b9b !important; }

.btn.share-facebook:hover {
  background: #334c8e !important; }

.btn.share-twitter {
  background: #5aa8cd !important; }

.btn.share-twitter:hover {
  background: #499ac8 !important; }

.btn.share-googleplus {
  background: #3b3b3b !important; }

.btn.share-googleplus:hover {
  background: #2e2e2e !important; }

.btn.share-pinterest {
  background: #c53942 !important; }

.btn.share-pinterest:hover {
  background: #bc2d32 !important; }

.btn-share-lg,
.btn-share-md,
.btn-share-sm,
.btn-share-xs {
  /*display: table;*/
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
  width: 100%;
  text-align: center; }

.btn-share-lg i,
.btn-share-md i,
.btn-share-sm i,
.btn-share-xs i {
  /*display: table-cell;
	vertical-align: middle;*/
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: auto;
  margin-left: auto; }

.btn-share-lg {
  height: 250px; }

.btn-share-lg i {
  font-size: 80px; }

.btn-share-md {
  height: 180px; }

.btn-share-md i {
  font-size: 60px; }

.btn-share-sm {
  height: 120px; }

.btn-share-sm i {
  font-size: 40px; }

.btn-share-xs {
  height: 80px; }

.btn-share-xs i {
  font-size: 28px; }

.btn-share-lg i:after,
.btn-share-md i:after,
.btn-share-sm i:after,
.btn-share-xs i:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

/*.btn-share-lg:hover i:after,
.btn-share-md:hover i:after,
.btn-share-sm:hover i:after,
.btn-share-xs:hover i:after {
	height: 100%;
}*/
.btn-share-lg:hover .icon-s-facebook:after,
.btn-share-md:hover .icon-s-facebook:after,
.btn-share-sm:hover .icon-s-facebook:after,
.btn-share-xs:hover .icon-s-facebook:after {
  background: #3B5998;
  background: #4668B3; }

.btn-share-lg:hover .icon-s-gplus:after,
.btn-share-md:hover .icon-s-gplus:after,
.btn-share-sm:hover .icon-s-gplus:after,
.btn-share-xs:hover .icon-s-gplus:after {
  background: #DD4B39;
  background: #DE5745; }

.btn-share-lg:hover .icon-s-twitter:after,
.btn-share-md:hover .icon-s-twitter:after,
.btn-share-sm:hover .icon-s-twitter:after,
.btn-share-xs:hover .icon-s-twitter:after {
  background: #00ACED;
  background: #2FBBED; }

.btn-share-lg p,
.btn-share-md p,
.btn-share-sm p,
.btn-share-xs p {
  position: absolute;
  height: 1.1em;
  font-family: 'Rubik', sans-serif;
  line-height: 100%;
  color: #FFF;
  margin: auto;
  opacity: 0; }

.btn-share-lg p,
.btn-share-md p {
  top: 20px; }

.btn-share-sm p,
.btn-share-xs p {
  top: 0;
  bottom: 0; }

.btn-share-sm p {
  font-size: 16px; }

.btn-share-xs p {
  font-size: 15px; }

.btn-share-lg:hover p,
.btn-share-md:hover p,
.btn-share-sm:hover p,
.btn-share-xs:hover p {
  opacity: 1; }

.btn-share-lg .name,
.btn-share-md .name,
.btn-share-sm .name,
.btn-share-xs .name {
  right: 25px; }

.btn-share-lg .counter,
.btn-share-md .counter,
.btn-share-sm .counter,
.btn-share-xs .counter {
  left: 25px; }

.btn-share-lg:hover,
.btn-share-md:hover,
.btn-share-sm:hover,
.btn-share-xs:hover {
  color: #FFF; }

h1 .btn,
h2 .btn,
h3 .btn,
h4 .btn,
p .btn {
  margin-right: 15px; }

.txt-btn {
  font-size: 105%;
  font-weight: 700; }

.txt-btn:after {
  font-family: 'fontello';
  font-size: 75%;
  content: '\e846';
  margin-right: 7px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.txt-btn:hover:after {
  margin-right: 12px; }

/*===================================================================================*/
/*	SOCIAL ICONS
/*===================================================================================*/
.social {
  padding: 0;
  margin: 0; }

.social li {
  font-family: 'fontello-social';
  margin-left: 4px; }

.social li {
  position: relative; }

.text-center .social li {
  margin: 0 2px; }

.social li a {
  display: table;
  position: relative; }

.social li a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #1e4654;
  width: 30px;
  height: 30px;
  font-size: 12px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.social li a i:after {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  right: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.social li a:hover i {
  color: #FFF; }

.social li a:hover i:after {
  height: 100%; }

.social a .icon-s-facebook:after {
  background: #3B5998;
  background: #4668B3; }

.social a .icon-s-gplus:after {
  background: #DD4B39;
  background: #DE5745; }

.social a .icon-s-twitter:after {
  background: #00ACED;
  background: #2FBBED; }

.social a .icon-s-pinterest:after {
  background: #CB2027;
  background: #D94348; }

.social a .icon-s-behance:after {
  background: #1769FF;
  background: #3079FF; }

.social a .icon-s-dribbble:after {
  background: #EA4C89;
  background: #EB6397; }

/*===================================================================================*/
/*	TABS ON SIDE
/*===================================================================================*/
.tabs-side .etabs {
  float: right;
  width: 20%;
  padding: 0;
  margin: 0 0 0 -1px; }

.tabs-side .tab {
  display: block;
  position: relative;
  z-index: 0; }

.tabs-side .tab a {
  display: block;
  background: #F5F7FA;
  color: #0c1e23;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px;
  border: 1px solid #E6E9ED;
  border-left-color: #CED7E0;
  border-bottom: none;
  overflow: hidden; }

.tabs-side .tab:first-child a {
  border-top-right-radius: 3px; }

.tabs-side .tab:last-child a {
  border-bottom: 1px solid #E6E9ED;
  border-bottom-right-radius: 3px; }

.tabs-side .tab a:hover {
  background: #1ABB9C;
  color: #FFF;
  border-color: #1ABB9C; }

.tabs-side .tab:hover,
.tabs-side .tab.active {
  z-index: 2; }

.tabs-side .tab.active a {
  background: #FFF !important;
  color: #1ABB9C;
  border-color: #CED7E0 !important;
  border-left-color: #FFF !important; }

.tabs-side .tab.active + .tab a {
  border-top-color: #CED7E0; }

.tabs-side .tab.active + .tab a:hover {
  border-top-color: #1ABB9C; }

.tabs-side .tab a div {
  font-family: 'Rubik', sans-serif;
  font-size: 40px;
  line-height: 100%;
  font-weight: 200;
  margin: -10px 0 5px; }

.tabs-side.tab-container .panel-container {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-right: 70px;
  border-right: 1px solid #CED7E0; }

.tabs-side.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px; }

/*===================================================================================*/
/*	TABS ON TOP
/*===================================================================================*/
.tabs-top .etabs {
  padding: 0;
  margin: 0; }

.tabs-top .etabs:after {
  content: '';
  display: block;
  position: relative;
  z-index: 1;
  border-top: 1px solid #CED7E0;
  margin-top: -1px; }

.tabs-top .tab {
  display: inline-block;
  position: relative;
  z-index: 0;
  max-width: 40%;
  margin: 0 3px; }

.tabs-top .tab a {
  display: inline-block;
  vertical-align: bottom;
  background: #F5F7FA;
  color: #0c1e23;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px;
  border: 1px solid #E6E9ED;
  border-bottom: none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  overflow: hidden; }

.tabs-top .tab a div {
  font-family: 'Rubik', sans-serif;
  font-size: 40px;
  line-height: 100%;
  font-weight: 200;
  margin: -10px 0 5px; }

.tabs-top .tab a:hover {
  background: #1ABB9C;
  color: #FFF;
  border-color: #1ABB9C; }

.tabs-top .tab.active {
  z-index: 2; }

.tabs-top .tab.active a {
  background: #FFF !important;
  color: #1ABB9C;
  border-color: #CED7E0 !important; }

.tabs-top.tab-container .panel-container {
  margin-top: 70px; }

.tabs-top.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px; }

/*===================================================================================*/
/*	TABS CIRCLED ON TOP
/*===================================================================================*/
.tabs-circle-top .etabs {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2; }

.tabs-circle-top .etabs:before {
  content: '';
  display: block;
  border-bottom: 1px solid #CED7E0;
  margin: 65px 0 -65px; }

.tabs-circle-top .tab {
  display: inline-block;
  height: 160px;
  position: relative;
  top: 0;
  margin: 0 15px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.tabs-circle-top .tab a {
  display: table-cell;
  vertical-align: middle;
  width: 130px;
  height: 130px;
  max-width: 130px;
  background: #FFF;
  color: #0c1e23;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px;
  border: 1px solid #CED7E0;
  border-radius: 50%;
  overflow: hidden; }

.tabs-circle-top .tab a div {
  font-family: 'Rubik', sans-serif;
  font-size: 40px;
  line-height: 100%;
  font-weight: 200;
  margin: -10px 0 5px; }

.tabs-circle-top .tab a:hover {
  /*border: 4px solid #1ABB9C;*/
  background-color: #F5F7FA;
  border-color: #CED7E0 !important; }

.tabs-circle-top .tab.active {
  top: -15px; }

.tabs-circle-top .tab.active a {
  height: 160px;
  width: 160px;
  max-width: 160px;
  background: #1ABB9C;
  font-size: 15px;
  line-height: 140%;
  color: #FFF;
  border: 1px solid #1ABB9C; }

.tabs-circle-top .tab.active a:hover {
  border-color: transparent !important; }

.tabs-circle-top .tab.active a div {
  font-size: 50px;
  margin: -13px 0 8px; }

.tabs-circle-top.tab-container .panel-container {
  position: relative;
  margin-top: 30px;
  z-index: 1; }

.tabs-circle-top.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px; }

/*===================================================================================*/
/*	TABS 2-BIG ON TOP
/*===================================================================================*/
.tabs-2-big-top .etabs {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2; }

.tabs-2-big-top .tab {
  position: relative;
  display: inline-block;
  width: 40%;
  border-bottom: 2px solid transparent;
  border-radius: 3px;
  margin: 0 15px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.tabs-2-big-top .tab:before, .tabs-2-big-top .tab:after {
  content: '';
  position: absolute;
  top: 100%;
  right: 50%;
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.tabs-2-big-top .tab:before {
  border-width: 23px;
  margin-right: -23px; }

.tabs-2-big-top .tab:after {
  border-width: 20px;
  margin-right: -20px; }

.tabs-2-big-top .tab a {
  display: block;
  padding: 35px;
  color: #0c1e23;
  opacity: .5; }

.tabs-2-big-top .tab:hover {
  background: #F7F9FA;
  border-color: #E6EBF0; }

.tabs-2-big-top .tab:hover:before {
  border-top-color: #E6EBF0; }

.tabs-2-big-top .tab:hover:after {
  border-top-color: #F7F9FA; }

.tabs-2-big-top .tab.active {
  background: #FFF;
  border-color: #E1E7ED; }

.tabs-2-big-top .tab.active:before {
  border-top-color: #E1E7ED; }

.tabs-2-big-top .tab.active:after {
  border-top-color: #FFF; }

.tabs-2-big-top .tab:hover a,
.tabs-2-big-top .tab.active a {
  opacity: 1; }

.tabs-2-big-top .tab a p {
  margin-bottom: 0; }

.tabs-2-big-top.tab-container .panel-container {
  position: relative;
  margin-top: 80px;
  z-index: 1; }

.tabs-2-big-top.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px; }

/*===================================================================================*/
/*	ACCORDION / TOGGLE
/*===================================================================================*/
.panel-group {
  margin-bottom: 0; }

.panel-group + .panel-group {
  margin-top: 20px; }

.panel-group .panel {
  background: #F5F7FA;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-radius: 3px;
  box-shadow: none; }

.panel-group .panel + .panel {
  margin-top: 10px; }

.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #E6E9ED; }

.panel-body {
  padding: 25px; }

.panel-heading {
  background-color: inherit !important;
  color: inherit !important;
  padding: 0;
  border-color: inherit !important; }

.panel-title {
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  line-height: 1.42857;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0; }

.panel-title > a {
  display: block;
  padding: 15px 25px 15px; }

.panel-title > a.collapsed {
  background: #F5F7FA;
  color: #0c1e23; }

.panel-title > a,
.panel-title > a:hover {
  background: #EDF0F2; }

.panel-title > a {
  color: #0c1e23; }

.panel-title > a:hover {
  color: #1ABB9C; }

.panel-title > a span {
  display: block;
  position: relative;
  top: 0;
  color: #0c1e23;
  padding-left: 25px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.text-right .panel-title > a span {
  padding-right: 25px;
  padding-left: 0; }

.text-center .panel-title > a span {
  padding-right: 0;
  padding-left: 0; }

.panel-title > a:hover span {
  color: inherit; }

.text-center .panel-title > a:hover span {
  top: .5em;
  color: transparent; }

.panel-title > a span:after {
  font-family: "fontello";
  content: "\e849";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.text-right .panel-title > a span:after {
  right: 0;
  left: auto; }

.text-center .panel-title > a span:after {
  top: -.75em;
  right: 0;
  opacity: 0; }

.panel-title > a.collapsed span:after {
  content: "\e84c"; }

.text-center .panel-title > a:hover span:after {
  color: #1ABB9C;
  top: -.5em;
  opacity: 1; }

.panel-group .bordered {
  background: #FFF; }

.light-bg .panel-group .panel {
  background: #FFF; }

.light-bg .panel-title > a.collapsed,
.light-bg .blank .panel-title > a.collapsed {
  background: #F8FAFD; }

.light-bg .panel-title > a,
.light-bg .blank .panel-title > a,
.light-bg .panel-title > a:hover,
.light-bg .blank .panel-title > a:hover {
  /*background: #E6E9ED;*/
  background: #FFF; }

.dark-bg .panel-group .panel,
.dark-bg .panel-group .panel-heading + .panel-collapse .panel-body {
  border-color: #485867; }

.dark-bg .panel-group .panel {
  background: #3A4A59; }

.dark-bg .panel-title > a.collapsed {
  background: #566473; }

.dark-bg .panel-title > a,
.dark-bg .panel-title > a:hover {
  /*background: #E6E9ED;*/
  background: #485867; }

.dark-bg .panel-title > a,
.dark-bg .panel-title > a span {
  color: #FFF; }

.dark-bg .blank .panel-title > a.collapsed {
  background: #071215; }

.dark-bg .blank .panel-title > a.collapsed,
.dark-bg .blank .panel-title > a {
  border-color: #566473; }

.dark-bg .blank .panel-title > a,
.dark-bg .blank .panel-title > a:hover {
  background: #485867; }

.blank .panel-title > a.collapsed {
  background: #FFF; }

.panel-group.blank .panel {
  background: none; }

.panel-group.blank .panel {
  border: none; }

.blank .panel-title > a.collapsed,
.blank .panel-title > a {
  border: 1px solid #E6E9ED; }

.blank .panel-title > a,
.blank .panel-title > a:hover {
  background: #F5F7FA; }

.blank .panel-heading,
.blank .panel-title > a {
  border-radius: 3px; }

.panel-group.blank .panel-heading + .panel-collapse .panel-body {
  border: none; }

.blank .panel-body {
  padding: 35px 0; }

.blank.panel-group .bordered,
.light-bg .panel-group .bordered,
.dark-bg .panel-group .bordered {
  background: #F5F7FA; }

.light-bg .blank.panel-group .bordered {
  background: #FFF; }

/*===================================================================================*/
/*	PORTFOLIO
/*===================================================================================*/
.items {
  list-style: none;
  margin: 0;
  padding: 0; }

.items:before,
.items:after {
  display: table;
  content: "";
  line-height: 0; }

.items:after {
  clear: both; }

.items li {
  float: right;
  min-height: 1px;
  position: relative; }

.items.col-3-custom {
  margin-right: -8px;
  margin-bottom: -8px; }

.items.col-3-custom li {
  width: 314px;
  margin-right: 8px;
  margin-bottom: 8px; }

.items.col-3-custom.gap {
  margin-right: -20px;
  margin-bottom: -20px; }

.items.col-3-custom.gap li {
  width: 306px;
  margin-right: 20px;
  margin-bottom: 20px; }

.items.col-4-custom {
  margin-right: -10px;
  margin-bottom: -10px; }

.items.col-4-custom li {
  width: 232px;
  margin-right: 10px;
  margin-bottom: 10px; }

.items.col-4-custom .post-title {
  font-size: 16px;
  margin-bottom: 3px; }

.items.fullscreen {
  width: 100%;
  clear: both;
  margin: 0 0 -1px 0;
  padding: 0;
  list-style: none; }

.items.fullscreen li {
  float: right;
  position: relative;
  background: none;
  padding: 0;
  width: 16.666666666%; }

.items.fullscreen li img {
  display: block;
  /*position: relative;*/
  width: 100%;
  height: auto; }

.items.fullscreen:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.items li img {
  display: block;
  width: 100%; }

.items li,
.items li img,
.thumbs .thumb figure,
.owl-carousel .item figure {
  display: block;
  position: relative; }

.items li {
  overflow: hidden; }

.items li .text-overlay,
.thumbs .thumb .text-overlay,
.owl-carousel .item .text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 10%;
  font: 0px/0 a;
  /* remove the gap between inline(-block) elements */
  background-color: rgba(26, 187, 156, 0.9);
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0; }

.items li .text-overlay:before,
.thumbs .thumb .text-overlay:before,
.owl-carousel .item .text-overlay:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%; }

.items li:hover .text-overlay,
.thumbs .thumb:hover .text-overlay,
.owl-carousel .item:hover .text-overlay {
  opacity: 1; }

.thumbs .thumb:hover .member .text-overlay {
  opacity: 0; }

.thumbs .thumb .member .member-image:hover .text-overlay {
  opacity: 1; }

.items li .text-overlay .info,
.thumbs .thumb .text-overlay .info,
.owl-carousel .item .text-overlay .info {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: auto;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 22px;
  /*color: #FFF;*/
  text-align: center; }

.items li .text-overlay .info h2,
.thumbs .thumb .text-overlay .info h2,
.owl-carousel .item .text-overlay .info h2,
.items li .text-overlay .info h3,
.thumbs .thumb .text-overlay .info h3,
.owl-carousel .item .text-overlay .info h3,
.items li .text-overlay .info h4,
.thumbs .thumb .text-overlay .info h4,
.owl-carousel .item .text-overlay .info h4 {
  color: #FFF;
  margin-bottom: 2px; }

.items li .text-overlay .info p,
.thumbs .thumb .text-overlay .info p,
.owl-carousel .item .text-overlay .info p {
  font-size: 14px;
  line-height: 22px;
  color: #FFF;
  margin-bottom: 0; }

.items li .text-overlay .info.big p,
.thumbs .thumb .text-overlay .info.big p,
.owl-carousel .item .text-overlay .info.big p,
#hero .items li .text-overlay .info.big p,
#hero .thumbs .thumb .text-overlay .info.big p,
#hero .owl-carousel .item .text-overlay .info.big p {
  font-size: 18px;
  line-height: 140%;
  padding-top: 10px;
  margin-top: 0; }

#owl-clients .item .text-overlay,
#clients .thumb .text-overlay {
  background: none; }

#owl-clients .item img,
#clients .thumb img {
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

#owl-clients .item:hover img,
#clients .thumb:hover img {
  opacity: .1; }

#owl-clients .item .text-overlay .info h4,
#owl-clients .item .text-overlay .info p,
#clients .thumb .text-overlay .info h4,
#clients .thumb .text-overlay .info p {
  color: inherit; }

.filter,
.format-filter {
  padding: 0;
  margin-bottom: 40px; }

.filter:before,
.format-filter:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  border-bottom: 1px solid #CED7E0; }

.filter li,
.format-filter li {
  display: inline;
  position: relative;
  background: #FFF;
  font-size: 15px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: .031em;
  text-transform: uppercase;
  margin: 0 -2px; }

.light-bg .format-filter li {
  background: #F2F5F7; }

.filter li:after,
.format-filter li:after {
  content: "\00b7"; }

.filter li:last-child:after,
.format-filter li:last-child:after {
  content: ""; }

.filter li a,
.format-filter li a {
  padding: 0 15px; }

.light-bg .filter:before {
  border-color: #BCC7D1; }

.light-bg .filter li {
  background: #F2F5F7; }

.dark-bg .filter:before {
  border-color: #1e4654; }

.dark-bg .filter li {
  background: #071215; }

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 600ms;
  -moz-transition-duration: 600ms;
  -ms-transition-duration: 600ms;
  -o-transition-duration: 600ms;
  transition-duration: 600ms; }

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width; }

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: top, right, opacity;
  transition-property: transform, opacity; }

.isotope figure,
.items figure {
  margin: 0; }

.post-content .isotope figure {
  margin: auto; }

/*===================================================================================*/
/*	OVERLAY
/*===================================================================================*/
.icon-overlay {
  display: block;
  position: relative; }

.icon-overlay img {
  display: block;
  max-width: 100%; }

.icon-overlay a .icn-more {
  opacity: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  width: 100%;
  z-index: 100;
  background-color: rgba(26, 187, 156, 0.9);
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  overflow: hidden; }

.icon-overlay a:hover .icn-more {
  opacity: 1; }

.icon-overlay a .icn-more:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  position: absolute;
  text-align: center;
  content: '\2b';
  width: 60px;
  height: 60px;
  font-size: 60px;
  line-height: 1;
  color: #FFF;
  top: 50%;
  right: 50%;
  z-index: 2;
  margin: -30px -30px 0 0;
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.icon-overlay a:hover .icn-more:before {
  font-size: 30px;
  padding-top: 15px; }

.icon-overlay.icn-link a .icn-more:before {
  content: '\2b'; }

/*===================================================================================*/
/*	OWL CAROUSEL
/*===================================================================================*/
.owl-carousel {
  text-align: center;
  cursor: default; }

.owl-controls {
  display: inline-block;
  position: relative;
  margin-top: 40px; }

.panel-group .panel .owl-controls {
  margin-top: 25px; }

.panel-group.blank .panel .owl-controls {
  margin-top: 40px; }

.owl-pagination {
  position: relative;
  line-height: 30px; }

.owl-buttons {
  display: none; }

.owl-prev,
.owl-next {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: #FFF;
  background-color: #0c1e23;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: auto;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0; }

.owl-prev {
  right: -35px; }

.owl-next {
  left: -35px; }

.owl-carousel:hover .owl-prev {
  right: -40px;
  opacity: .25; }

.owl-carousel:hover .owl-next {
  left: -40px;
  opacity: .25; }

.owl-carousel:hover .owl-prev:hover,
.owl-carousel:hover .owl-next:hover {
  color: #1ABB9C;
  opacity: 1; }

.owl-outer-nav:after {
  content: "";
  position: absolute;
  top: 0;
  right: -15%;
  width: 130%;
  height: 100%;
  z-index: 0; }

.owl-outer-nav .owl-wrapper-outer,
.owl-outer-nav .owl-pagination,
.owl-outer-nav .owl-prev,
.owl-outer-nav .owl-next {
  z-index: 1; }

.owl-outer-nav .owl-controls {
  position: static; }

.owl-outer-nav .owl-prev {
  right: -65px; }

.owl-outer-nav .owl-next {
  left: -65px; }

.owl-outer-nav:hover .owl-prev {
  right: -80px; }

.owl-outer-nav:hover .owl-next {
  left: -80px; }

.owl-ui-md .owl-pagination {
  line-height: 45px; }

.owl-ui-md .owl-prev,
.owl-ui-md .owl-next {
  width: 45px;
  height: 45px;
  font-size: 45px; }

.owl-ui-md .owl-prev {
  right: -55px; }

.owl-ui-md .owl-next {
  left: -55px; }

.owl-ui-md:hover .owl-prev {
  right: -60px; }

.owl-ui-md:hover .owl-next {
  left: -60px; }

.owl-outer-nav.owl-ui-md:after {
  right: -18%;
  width: 136%; }

.owl-outer-nav.owl-ui-md .owl-prev {
  right: -85px; }

.owl-outer-nav.owl-ui-md .owl-next {
  left: -85px; }

.owl-outer-nav.owl-ui-md:hover .owl-prev {
  right: -100px; }

.owl-outer-nav.owl-ui-md:hover .owl-next {
  left: -100px; }

.owl-ui-lg .owl-pagination {
  line-height: 60px; }

.owl-ui-lg .owl-prev,
.owl-ui-lg .owl-next {
  width: 60px;
  height: 60px;
  font-size: 60px; }

.owl-ui-lg .owl-prev {
  right: -75px; }

.owl-ui-lg .owl-next {
  left: -75px; }

.owl-ui-lg:hover .owl-prev {
  right: -80px; }

.owl-ui-lg:hover .owl-next {
  left: -80px; }

.owl-outer-nav.owl-ui-lg:after {
  right: -22%;
  width: 144%; }

.owl-outer-nav.owl-ui-lg .owl-prev {
  right: -105px; }

.owl-outer-nav.owl-ui-lg .owl-next {
  left: -105px; }

.owl-outer-nav.owl-ui-lg:hover .owl-prev {
  right: -120px; }

.owl-outer-nav.owl-ui-lg:hover .owl-next {
  left: -120px; }

.owl-inner-nav .owl-controls {
  position: static; }

.owl-inner-nav .owl-prev {
  right: 45px; }

.owl-inner-nav .owl-next {
  left: 45px; }

.owl-inner-nav:hover .owl-prev {
  right: 30px; }

.owl-inner-nav:hover .owl-next {
  left: 30px; }

.owl-outer-nav .owl-prev,
.owl-outer-nav .owl-next,
.owl-inner-nav .owl-prev,
.owl-inner-nav .owl-next {
  bottom: 70px; }

.owl-outer-nav.owl-ui-md .owl-prev,
.owl-outer-nav.owl-ui-md .owl-next,
.owl-inner-nav.owl-ui-md .owl-prev,
.owl-inner-nav.owl-ui-md .owl-next {
  bottom: 85px; }

.owl-outer-nav.owl-ui-lg .owl-prev,
.owl-outer-nav.owl-ui-lg .owl-next,
.owl-inner-nav.owl-ui-lg .owl-prev,
.owl-inner-nav.owl-ui-lg .owl-next {
  bottom: 100px; }

.owl-inner-pagination .owl-pagination,
.owl-inner-pagination .owl-prev,
.owl-inner-pagination .owl-next {
  margin-top: -40px;
  top: -60px; }

.owl-inner-pagination.owl-ui-md .owl-pagination,
.owl-inner-pagination.owl-ui-md .owl-prev,
.owl-inner-pagination.owl-ui-md .owl-next {
  margin-top: -50px;
  top: -65px; }

.owl-inner-pagination.owl-ui-lg .owl-pagination,
.owl-inner-pagination.owl-ui-lg .owl-prev,
.owl-inner-pagination.owl-ui-lg .owl-next {
  margin-top: -60px;
  top: -75px; }

.owl-inner-pagination.owl-outer-nav .owl-prev,
.owl-inner-pagination.owl-outer-nav .owl-next,
.owl-inner-pagination.owl-inner-nav .owl-prev,
.owl-inner-pagination.owl-inner-nav .owl-next {
  margin: auto;
  top: 0;
  bottom: 43px; }

.owl-inner-pagination .owl-pagination {
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0; }

.owl-inner-pagination:hover .owl-pagination {
  opacity: 1; }

.owl-inner-pagination.owl-inner-nav .owl-pagination,
.owl-inner-pagination.owl-outer-nav .owl-pagination {
  top: -45px; }

.owl-inner-pagination.owl-inner-nav.owl-ui-md .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-md .owl-pagination {
  top: -50px; }

.owl-inner-pagination.owl-inner-nav.owl-ui-lg .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-lg .owl-pagination {
  top: -60px; }

.owl-inner-pagination.owl-inner-nav:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav:hover .owl-pagination {
  top: -60px; }

.owl-inner-pagination.owl-inner-nav.owl-ui-md:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-md:hover .owl-pagination {
  top: -65px; }

.owl-inner-pagination.owl-inner-nav.owl-ui-lg:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-lg:hover .owl-pagination {
  top: -75px; }

#owl-main .item {
  height: 44vh;
  background-color: #FFF;
  background-position: center 55%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

#owl-main.height-md .item {
  height: 61vh; }

#owl-main.height-lg .item {
  height: 90vh; }

#owl-main .container {
  display: table;
  height: inherit; }

#owl-main .caption {
  display: table-cell; }

#owl-main .caption.vertical-center {
  vertical-align: middle;
  padding-bottom: 3vh; }

#owl-main .caption.vertical-top {
  vertical-align: top;
  padding-top: 8vh; }

#owl-main .caption.vertical-bottom {
  vertical-align: bottom;
  padding-bottom: 14vh; }

#owl-main .caption.text-center {
  padding-right: 8%;
  padding-left: 8%; }

#owl-main .caption.text-left {
  padding-left: 20%; }

#owl-main .caption.text-right {
  padding-right: 20%; }

#owl-main .caption h1,
#owl-main .caption p,
#owl-main .caption div {
  position: relative; }

#owl-main .caption h1 span {
  padding: .250em 0;
  line-height: 180%; }

#owl-main .caption .light-bg,
#owl-main .caption .medium-bg,
#owl-main .caption .dark-bg,
#owl-main .caption .tint-bg {
  background: none;
  padding-right: .500em;
  padding-left: .500em; }

#owl-main .caption h1.light-bg span,
#owl-main .caption p.light-bg span {
  background: #FFF;
  box-shadow: 0.5em 0 0 #FFF, -0.5em 0 0 #FFF;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; }

#owl-main .caption h1.medium-bg span,
#owl-main .caption p.medium-bg span {
  background: #A3B1BF;
  box-shadow: 0.5em 0 0 #A3B1BF, -0.5em 0 0 #A3B1BF;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; }

#owl-main .caption h1.dark-bg span,
#owl-main .caption p.dark-bg span {
  background: #0c1e23;
  box-shadow: 0.5em 0 0 #0c1e23, -0.5em 0 0 #0c1e23;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; }

#owl-main .caption h1.tint-bg span,
#owl-main .caption p.tint-bg span {
  background: #1ABB9C;
  box-shadow: 0.5em 0 0 #1ABB9C, -0.5em 0 0 #1ABB9C;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; }

#owl-main .owl-controls {
  display: block;
  position: static;
  margin-top: -47px; }

#owl-main .owl-pagination {
  background: #FFF;
  line-height: inherit;
  position: relative;
  bottom: -40px;
  padding: 10px;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: auto;
  opacity: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

#owl-main:hover .owl-pagination {
  bottom: -25px;
  opacity: 1; }

#owl-main .owl-prev,
#owl-main .owl-next {
  bottom: 0; }

.owl-controls .owl-page {
  display: inline-block; }

.owl-pagination .owl-page span {
  display: block;
  width: 15px;
  height: 15px;
  background: #0c1e23;
  border: none;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.owl-pagination .owl-page.active span,
.owl-pagination .owl-page:hover span {
  background: #1ABB9C; }

.owl-item-gap .item {
  margin: 0 15px; }

.owl-item-gap-sm .item {
  margin: 0 10px; }

/* preloading images */
.owl-item.loading {
  min-height: inherit;
  background: none; }

/*===================================================================================*/
/*	FORMS
/*===================================================================================*/
.forms {
  position: relative;
  padding: 0;
  width: 100%; }

.form-container .response {
  display: none; }

.forms ol {
  margin: 0;
  padding: 0; }

.forms ol li {
  line-height: auto;
  list-style: none; }

input,
textarea,
.uneditable-input,
.form-inline .form-control,
.navbar-form .form-control {
  width: 100%; }

select,
textarea,
textarea.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 25px;
  color: #1e4654;
  resize: none;
  vertical-align: middle;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #f5f5f5;
  border: 1px solid #E6E9ED;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out; }

textarea:focus,
textarea.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border: 1px solid #BCC7D1;
  box-shadow: none; }

textarea {
  height: 100%;
  padding: 10px;
  margin-bottom: 15px;
  min-height: 150px;
  resize: vertical; }

textarea.form-control {
  padding: 10px; }

.btn-submit {
  width: auto; }

.forms .btn-submit {
  margin-bottom: 0; }

.forms .error,
.forms .error:focus {
  border: 1px solid #d99898; }

.forms label {
  display: block;
  float: right;
  width: 95px;
  padding-top: 7px;
  font-size: 13px;
  clear: both; }

.forms input,
.forms textarea {
  background: #F8FAFD;
  border-radius: 3px; }

.light-bg .forms input,
.light-bg .forms textarea {
  background: #FFF; }

.form-container:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.newsletter {
  position: relative; }

.newsletter input[type="email"] {
  background: rgba(255, 255, 255, 0.15);
  padding-left: 100px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  color: #FFF !important; }

.newsletter input[type="email"]:focus {
  background: rgba(255, 255, 255, 0.2); }

.navbar .searchbox .dropdown-menu {
  width: 270px; }

.search {
  position: relative;
  margin: 15px;
  margin-bottom: 0; }

.search input[type="search"] {
  background: #FFF;
  padding-left: 40px; }

.newsletter .btn-submit,
.search .btn-submit {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: 5px;
  color: #FFF !important;
  line-height: 1; }

.navbar-form.search .btn-submit {
  width: 30px;
  height: 30px; }

footer .newsletter .btn-submit {
  width: 88px;
  height: 30px;
  font-size: 13px; }

#response ul.errors {
  margin-bottom: 25px; }

#response .btn i {
  font-size: 12px; }

.dark-bg input,
.dark-bg button,
.dark-bg select,
.dark-bg textarea {
  color: #A3B1BF; }

.form-control::-webkit-input-placeholder {
  color: #C2C7CC; }

.form-control:-moz-placeholder {
  color: #C2C7CC; }

.form-control::-moz-placeholder {
  color: #C2C7CC; }

.form-control:-ms-input-placeholder {
  color: #C2C7CC; }

.dark-bg .form-control::-webkit-input-placeholder {
  color: #828E99; }

.dark-bg .form-control:-moz-placeholder {
  color: #828E99; }

.dark-bg .form-control::-moz-placeholder {
  color: #828E99; }

.dark-bg .form-control:-ms-input-placeholder {
  color: #828E99; }

.search .form-control::-webkit-input-placeholder {
  color: #C2C7CC; }

.search .form-control:-moz-placeholder {
  color: #C2C7CC; }

.search .form-control::-moz-placeholder {
  color: #C2C7CC; }

.search .form-control:-ms-input-placeholder {
  color: #C2C7CC; }

input:focus::-webkit-input-placeholder {
  color: transparent !important; }

input:focus:-moz-placeholder {
  color: transparent !important; }

input:focus::-moz-placeholder {
  color: transparent !important; }

input:focus:-ms-input-placeholder {
  color: transparent !important; }

/*===================================================================================*/
/*	TOOLTIPS
/*===================================================================================*/
.tooltip-inner {
  color: #fff;
  background-color: #1ABB9C;
  padding: 5px 12px; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #1ABB9C; }

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #1ABB9C; }

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-right-color: #1ABB9C; }

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-left-color: #1ABB9C; }

/*===================================================================================*/
/*	GO TO TOP / SCROLL UP
/*===================================================================================*/
#scrollUp {
  bottom: 20px;
  right: 20px;
  font-size: 36px;
  line-height: 100%;
  color: #FFF;
  background-color: #0c1e23;
  padding: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  opacity: .5; }

#scrollUp:hover {
  color: #1ABB9C;
  opacity: 1; }

/*===================================================================================*/
/*	FEATURES / STYLES
/*===================================================================================*/
.font-icons {
  list-style: none;
  margin: 0;
  padding: 0; }

.font-icons li {
  display: inline-block;
  font-size: 25px;
  margin-bottom: 10px;
  padding: 0 10px; }

.alert .close {
  line-height: 28px; }

.retina-icons-code i {
  font-size: 18px; }

.retina-icons-code tr td:first-child,
.retina-icons-code tr th:first-child {
  text-align: center; }

.retina-icons-code code {
  color: #1e4654;
  background: none; }

.table-bordered {
  border: 1px solid #E6E9ED;
  border-right: none;
  background: #FFF; }

.table-bordered th,
.table-bordered td {
  border-right: 1px solid #E6E9ED; }

.table th,
.table td {
  border-top: 1px solid #E6E9ED; }

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #F5F7FA; }

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border-color: #E6E9ED; }

/*===================================================================================*/
/*	MODALS
/*===================================================================================*/
.modal {
  z-index: 9000;
  background: none; }

.modal.fade {
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out; }

.modal.fade .modal-dialog {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0; }

.modal.fade.show .modal-dialog {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/*.modal.fade .modal-dialog {
	-webkit-transform: translate(0);
	    -ms-transform: translate(0);
	        transform: translate(0);
	-webkit-transition: none;
	        transition: none;
}*/
.modal-open .modal {
  overflow: hidden; }

.modal-backdrop {
  background-color: #F2F5F7;
  z-index: 8999; }

.modal-backdrop.show {
  opacity: .95; }

.modal .container {
  width: 100%;
  max-width: 1170px;
  padding-right: 50px;
  padding-left: 50px; }

.modal-dialog {
  position: absolute;
  top: 35px;
  bottom: 35px;
  right: 35px;
  left: 35px;
  width: calc(100% - 70px);
  max-width: none;
  margin: auto; }

.modal-full {
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

.modal-lg {
  max-width: 100%;
  max-height: 100%; }

.modal-md {
  max-width: 1170px;
  max-height: 800px; }

.modal-sm {
  max-width: 750px;
  max-height: 500px; }

.modal-xs {
  max-width: 480px;
  max-height: 350px; }

.modal-content {
  width: 100%;
  /* Can use left: 0; right: 0; with position: absolute; as well */
  height: 100%;
  /* Can use top: 0; bottom: 0; with position: absolute; as well */
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  border-bottom: 2px solid #CED2D6;
  border-radius: 3px;
  -webkit-box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.modal-full .modal-content {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.modal-header {
  position: relative;
  z-index: 1;
  height: 70px;
  padding: 22px 25px;
  background-color: #FFF;
  border-bottom: 1px solid #E6E9ED; }

.modal-header .close {
  font-size: 24px;
  font-weight: 400;
  color: #0c1e23;
  text-shadow: none;
  padding: 0;
  margin: 0 auto 0 0;
  opacity: .4;
  -webkit-transition: opacity 200ms ease-out;
  -o-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out; }

.dark-bg.no-modal-header .modal-header .close,
.tint-bg.no-modal-header .modal-header .close {
  color: #FFF; }

.modal-header .close:hover,
.modal-header .close:focus {
  opacity: 1 !important; }

.modal-title {
  color: #0c1e23 !important;
  line-height: 150%;
  margin-left: 25px; }

.no-modal-header .modal-header {
  background-color: transparent;
  padding-left: 40px;
  border-bottom: none; }

.no-modal-header .modal-header .modal-title {
  display: none; }

.no-modal-footer .modal-footer {
  display: none; }

.no-modal-header .modal-body {
  top: 0; }

.no-modal-footer .modal-body {
  bottom: 0; }

.modal-body {
  position: absolute;
  top: 70px;
  bottom: 75px;
  width: 100%;
  /* Can use left: 0; right: 0; as well */
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Can use left: 0; right: 0; as well */
  height: 75px;
  background-color: #FFF;
  border-top: 1px solid #E6E9ED; }

.modal-footer .btn {
  margin: 0; }

.modal-open #scrollUp {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 0; }

.btn-modal {
  background: #F5F7FA !important;
  color: #0c1e23 !important;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  box-shadow: none !important; }

.btn-modal:hover,
.btn-modal:focus,
.btn-modal:active,
.btn-modal.active {
  background: #EDF0F2 !important;
  color: #1ABB9C !important; }

/*===================================================================================*/
/*	RESPONSIVE
/*===================================================================================*/
@media (min-width: 1200px) {
  .container {
    padding-left: 0;
    padding-right: 0; }
  .owl-buttons {
    display: block; }
  .items.col-4-custom {
    margin-right: -10px;
    margin-bottom: -10px; }
  .items.col-4-custom li {
    width: 277px;
    margin-right: 10px;
    margin-bottom: 10px; }
  .items.col-3-custom {
    margin-right: -9px;
    margin-bottom: -9px; }
  .items.col-3-custom li {
    width: 374px;
    margin-right: 9px;
    margin-bottom: 9px; }
  .items.col-3-custom.gap {
    margin-right: -21px;
    margin-bottom: -21px; }
  .items.col-3-custom.gap li {
    width: 366px;
    margin-right: 21px;
    margin-bottom: 21px; }
  .items.col-4-custom.gap {
    margin-right: -18px;
    margin-bottom: -18px; }
  .items.col-4-custom.gap li {
    width: 271px;
    margin-right: 18px;
    margin-bottom: 18px; }
  .grid-blog.col-2-custom {
    margin-right: -40px; }
  .sidebar-left .grid-blog.col-2-custom {
    margin-right: -20px; }
  .sidebar-left .pagination {
    margin-right: 20px; }
  .grid-blog.col-2-custom .post {
    width: 378px;
    margin-right: 40px;
    margin-bottom: 40px; }
  .grid-blog.col-2-custom.no-sidebar .post {
    width: 550px; }
  .grid-blog.col-3-custom {
    margin-right: -30px; }
  .grid-blog.col-3-custom .post {
    margin-right: 30px;
    margin-bottom: 30px; }
  .grid-blog.col-3-custom.no-sidebar .post {
    width: 360px; }
  .sidebar {
    padding-right: 35px !important; }
  .modal-dialog {
    top: 50px;
    bottom: 50px;
    right: 50px;
    left: 50px;
    width: calc(100% - 100px); } }

@media (min-width: 1025px) {
  .modal-open {
    margin-right: -17px;
    margin-left: -17px; }
  .modal-open .navbar-collapse.affix {
    left: 7px; }
  .navbar-collapse.collapse {
    padding-bottom: 15px; }
  .navbar .dropdown-submenu > a:after {
    display: none; }
  .navbar .dropdown-submenu > .dropdown-menu:not(.yamm-dropdown-menu) {
    margin-top: 0;
    margin-right: -10px; }
  .dropdown-submenu {
    position: relative; }
  .dropdown-submenu > .dropdown-menu:not(.yamm-dropdown-menu) {
    top: 0;
    right: 100%;
    margin-top: -6px; }
  .dropdown-submenu:hover > .dropdown-menu:not(.yamm-dropdown-menu) {
    display: block; }
  .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: left;
    width: 0;
    height: 0;
    margin-top: 5px;
    margin-left: -10px; }
  .dropdown-submenu.float-left {
    float: none; }
  .dropdown-submenu.float-left > .dropdown-menu:not(.yamm-dropdown-menu) {
    right: -100%;
    margin-right: 10px; }
  .navbar-nav .dropdown .dropdown-menu {
    visibility: hidden;
    display: block;
    transition: all 0.125s ease-out;
    opacity: 0;
    margin-top: -10px; }
  .navbar-nav .dropdown.open > .dropdown-menu,
  .navbar-nav .dropdown.open .dropdown-submenu:hover > .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    margin-right: 0; }
  .navbar-nav .dropdown-menu {
    position: absolute;
    float: right; }
  .navbar-nav .dropdown-menu-right .dropdown-menu:not(.yamm-dropdown-menu) {
    right: -100%; }
  .navbar-nav .dropdown-menu-right .dropdown-menu:not(.yamm-dropdown-menu) {
    margin-right: 10px; } }

@media (min-width: 1025px) and (max-width: 1199px) {
  /*br {
		display: none;
	}*/
  #team .inner-left {
    padding-right: 40px; }
  #team .inner-right {
    padding-left: 40px; }
  .classic-blog .post {
    width: 593px;
    padding-bottom: 50px; }
  .classic-blog .post-content > * {
    margin-right: 50px;
    margin-left: 50px; }
  .classic-blog .post-content > .post-media {
    margin-right: 0;
    margin-left: 0; }
  .classic-blog .post-content > *:first-child,
  .classic-blog .post-media + * {
    margin-top: 45px; }
  .classic-blog .post-content > .post-media:first-child,
  .classic-blog .owl-carousel.post-media + * {
    margin-top: 0; }
  .classic-blog .post-title {
    font-size: 33px; }
  .classic-blog .post-content .meta {
    padding-right: 50px;
    padding-left: 50px;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 25px; }
  .grid-blog.col-2-custom {
    margin-right: -30px; }
  .grid-blog.col-2-custom .post {
    width: 326px;
    margin-right: 30px;
    margin-bottom: 30px; }
  .grid-blog.col-2-custom .post-title {
    font-size: 28px; }
  .grid-blog.col-2-custom.no-sidebar {
    margin-right: -40px; }
  .grid-blog.col-2-custom.no-sidebar .post {
    width: 460px;
    margin-right: 40px;
    margin-bottom: 40px; }
  .grid-blog.col-3-custom.no-sidebar {
    margin-right: -25px; }
  .grid-blog.col-3-custom.no-sidebar .post {
    width: 302px;
    margin-right: 25px;
    margin-bottom: 25px; }
  .grid-blog.col-3-custom.no-sidebar .post-title {
    font-size: 24px; }
  .grid-blog.col-3-custom.no-sidebar p {
    font-size: 16px; }
  .tabs-circle-top .etabs:before {
    margin: 55px 0 -55px; }
  .tabs-circle-top .tab {
    height: 140px;
    margin: 0 10px; }
  .tabs-circle-top .tab a {
    width: 110px;
    height: 110px;
    max-width: 110px;
    font-size: 12px; }
  .tabs-circle-top .tab a div {
    font-size: 36px; }
  .tabs-circle-top .tab.active a {
    height: 140px;
    width: 140px;
    max-width: 140px;
    font-size: 14px; }
  .tabs-circle-top .tab.active a div {
    font-size: 46px; } }

@media (max-width: 1024px) {
  html {
    overflow-x: hidden; }
  .body-scroll-disabled,
  .body-scroll-disabled body {
    overflow: hidden; }
  #hero .info,
  .items li .text-overlay .info.big,
  .thumbs .thumb .text-overlay .info.big,
  .owl-carousel .item .text-overlay .info.big {
    margin-top: -35px; }
  .retina-icons-code code {
    font-size: 11.5px; }
  .tabs-side.tab-container .panel-container {
    padding-right: 50px; }
  br {
    display: none; }
  #team .inner-left {
    padding-right: 30px; }
  #team .inner-right {
    padding-left: 30px; }
  .classic-blog .post {
    width: 623px;
    padding-bottom: 50px; }
  .classic-blog .post-content > * {
    margin-right: 50px;
    margin-left: 50px; }
  .classic-blog .post-content > .post-media {
    margin-right: 0;
    margin-left: 0; }
  .classic-blog .post-content > *:first-child,
  .classic-blog .post-media + * {
    margin-top: 45px; }
  .classic-blog .post-content > .post-media:first-child,
  .classic-blog .owl-carousel.post-media + * {
    margin-top: 0; }
  .classic-blog .post-title {
    font-size: 33px; }
  .classic-blog .post-content .meta {
    padding-right: 50px;
    padding-left: 50px;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 25px; }
  .classic-blog.no-sidebar .sidemeta, .classic-blog.no-sidebar .pagination {
    right: auto; }
  .sidebar-left .grid-blog.col-2-custom,
  .sidebar-left .grid-blog.col-2-custom + .pagination {
    padding-right: 20px;
    padding-left: 0; }
  .grid-blog.col-2-custom {
    padding-left: 20px;
    margin-right: 0;
    margin-bottom: 0; }
  .grid-blog.col-2-custom .post {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px; }
  .grid-blog.col-2-custom .post-title {
    font-size: 28px; }
  .grid-blog.col-2-custom.no-sidebar,
  .grid-blog.col-3-custom.no-sidebar {
    padding-left: 0;
    margin-right: -25px; }
  .grid-blog.col-2-custom.no-sidebar .post,
  .grid-blog.col-3-custom.no-sidebar .post {
    width: 332px;
    padding-bottom: 40px;
    margin-right: 25px;
    margin-bottom: 25px; }
  .grid-blog.col-2-custom.no-sidebar .post-content > *,
  .grid-blog.col-3-custom.no-sidebar .post-content > * {
    margin-right: 40px;
    margin-left: 40px; }
  .grid-blog.col-2-custom.no-sidebar .post-content > *:first-child,
  .grid-blog.col-2-custom.no-sidebar .post-media + *,
  .grid-blog.col-3-custom.no-sidebar .post-content > *:first-child,
  .grid-blog.col-3-custom.no-sidebar .post-media + * {
    margin-top: 35px; }
  .grid-blog.col-2-custom.no-sidebar .post-content .meta,
  .grid-blog.col-3-custom.no-sidebar .post-content .meta {
    padding-right: 40px;
    padding-left: 40px;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 25px; }
  .grid-blog.col-2-custom.no-sidebar .post-title,
  .grid-blog.col-3-custom.no-sidebar .post-title {
    font-size: 24px; }
  .grid-blog.col-2-custom.no-sidebar p,
  .grid-blog.col-3-custom.no-sidebar p {
    font-size: 16px; }
  .format-filter li a {
    padding: 0 25px; }
  .blog.no-sidebar .post,
  .blog.no-sidebar #comments,
  .blog.no-sidebar .pagination,
  .blog.no-sidebar .comment-form-wrapper {
    width: 100%; }
  .classic-blog .pagination,
  .classic-blog .comment-form-wrapper {
    margin-bottom: 120px; }
  .classic-blog.no-sidebar .pagination,
  .classic-blog.no-sidebar .comment-form-wrapper {
    margin-bottom: 0; }
  [class*="col-lg"][class*="inner-left"],
  [class*="col-lg"][class*="inner-right"] {
    padding-right: 15px;
    padding-left: 15px; }
  [class*="col-lg"][class*="border-left"] {
    border-right: none !important; }
  [class*="col-lg"][class*="border-right"] {
    border-left: none !important; }
  .tabs-circle-top .etabs:before {
    margin: 50px 0 -50px; }
  .tabs-circle-top .tab {
    height: 130px;
    margin: 0 5px; }
  .tabs-circle-top .tab a {
    width: 95px;
    height: 95px;
    max-width: 95px;
    font-size: 11px; }
  .tabs-circle-top .tab a div {
    font-size: 33px; }
  .tabs-circle-top .tab.active a {
    height: 130px;
    width: 130px;
    max-width: 130px;
    font-size: 13px; }
  .tabs-circle-top .tab.active a div {
    font-size: 44px; }
  #owl-main .caption.text-center,
  #owl-main .caption.text-left,
  #owl-main .caption.text-right {
    padding-right: 0;
    padding-left: 0; }
  .navbar-header .navbar-brand {
    display: inline-block; }
  .navbar-collapse .navbar-brand {
    display: none; }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-collapse.collapse.show {
    display: block !important; }
  .navbar.affix,
  .navbar.affix-top {
    position: fixed;
    top: 0;
    width: 100%; }
  .navbar .container {
    display: block; }
  .navbar .social,
  .navbar .info {
    display: none; }
  .navbar-header {
    background: #FFF;
    padding: 25px 0; }
  .btn.responsive-menu {
    display: table; }
  .btn.responsive-menu i {
    display: table-cell;
    vertical-align: middle; }
  .navbar-collapse {
    position: static !important;
    background: #F5F7FA;
    max-height: inherit;
    padding-top: 0;
    padding-bottom: 0; }
  .navbar-collapse:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  .yamm .nav,
  .yamm .dropup,
  .yamm .dropdown,
  .yamm .collapse {
    float: none;
    position: relative; }
  .yamm .dropdown-menu {
    position: relative;
    right: inherit;
    top: inherit;
    float: none;
    width: 100%;
    /* display: block !important;*/ }
  .yamm .yamm-content {
    padding: 12px 0;
    font-size: 13px; }
  .navbar-nav {
    float: none !important;
    display: block;
    padding: 15px 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  .navbar-nav > li {
    float: none;
    display: block; }
  .navbar-nav > li + li {
    border-top: 1px solid #E6E9ED; }
  .navbar-nav > li > a {
    padding-right: 0;
    padding-left: 0;
    border: none;
    border-radius: 0; }
  .navbar-nav > li > a:hover,
  .navbar-nav > li > a:focus {
    background: none; }
  .navbar-nav .dropdown-menu {
    border: none;
    box-shadow: none; }
  .navbar-nav .dropdown-submenu .dropdown-menu {
    top: 0 !important;
    padding-right: 20px; }
  .navbar-nav .dropdown-menu > li:last-child {
    margin-bottom: 20px; }
  .navbar-nav .searchbox {
    float: right !important;
    display: block;
    width: 100%; }
  .navbar-header {
    float: right;
    width: 100%; }
  .navbar-header:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; } }

@media (max-width: 991px) {
  .yamm .yamm-content .inner {
    padding-top: 20px;
    padding-bottom: 20px; }
  .yamm-content > [class*="col"] {
    margin-bottom: 0; }
  .classic-blog .post {
    width: 630px; } }

@media (max-width: 979px) {
  .classic-blog .post {
    width: 601px; } }

@media (max-width: 969px) {
  .yamm .dropdown.yamm-fullwidth .dropdown-menu {
    width: 100%; } }

@media (min-width: 768px) and (max-width: 979px) {
  .navbar .container,
  .container {
    padding-left: 15px;
    padding-right: 15px; }
  .tabs-side .etabs {
    width: 200px; }
  .tabs-side.tab-container .panel-container {
    width: 480px; }
  .tabs-top.left .tab {
    margin-left: 2px; }
  .tabs-top.left .tab a {
    padding: 5px 10px; }
  .items.col-4-custom {
    margin-right: -9px;
    margin-bottom: -9px; }
  .items.col-4-custom li {
    width: 224px;
    margin-right: 9px;
    margin-bottom: 9px; }
  .items.col-3-custom {
    margin-right: -9px;
    margin-bottom: -9px; }
  .items.col-3-custom li {
    width: 224px;
    margin-right: 9px;
    margin-bottom: 9px; }
  .items.col-3-custom.gap {
    margin-right: -18px;
    margin-bottom: -18px; }
  .items.col-3-custom.gap li {
    width: 336px;
    margin-right: 18px;
    margin-bottom: 18px; }
  .items.col-4-custom.gap {
    margin-right: -18px;
    margin-bottom: -18px; }
  .items.col-4-custom.gap li {
    width: 336px;
    margin-right: 18px;
    margin-bottom: 18px; } }

@media (max-width: 767px) {
  /*body {
		height: 100vh;
	}*/
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: .0125em; }
  h1 {
    font-size: 26px;
    line-height: 120%;
    margin-bottom: 20px; }
  header p {
    font-size: 20px; }
  footer .inner {
    padding-top: 35px;
    padding-bottom: 25px; }
  footer .row .inner {
    padding-right: 20px;
    padding-left: 20px; }
  footer .container > .row {
    margin-right: -20px;
    margin-left: -20px; }
  .row,
  #share {
    overflow: hidden; }
  #share .container {
    max-width: 100%;
    padding: 0; }
  /*.owl-controls {
		display: inline;
	}
	.owl-pagination {
		top: 0 !important;
	}*/
  .last-bottom {
    padding-bottom: 20px !important; }
  .last-bottom + .last-bottom {
    padding-bottom: 0 !important; }
  .thumbs.gap-lg {
    margin-right: -15px; }
  .thumbs.gap-lg .thumb {
    padding: 0 15px 15px 0; }
  .icon.float-right {
    margin-right: 10px; }
  .inner {
    padding-top: 80px;
    padding-bottom: 80px; }
  .inner-md {
    padding-top: 65px;
    padding-bottom: 65px; }
  .inner-sm {
    padding-top: 50px;
    padding-bottom: 50px; }
  .inner-xs {
    padding-top: 40px;
    padding-bottom: 40px; }
  .inner-top {
    padding-top: 80px; }
  .inner-top-md {
    padding-top: 65px; }
  .inner-top-sm {
    padding-top: 50px; }
  .inner-top-xs {
    padding-top: 40px; }
  .inner-bottom {
    padding-bottom: 80px; }
  .inner-bottom-md {
    padding-bottom: 65px; }
  .inner-bottom-sm {
    padding-bottom: 50px; }
  .inner-bottom-xs {
    padding-bottom: 40px; }
  .height-xs,
  .height-sm,
  .height-md,
  .height-lg,
  #owl-main .item,
  #owl-main.height-md .item,
  #owl-main.height-lg .item {
    height: 88vh; }
  #hero h1 {
    font-size: 38px;
    line-height: 115%;
    letter-spacing: 0.031em; }
  #hero p {
    font-size: 20px;
    line-height: 135%;
    margin-top: 15px; }
  #hero #owl-main .caption h1.light-bg,
  #hero #owl-main .caption h1.medium-bg,
  #hero #owl-main .caption h1.dark-bg,
  #hero #owl-main .caption h1.tint-bg {
    font-size: 33px; }
  #hero .btn {
    margin-top: 25px; }
  #hero .btn-large {
    font-size: 16px;
    padding: 12px 30px 15px; }
  .retina-icons-code tr td:first-child,
  .retina-icons-code tr th:first-child {
    max-width: 30px; }
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    line-height: 100%;
    padding-right: 4px;
    padding-left: 4px; }
  .table > thead > tr > th {
    font-size: 11px; }
  .retina-icons-code i {
    font-size: 14px; }
  .retina-icons-code code {
    font-size: 10px; }
  .tabs-side .etabs {
    float: none;
    width: 100%; }
  .tabs-side .tab a {
    border-left-color: #E6E9ED; }
  .tabs-side .tab:first-child a {
    border-top-left-radius: 3px; }
  .tabs-side .tab:last-child a {
    border-bottom-left-radius: 3px; }
  .tabs-side .tab.active a {
    border-left-color: #CED7E0 !important; }
  .tabs-side.tab-container .panel-container {
    padding-right: 0;
    border-right: none;
    margin-top: 30px; }
  .tabs-top .etabs:after {
    display: none; }
  .tabs-top .tab {
    display: block;
    max-width: 100%;
    margin: 0; }
  .tabs-top .tab a {
    display: block;
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .tabs-top .tab:first-child a {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .tabs-top .tab:last-child a {
    border-bottom: 1px solid #E6E9ED;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .tabs-top .tab.active + .tab a {
    border-top-color: #CED7E0; }
  .tabs-top .tab.active + .tab a:hover {
    border-top-color: #1ABB9C; }
  .filter:before, .format-filter:before {
    display: none; }
  h1.single-block .btn {
    margin-right: 0; }
  .sidemeta .date-wrapper,
  .classic-blog.sidebar-left .sidemeta .date-wrapper {
    top: -80px;
    right: 50%;
    margin-right: -60px; }
  .sidemeta .format-wrapper,
  .classic-blog.sidebar-left .sidemeta .format-wrapper {
    top: -70px;
    right: 50%;
    margin-right: 10px; }
  .classic-blog .sidemeta,
  .classic-blog .pagination {
    padding-right: 0;
    border-right: none;
    margin-right: 0; }
  .classic-blog.sidebar-left .sidemeta,
  .classic-blog.sidebar-left .pagination {
    padding-left: 0;
    border-left: none;
    margin-left: 0; }
  .classic-blog.no-sidebar .sidemeta,
  .classic-blog.no-sidebar .pagination {
    right: 0; }
  .classic-blog .post {
    margin-top: 80px;
    width: 100%; }
  .grid-blog.col-2-custom {
    padding-left: 0; }
  .sidebar-left .grid-blog.col-2-custom,
  .sidebar-left .grid-blog.col-2-custom + .pagination {
    padding-right: 0; }
  .classic-blog .post,
  .grid-blog.col-2-custom .post,
  .grid-blog.col-3-custom .post,
  .grid-blog.col-2-custom.no-sidebar .post,
  .grid-blog.col-3-custom.no-sidebar .post {
    padding-bottom: 30px; }
  .classic-blog .post-content > *,
  .grid-blog.col-2-custom .post-content > *,
  .grid-blog.col-3-custom .post-content > *,
  .grid-blog.col-2-custom.no-sidebar .post-content > *,
  .grid-blog.col-3-custom.no-sidebar .post-content > * {
    margin-right: 30px;
    margin-left: 30px; }
  .classic-blog .post-content .meta,
  .grid-blog.col-2-custom .post-content .meta,
  .grid-blog.col-3-custom .post-content .meta,
  .grid-blog.col-2-custom.no-sidebar .post-content .meta,
  .grid-blog.col-3-custom.no-sidebar .post-content .meta {
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 20px; }
  .classic-blog .post-content > *:first-child,
  .classic-blog .post-media + *,
  .grid-blog.col-2-custom .post-content > *:first-child,
  .grid-blog.col-2-custom .post-media + *,
  .grid-blog.col-3-custom .post-content > *:first-child,
  .grid-blog.col-3-custom .post-media + *,
  .grid-blog.col-2-custom.no-sidebar .post-content > *:first-child,
  .grid-blog.col-2-custom.no-sidebar .post-media + *,
  .grid-blog.col-3-custom.no-sidebar .post-content > *:first-child,
  .grid-blog.col-3-custom.no-sidebar .post-media + * {
    margin-top: 30px; }
  .classic-blog .post-title,
  .grid-blog.col-2-custom .post-title,
  .grid-blog.col-3-custom .post-title,
  .grid-blog.col-2-custom.no-sidebar .post-title,
  .grid-blog.col-3-custom.no-sidebar .post-title {
    font-size: 24px; }
  .classic-blog p,
  .grid-blog.col-2-custom p,
  .grid-blog.col-3-custom p,
  .grid-blog.col-2-custom.no-sidebar p,
  .grid-blog.col-3-custom.no-sidebar p {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 10px; }
  .classic-blog .pagination,
  .classic-blog .comment-form-wrapper,
  .pagination {
    margin-bottom: 100px; }
  .grid-blog.col-2-custom.no-sidebar + .pagination,
  .grid-blog.col-3-custom.no-sidebar + .pagination {
    margin-bottom: 0; }
  .classic-blog .pagination,
  .classic-blog.sidebar-left .pagination,
  .grid-blog.col-2-custom + .pagination,
  .grid-blog.col-3-custom + .pagination,
  .grid-blog.col-2-custom.no-sidebar + .pagination,
  .grid-blog.col-3-custom.no-sidebar + .pagination {
    margin-right: -10px; }
  .pagination > li:first-child {
    margin-right: 10px; }
  .pagination li {
    margin-bottom: 10px; }
  #blog-post .post h2 {
    font-size: 19px;
    margin-bottom: 15px; }
  #blog-post .post h3 {
    font-size: 16px; }
  #blog-post .post h4 {
    font-size: 14px; }
  #blog-post .post figure figcaption p {
    font-size: 14px;
    line-height: 150%; }
  [class*="col"] > figcaption:first-child {
    padding-top: 20px; }
  .post-author, #comments {
    padding: 30px; }
  .post-author .author-image {
    float: none;
    display: inline-block;
    margin-bottom: 20px; }
  .post-author .author-details {
    padding-right: 0; }
  #comments .avatar {
    float: none;
    display: inline-block;
    margin-bottom: 15px; }
  #comments .commentbody {
    padding-right: 0; }
  #comments .commentlist .children {
    padding-right: 0; }
  [class*="col"][class*="border-left"] {
    border-right: none !important; }
  [class*="col"][class*="border-right"] {
    border-left: none !important; }
  .filter li {
    display: block; }
  .filter li + li {
    margin-top: 15px; }
  .filter li:after {
    display: none; }
  #hero .info,
  #hero .info,
  #hero .info,
  .items li .text-overlay .info.big,
  .thumbs .thumb .text-overlay .info.big,
  .owl-carousel .item .text-overlay .info.big {
    margin-top: -25px; }
  .items li .text-overlay .info h2,
  .thumbs .thumb .text-overlay .info h2,
  .owl-carousel .item .text-overlay .info h2,
  .items li .text-overlay .info h3,
  .thumbs .thumb .text-overlay .info h3,
  .owl-carousel .item .text-overlay .info h3,
  .items li .text-overlay .info h4,
  .thumbs .thumb .text-overlay .info h4,
  .owl-carousel .item .text-overlay .info h4 {
    font-size: 16px;
    line-height: 150%; }
  .items li .text-overlay .info p,
  .thumbs .thumb .text-overlay .info p,
  .owl-carousel .item .text-overlay .info p,
  .items li .text-overlay .info.big p,
  .thumbs .thumb .text-overlay .info.big p,
  .owl-carousel .item .text-overlay .info.big p,
  #hero .items li .text-overlay .info.big p,
  #hero .thumbs .thumb .text-overlay .info.big p,
  #hero .owl-carousel .item .text-overlay .info.big p {
    font-size: 14px;
    line-height: 140%;
    padding-top: 0; }
  .tabs-2-big-top .tab {
    width: 100%;
    margin: 0 0 50px 0; }
  .tabs-2-big-top .tab a {
    padding: 25px; }
  .tabs-2-big-top.tab-container .panel-container {
    margin-top: 0; }
  [class*="col"][class*="inner-left"],
  [class*="col"][class*="inner-right"],
  [class*="col"][class*="reset-padding"] {
    padding-right: 15px;
    padding-left: 15px; }
  [class*="inner-top"].reset-xs {
    padding-top: 0 !important; }
  .tabs-circle-top .etabs:before {
    border-bottom: none;
    margin: 0; }
  .tabs-circle-top .tab {
    display: table;
    width: 100%;
    height: auto;
    margin: 0; }
  .tabs-circle-top .tab.active {
    top: 0; }
  .tabs-circle-top .tab a,
  .tabs-circle-top .tab.active a {
    width: 100%;
    max-width: 100%;
    height: auto;
    font-size: 18px;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: none;
    border-radius: 0; }
  .tabs-circle-top .tab a:hover {
    border-width: 1px;
    border-color: #CED7E0 !important;
    border-top: none; }
  .tabs-circle-top .tab.active a:hover {
    border-color: transparent !important; }
  .tabs-circle-top .tab:first-child a {
    border-top: 1px solid #CED7E0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .tabs-circle-top .tab:last-child a {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .tabs-circle-top .tab a div,
  .tabs-circle-top .tab.active a div {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    line-height: 120%;
    padding-bottom: 5px;
    margin: 0 0 0 10px; }
  .yamm-content {
    margin-top: 0; }
  .yamm-content > [class*="col"] {
    margin-bottom: 0; }
  .navbar .container,
  .container {
    padding-left: 20px;
    padding-right: 20px; }
  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0; }
  .footer-bottom p.float-left,
  .footer-bottom ul.float-right {
    float: none !important;
    text-align: center; }
  .footer-bottom p {
    margin-bottom: 20px; }
  .footer-menu li {
    padding: 0 5px; }
  img {
    max-width: 100%;
    height: auto; }
  .filter li a:before {
    display: none; }
  .items {
    margin-right: 0 !important;
    /*margin-bottom: -20px !important;*/ }
  .items li {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important; }
  .items.fullscreen li {
    margin: 0 !important; }
  .sidebar {
    margin-top: 70px;
    padding-right: 15px !important; }
  .blog.no-sidebar .post,
  .blog.no-sidebar #comments,
  .blog.no-sidebar .pagination,
  .blog.no-sidebar .comment-form-wrapper {
    width: 100%; }
  .blog.no-sidebar .pagination ul {
    margin: 0; }
  .grid-blog {
    margin-right: 0 !important;
    margin-bottom: 20px !important; }
  .grid-blog .post {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important; }
  #comments ul.children {
    padding: 0 20px 0 0; }
  #comments .arrow-box {
    margin-right: 0; }
  .panel-title {
    line-height: 18px; }
  .yamm .yamm-content.row {
    margin-bottom: -20px; }
  .modal .container {
    padding-right: 35px;
    padding-left: 35px; }
  .modal-dialog {
    top: 25px;
    bottom: 25px;
    right: 25px;
    left: 25px;
    width: calc(100% - 50px); }
  .modal-open {
    background-color: #F2F5F7; } }

@media (min-width: 768px) {
  .navbar-nav > li {
    float: right; } }

@media (min-width: 768px) and (max-height: 1024px) {
  .height-xs,
  .height-sm,
  #owl-main .item {
    height: 480px; }
  #hero h1 {
    font-size: 60px;
    line-height: 115%; }
  #hero p {
    font-size: 22px;
    line-height: 135%;
    margin-top: 17px; }
  #hero #owl-main .caption h1.light-bg,
  #hero #owl-main .caption h1.medium-bg,
  #hero #owl-main .caption h1.dark-bg,
  #hero #owl-main .caption h1.tint-bg {
    font-size: 50px; }
  #hero .btn {
    margin-top: 35px; }
  #hero .btn-large {
    font-size: 16px;
    padding: 15px 35px 17px; } }

@media (min-width: 768px) and (max-height: 768px) {
  .height-xs,
  .height-sm,
  .height-md,
  .height-lg,
  #owl-main .item,
  #owl-main.height-md .item,
  #owl-main.height-lg .item {
    height: 480px; } }

@media (max-width: 480px) {
  .icon.pos-left i.icn {
    font-size: 60px; }
  .modal .container {
    padding-right: 25px;
    padding-left: 25px; }
  .modal-dialog {
    top: 15px;
    bottom: 15px;
    right: 15px;
    left: 15px;
    width: calc(100% - 30px); }
  .modal-header {
    padding: 14px 15px; }
  .modal-title {
    font-size: 16px;
    line-height: 120%; } }

a:hover,
a:focus,
.bordered .info h3 a:hover,
.bordered .info h4 a:hover,
.icon i.icn,
.meta a:hover,
.meta li.categories:hover:before,
.more:hover,
.navbar.basic .nav > li > a:hover,
.navbar.basic .nav > li > a:focus,
.navbar.basic .nav .open > a,
.navbar.basic .nav .open > a:hover,
.navbar.basic .nav .open > a:focus,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a.active,
.navbar-header .info a:hover,
.nav > li > a:hover,
.owl-carousel:hover .owl-next:hover,
.owl-carousel:hover .owl-prev:hover,
.panel-title > a:hover,
#scrollUp:hover,
.sidemeta .format-wrapper a,
.tabs-side .tab.active a,
.tabs-top .tab.active a,
.text-center .panel-title > a:hover span:after,
ul.arrowed li:before,
ul.circled li:before,
.yamm .yamm-content a:hover {
  color: #F27A24; }

.tint-bg .btn,
.tint-color {
  color: #F27A24 !important; }

.btn,
.classic-blog .post .date,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:focus,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:focus,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:hover,
.navbar .dropdown-submenu:focus > a,
.navbar .dropdown-submenu:hover > a,
.owl-pagination .owl-page.active span,
.owl-pagination .owl-page:hover span,
#owl-main .caption h1 span.tint-bg,
#owl-main .caption p span.tint-bg,
.pagination > li > a,
.pagination > li > span,
.post.format-quote,
.sidemeta .format-wrapper a:hover,
.sidenav li a:hover,
.tabs-circle-top .tab.active a,
.tabs-side .tab a:hover,
.tabs-top .tab a:hover,
.tint-bg,
.tint-bg.img-bg-soft:before,
.tint-bg.img-bg-softer:before,
.tooltip-inner {
  background-color: #F27A24; }

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > li > a:hover,
.pagination > li > a:focus {
  background-color: #D96716; }

.icon-overlay a .icn-more,
.items li .text-overlay,
.owl-carousel .item .text-overlay,
.thumbs .thumb .text-overlay {
  background-color: rgba(242, 122, 36, 0.9); }

.classic-blog .post .date,
.icon.circle,
.sidemeta .format-wrapper a,
.tabs-circle-top .tab.active a,
.tabs-circle-top .tab a:hover,
.tabs-side .tab a:hover,
.tabs-top .tab a:hover {
  border-color: #F27A24; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #F27A24; }

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #F27A24; }

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #F27A24; }

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #F27A24; }

#owl-main .caption h1 span.tint-bg,
#owl-main .caption p span.tint-bg {
  box-shadow: -0.5em 0 0 #F27A24, 0.5em 0 0 #F27A24; }

::selection {
  background: #F7CAA6;
  /* Safari */ }

::-moz-selection {
  background: #F7CAA6;
  /* Firefox */ }

.wrapper-popap-form .modal-content {
  background-color: unset;
  box-shadow: unset;
  border: 0; }

.wrapper-popap-form .modal-dialog {
  max-width: 1170px;
  max-height: 700px; }

.wrapper-popap-form .modal-body {
  padding: 0 40px; }
  @media (max-width: 1024px) {
    .wrapper-popap-form .modal-body {
      padding: 0; } }

.popap-form {
  position: relative;
  border-bottom: 2px solid #CED2D6;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1); }

.popap-form-btn-close {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #F27A24;
  z-index: 1;
  cursor: pointer; }
  @media (max-width: 768px) {
    .popap-form-btn-close {
      width: 35px;
      height: 35px;
      top: -15px;
      left: -15px; } }
  .popap-form-btn-close:hover .close {
    transform: translate(-50%, -50%) rotate(180deg); }
  .popap-form-btn-close .close {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    color: #fff !important;
    opacity: 1;
    transition: all 0.4s; }

.popap-form-image {
  margin-bottom: 40px; }
  @media (max-width: 768px) {
    .popap-form-image {
      margin-bottom: 20px; }
      .popap-form-image img {
        max-width: 55%; } }

.popap-form {
  max-width: 1020px;
  width: 100%;
  min-height: 440px;
  background: #fff;
  padding: 45px 20px 30px;
  border-radius: 3px;
  margin: 0 auto; }
  @media (max-width: 1200px) {
    .popap-form {
      width: 90%; } }
  @media (max-width: 768px) {
    .popap-form {
      padding: 20px 15px; }
      .popap-form .row {
        overflow: visible; } }
  .popap-form__title {
    font-size: 24px;
    color: #F27A24;
    line-height: 1; }
    @media (max-width: 1024px) {
      .popap-form__title {
        font-size: 20px; } }
    @media (max-width: 768px) {
      .popap-form__title {
        padding: 0 15px;
        font-size: 16px; } }
  .popap-form__sub-title {
    font-size: 30px;
    font-weight: 700;
    color: #1e4654;
    line-height: 1;
    margin-bottom: 30px; }
    @media (max-width: 1024px) {
      .popap-form__sub-title {
        font-size: 25px; } }
    @media (max-width: 768px) {
      .popap-form__sub-title {
        padding: 0 15px;
        font-size: 18px; } }

.popap-form-myform .form-group {
  position: relative;
  margin-bottom: 10px; }

.popap-form-myform__label {
  position: absolute;
  top: 0;
  right: 5px;
  transition: all 0.3s;
  font-size: 18px;
  cursor: text; }

.popap-form-myform__input {
  width: 100%;
  height: 35px !important;
  border: 0 !important;
  background-color: unset !important;
  border-bottom: 1px solid #c3c3c3 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  font-size: 18px;
  color: #000;
  transition: all 0.3s; }
  .popap-form-myform__input::placeholder {
    font-size: 0;
    color: #000;
    transition: opacity 0.4s; }
  .popap-form-myform__input:focus {
    outline: none;
    border-bottom-color: #F27A24 !important; }
    .popap-form-myform__input:focus ~ label {
      top: -20px;
      font-size: 14px;
      color: #F27A24;
      right: 5px; }
  .popap-form-myform__input.has-content {
    border-bottom-color: #F27A24 !important; }
    .popap-form-myform__input.has-content ~ label {
      top: -20px;
      font-size: 14px;
      color: #F27A24;
      right: 5px; }

.popap-form-myform__textarea {
  resize: none;
  padding-top: 10px !important;
  min-height: 95px;
  max-height: 95px; }

.popap-form-myform__send {
  width: 100%;
  background: #F27A24;
  border: 0;
  height: 39px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer; }
  .popap-form-myform__send:focus {
    outline: none; }
  .popap-form-myform__send:active {
    box-shadow: inset 2px 5px 5px rgba(0, 0, 0, 0.15); }

.popap-form-info {
  margin-bottom: 25px; }
  .popap-form-info__title {
    font-size: 18px;
    color: #000;
    line-height: 1;
    margin-bottom: 5px; }
    .popap-form-info__title_color-silver {
      color: #1e4654; }
    .popap-form-info__title .icon-star {
      margin-left: 5px;
      font-size: 14px;
      vertical-align: middle; }
    @media (max-width: 768px) {
      .popap-form-info__title {
        font-size: 16px; } }
  .popap-form-info__sub-title {
    font-size: 24px;
    font-weight: 700;
    color: #F27A24;
    line-height: 1;
    display: inline-block; }
    @media (max-width: 1024px) {
      .popap-form-info__sub-title {
        font-size: 20px; } }
    @media (max-width: 768px) {
      .popap-form-info__sub-title {
        font-size: 18px; } }
    .popap-form-info__sub-title:hover {
      color: #F27A24; }
  .popap-form-info__image {
    position: relative;
    background-repeat: no-repeat;
    display: inline-block;
    margin-top: 7px;
    margin-left: 20px; }
    @media (max-width: 768px) {
      .popap-form-info__image {
        margin-top: 0; } }
    .popap-form-info__image > i {
      font-size: 2em; }

.popap-form-col-last {
  padding-right: 45px; }
  @media (max-width: 768px) {
    .popap-form-col-last {
      padding-right: 15px; } }

.popap-form-col-first {
  padding-right: 40px; }
  @media (max-width: 768px) {
    .popap-form-col-first {
      padding-right: 15px; } }

.popap-form-social {
  display: block; }

.popap-form-social__box {
  position: relative;
  padding: 16px 0;
  display: inline-block;
  margin-left: 25px; }
  @media (max-width: 480px) {
    .popap-form-social__box {
      margin-left: 0px;
      width: 75px; } }

.aside-panel__social-title {
  color: #415667;
  font-size: 16px;
  line-height: 1; }

.popap-form-social__icon {
  margin-bottom: 8px;
  display: inline-block;
  font-size: 2em; }

.popap-form-bottom-info {
  margin-bottom: 15px; }

.global-btn-price {
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: block;
  padding: 22px 0;
  width: 100px;
  height: 100px;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  background: #F27A24;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(241, 112, 20, 0.5), inset 2px 5px 11px 0px rgba(0, 0, 0, 0.2);
  animation: pulse 1.5s infinite; }
  @media (max-width: 1024px) {
    .global-btn-price {
      width: 65px;
      height: 65px;
      padding: 15px 0;
      line-height: 1.2;
      letter-spacing: normal;
      font-size: 0.9em;
      bottom: 10px;
      left: 10px;
      animation: unset; } }

@keyframes pulse {
  0% { }
  70% {
    box-shadow: 0 0 0 30px rgba(241, 112, 20, 0), inset 2px 5px 11px 0px rgba(0, 0, 0, 0.2); }
  100% {
    box-shadow: 0 0 0 0 rgba(241, 112, 20, 0), inset 2px 5px 11px 0px rgba(0, 0, 0, 0.2); } }

.owl-carousel {
  direction: ltr; }

.owl-controls .owl-page {
  float: right; }

.txt-btn:after {
  content: "\e847"; }

.dir-rtl {
  direction: rtl !important; }

.forms .form-error,
.popap-form-myform .form-error {
  margin-bottom: 15px;
  margin-top: -15px; }

.wrapper-popap-form .modal-body {
  bottom: 0;
  top: 30px; }

.hp-parthers-slide {
  max-width: 293px;
  width: 100%;
  height: 200px; }
  @media (max-width: 768px) {
    .hp-parthers-slide {
      height: 120px; } }
  .hp-parthers-slide > figure {
    height: 100%;
    position: relative; }
    .hp-parthers-slide > figure > img {
      max-width: 90%;
      width: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

@media (max-width: 1024px) {
  .navbar-header {
    padding: 15px 0 20px 15px; } }

@media (max-width: 1024px) {
  .navbar-brand img {
    height: 50px; } }

@media (max-width: 1024px) {
  .hp-product .container .inner-top-sm {
    padding-top: 10px; } }

.navbar-phone-mobile {
  display: none; }
  @media (max-width: 1024px) {
    .navbar-phone-mobile {
      display: block; }
      .navbar-phone-mobile > i.icon-phone {
        font-size: 24px;
        margin-right: 15px;
        color: #fff; } }

@media (max-width: 1024px) {
  .btn-mobile-hamburger {
    margin-left: 15px !important; } }

/*===================================================================================*/
/*	MEDIA QUERIES | BREAKPOINTS
/*===================================================================================*/
body:before {
  content: 'xs';
  display: none; }

/*@media (min-width: 768px) {
	body:before {
		content: 'sm';
	}
}*/
@media (min-width: 1025px) {
  body:before {
    content: 'md'; } }

/*@media (min-width: 1200px) {
	body:before {
		content: 'lg';
	}
}*/
