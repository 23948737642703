.wrapper-popap-form {

  & .modal-content {
    background-color: unset;
    box-shadow: unset;
    border: 0;
  }

  & .modal-dialog {
    max-width: 1170px; //1020
    max-height: 700px;
  }

  & .modal-body {
    padding: 0 40px;
    @include respond-to('medium'){
      padding: 0;
    }
  }
}

.popap-form {
  position: relative;
  border-bottom: 2px solid #CED2D6;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}

.popap-form-btn-close {
  position: absolute;
  top: -30px;
  #{$left}: -30px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: $clr-base-orange;
  z-index: 1;
  cursor: pointer;
  @include respond-to('small'){
    width: 35px;
    height: 35px;
    top: -15px;
    #{$left}: -15px;
  }

  &:hover {
    & .close {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  & .close {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    color: #fff !important;
    opacity: 1;
    transition: all 0.4s;
  }
}

.popap-form-image {
  margin-bottom: 40px;

  @include respond-to('small') {
    margin-bottom: 20px;

    img {
      max-width: 55%;
    }
  }
}

.popap-form {
  max-width: 1020px;
  width: 100%;
  min-height: 440px;
  background: #fff;
  padding: 45px 20px 30px;
  border-radius: 3px;
  margin: 0 auto;

  @include respond-to('large') {
      width: 90%;
  }

  @include respond-to('small') {
      padding: 20px 15px;
      .row{
        overflow: visible;
      }
  }

  &__title {
    font-size: 24px;
    color: $clr-base-orange;
    line-height: 1;

    @include respond-to('medium') {
      font-size: 20px;
    }

    @include respond-to('small') {
      padding: 0 15px;
      font-size: 16px;
    }
  }

  &__sub-title {
    font-size: 30px;
    font-weight: 700;
    color: $clr-base-txt;
    line-height: 1;
    margin-bottom: 30px;

    @include respond-to('medium') {
      font-size: 25px;
    }

    @include respond-to('small') {
      padding: 0 15px;
      font-size: 18px;
    }
  }
}

.popap-form-myform {
  .form-group {
    position: relative;
    margin-bottom: 10px;
  }

  &__label {
    position: absolute;
    top: 0;
    #{$right}: 5px;
    transition: all 0.3s;
    font-size: 18px;
    cursor: text;
  }

  &__input {
    width: 100%;
    height: 35px !important;
    border: 0 !important;
    background-color: unset !important;
    border-bottom: 1px solid #c3c3c3 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    font-size: 18px;
    color: #000;
    transition: all 0.3s;

    &::placeholder {
      font-size: 0;
      color: #000;
      transition: opacity 0.4s;
    }

    &:focus {
      outline: none;
      border-bottom-color: $clr-base-orange !important;

      & ~ label {
        top: -20px;
        font-size: 14px;
        color: $clr-base-orange;
        #{$right}: 5px;
      }
    }

    &.has-content {
      border-bottom-color: $clr-base-orange !important;

      & ~ label {
        top: -20px;
        font-size: 14px;
        color: $clr-base-orange;
        #{$right}: 5px;
        
      }
    }
  }

  &__textarea {
    resize: none;
    padding-top: 10px !important;
    min-height: 95px;
    max-height: 95px;
  }

  &__send {
    width: 100%;
    background: $clr-base-orange;
    border: 0;
    height: 39px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:active {
      @include btn-effect-active;
    }
  }
}

.popap-form-info {
  margin-bottom: 25px;

  &__title {
    font-size: 18px;
    color: #000;
    line-height: 1;
    margin-bottom: 5px;

    &_color-silver {
      color: $clr-base-txt;
    }

    & .icon-star {
      margin-#{$left}: 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    @include respond-to('small') {
      font-size: 16px;
    }
  }

  &__sub-title {
    font-size: 24px;
    font-weight: 700;
    color: $clr-base-orange;
    line-height: 1;
    display: inline-block;

    @include respond-to('medium') {
      font-size: 20px;
    }

    @include respond-to('small') {
      font-size: 18px;
    }

    &:hover {
      color: $clr-base-orange;
    }
  }

  &__image {
    position: relative;
    background-repeat: no-repeat;
    display: inline-block;
    margin-top: 7px;
    margin-#{$left}: 20px;

    @include respond-to('small') {
      margin-top: 0;
    }

    &>i {
      font-size: 2em;
    }
  }
}

.popap-form-col-last {
  padding-#{$right}: 45px;

  @include respond-to('small') {
    padding-#{$right}: 15px;
  }
}

.popap-form-col-first {
  padding-#{$right}: 40px;

  @include respond-to('small') {
    padding-#{$right}: 15px;
  }
}

.popap-form-social {
  display: block;
}

.popap-form-social__box {
  position: relative;
  padding: 16px 0;
  display: inline-block;
  margin-#{$left}: 25px;
  
  @include respond-to('extra-small') {
    margin-#{$left}: 0px;
    width: 75px;
  }
}

.aside-panel__social-title {
  color: #415667;
  font-size: 16px;
  line-height: 1;
}

.popap-form-social__icon {
  margin-bottom: 8px;
  display: inline-block;
  font-size: 2em;

  
}

.popap-form-bottom-info{
  margin-bottom: 15px;
}

.global-btn-price {
  position: fixed;
  bottom: 30px;
  #{$left}: 30px;
  display: block;
  padding: 22px 0;
  width: 100px;
  height: 100px;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  background: $clr-base-orange;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(#f17014, .5), inset 2px 5px 11px 0px rgba(0, 0, 0, .2);
  animation: pulse 1.5s infinite;
  @include respond-to('medium'){
    width: 65px;
    height: 65px;
    padding: 15px 0;
    line-height: 1.2;
    letter-spacing: normal;
    font-size: 0.9em;
    bottom: 10px;
    #{$left}: 10px;
    animation: unset;
  }
}

@keyframes pulse {
  0% {}

  70% {
    box-shadow: 0 0 0 30px rgba(#f17014, 0), inset 2px 5px 11px 0px rgba(0, 0, 0, .2);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(#f17014, 0), inset 2px 5px 11px 0px rgba(0, 0, 0, .2);
  }
}
