
/*===================================================================================*/
/*	TOOLTIPS
/*===================================================================================*/

.tooltip-inner {
	color: #fff;
	background-color: #1ABB9C;
	padding: 5px 12px;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: #1ABB9C;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
	border-top-color: #1ABB9C;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-right-color: #1ABB9C;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
	border-left-color: #1ABB9C;
}