
/*===================================================================================*/
/*	MODALS
/*===================================================================================*/

.modal {
	z-index: 9000;
	background: none;
}
.modal.fade {
	-webkit-transition: all 0.4s ease-out;
	        transition: all 0.4s ease-out;
}
.modal.fade .modal-dialog {
    -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: all 0.4s ease-out;
            transition: all 0.4s ease-out;
	opacity: 0;
}
.modal.fade.show .modal-dialog {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
	opacity: 1;
}
/*.modal.fade .modal-dialog {
	-webkit-transform: translate(0);
	    -ms-transform: translate(0);
	        transform: translate(0);
	-webkit-transition: none;
	        transition: none;
}*/
.modal-open .modal {
	overflow: hidden;
}
.modal-backdrop {
	background-color: #F2F5F7;
	z-index: 8999;
}
.modal-backdrop.show {
	opacity: .95;
}
.modal .container {
	width: 100%;
	max-width: 1170px;
	padding-#{$right}: 50px;
	padding-#{$left}: 50px;
}
.modal-dialog {
	position: absolute;
	top: 35px;
	bottom: 35px;
	#{$right}: 35px;
	#{$left}: 35px;
	width: calc(100% - 70px);
	max-width: none;
	margin: auto;
}
.modal-full {
	top: 0 !important;
	bottom: 0 !important;
	#{$right}: 0 !important;
	#{$left}: 0 !important;
	width: 100% !important;
	height: 100% !important;
}
.modal-lg {
	max-width: 100%;
	max-height: 100%;
}
.modal-md {
	max-width: 1170px;
	max-height: 800px;
}
.modal-sm {
	max-width: 750px;
	max-height: 500px;
}
.modal-xs {
	max-width: 480px;
	max-height: 350px;
}
.modal-content {
	width: 100%; /* Can use #{$left}: 0; #{$right}: 0; with position: absolute; as well */
	height: 100%; /* Can use top: 0; bottom: 0; with position: absolute; as well */
	padding: 0 !important;
	margin: 0 !important;
	border: none;
	border-bottom: 2px solid #CED2D6;
	border-radius: 3px;
	-webkit-box-shadow: 0 30px 30px rgba(0,0,0,0.1);
  box-shadow: 0 30px 30px rgba(0,0,0,0.1);
	overflow: hidden;
}
.modal-full .modal-content {
	border: none;
	border-radius: 0;
	-webkit-box-shadow: none;
  box-shadow: none;
}
.modal-header {
	position: relative;
	z-index: 1;
	height: 70px;
	padding: 22px 25px;
	background-color: #FFF;
	border-bottom: 1px solid #E6E9ED;
}
.modal-header .close {
	font-size: 24px;
	font-weight: 400;
	color: $clr-base-dark;
	text-shadow: none;
	padding: 0;
	margin: 0 auto 0 0;
	opacity: .4;
	-webkit-transition: opacity 200ms ease-out;
  -o-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}
.dark-bg.no-modal-header .modal-header .close,
.tint-bg.no-modal-header .modal-header .close {
	color: #FFF;
}
.modal-header .close:hover,
.modal-header .close:focus {
	opacity: 1 !important;
}
.modal-title {
	color: $clr-base-dark !important;
	line-height: 150%;
	margin-#{$left}: 25px;
}
.no-modal-header .modal-header {
	background-color: transparent;
	padding-#{$left}: 40px;
	border-bottom: none;
}
.no-modal-header .modal-header .modal-title {
	display: none;
}
.no-modal-footer .modal-footer {
	display: none;
}
.no-modal-header .modal-body {
	top: 0;
}
.no-modal-footer .modal-body {
	bottom: 0;
}
.modal-body {
	position: absolute;
	top: 70px;
	bottom: 75px;
	width: 100%; /* Can use #{$left}: 0; #{$right}: 0; as well */
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.modal-footer {
	position: absolute;
	bottom: 0;
	width: 100%; /* Can use #{$left}: 0; #{$right}: 0; as well */
	height: 75px;
	background-color: #FFF;
	border-top: 1px solid #E6E9ED;
}
.modal-footer .btn {
	margin: 0;
}
.modal-open #scrollUp {
	-webkit-transition: none;
		 -o-transition: none;
			transition: none;
	opacity: 0;
}
.btn-modal {
	background: #F5F7FA !important;
	color: $clr-base-dark !important;
	border: 1px solid #E6E9ED;
	border-bottom-width: 2px;
	box-shadow: none !important;
}
.btn-modal:hover,
.btn-modal:focus,
.btn-modal:active,
.btn-modal.active {
    background: #EDF0F2 !important;
	color: #1ABB9C !important;
}