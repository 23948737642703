.owl-carousel{
  direction: ltr;
  
}

.owl-controls .owl-page{
  float: right;
}

.txt-btn:after{
  content: "\e847";
}

.dir-rtl{
  direction: rtl !important;
}

.forms,
.popap-form-myform{
  .form-error{
    margin-bottom: 15px;
    margin-top: -15px;
  }
} 

.wrapper-popap-form{
  & .modal-body{
    bottom: 0;
    top: 30px;
  }
}

// #owl-main .caption.text-left{
//   padding-right: 20%;
//   padding-left: 0;
// }

// #owl-main .caption.text-right{
//   padding-left: 20%;
//   padding-right: 0;
// }

.hp-parthers-slide{
  max-width: 293px;
  width: 100%;
  height: 200px;
  @include respond-to('small'){
    height: 120px;
  }
  & > figure{
    height: 100%;
    position: relative;
    & > img{
      max-width: 90%;
      width: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.navbar-header{
  @include respond-to('medium'){
    padding: 15px 0 20px 15px;
  }
}

.navbar-brand img{
  @include respond-to('medium'){
    height: 50px;
  }
}

.hp-product .container .inner-top-sm{
  @include respond-to('medium'){
    padding-top: 10px;
  }
}

.navbar-phone-mobile{
  display: none;

  @include respond-to('medium'){
    display: block;
    & > i.icon-phone{
      font-size: 24px;
      margin-#{$right}: 15px;
      color: #fff;
    }
  }
}

.btn-mobile-hamburger{
  @include respond-to('medium'){
    margin-#{$left}: 15px !important; 
  }
}